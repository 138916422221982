import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import Header from './Header';
import Footer from './Footer';
import Pager from './Pager';
import { useAuth } from "./Auth";
export default function Layout(props) {
  const { cmpData, loading, welcomeModal, welcomeComplete } = useAuth();




  return (
    <div>
      <Header />

      <main className="dashboard-content">
        {props.children}
      </main>

      <Footer />
      {cmpData && (
        <>

          {(cmpData.account_info && cmpData.account_info.membershipLevel) && (
            <Pager />
          )}
        </>


      )}

    </div>
  );
}
