module.exports = {
    learning: {
        "learning": {

          "enrolled": [
            {
              "user_id": 3260310,
              "course_id": 33543,
              "course_role_id": null,
              "last_activity": "2023-11-10T18:12:52.000Z",
              "role": "No Role",
              "is_course_admin": false,
              "enrolled": true,
              "enrolled_at": "2023-11-10T18:08:11Z",
              "course_home_first_visited_at": "2023-11-10T18:08:11.000Z",
              "completion_progress": "in_progress",
              "is_mentor": false,
              "unenrolled_at": null,
              "completed_at": null,
              "record_update_at": "2023-11-10T18:12:52Z",
              "self_paced_access_end": "2024-02-09T18:08:11.000Z",
              "catalog_id": "novoed-testing-course4",
              "name": "Testing Course 4",
              "type": "Course",
              "release_date": "2023-08-01T13:00:00Z",
              "close_date": "2024-02-27T20:16:00Z",
              "class_summary": null,
              "course_url": "https:\/\/cuessandbox.novoed.com\/#!\/courses\/novoed-testing-course4\/home",
              "order_date": "2023-11-10T18:08:11Z"
            },
            {
              "user_id": 3260310,
              "course_id": 33544,
              "course_role_id": null,
              "last_activity": null,
              "role": "No Role",
              "is_course_admin": false,
              "enrolled": true,
              "enrolled_at": "2023-11-10T18:02:09Z",
              "course_home_first_visited_at": "2023-11-10T18:02:10.000Z",
              "completion_progress": "in_progress",
              "is_mentor": false,
              "unenrolled_at": null,
              "completed_at": null,
              "record_update_at": "2023-11-10T18:02:10Z",
              "self_paced_access_end": null,
              "catalog_id": "novoed-testing-journey7",
              "name": "Testing Journey 7",
              "type": "Journey",
              "release_date": "2023-03-01T13:00:00Z",
              "close_date": "2024-12-15T17:00:00Z",
              "class_summary": null,
              "course_url": "https:\/\/cuessandbox.novoed.com\/#!\/courses\/novoed-testing-journey7\/home",
              "order_date": "2023-11-10T18:02:09Z"
            },
            {
              "user_id": 3260310,
              "course_id": 33546,
              "course_role_id": null,
              "last_activity": null,
              "role": "No Role",
              "is_course_admin": false,
              "enrolled": true,
              "enrolled_at": "2023-11-10T18:02:34Z",
              "course_home_first_visited_at": "2023-11-10T18:02:37.000Z",
              "completion_progress": "in_progress",
              "is_mentor": false,
              "unenrolled_at": null,
              "completed_at": null,
              "record_update_at": "2023-11-10T18:02:37Z",
              "self_paced_access_end": null,
              "catalog_id": "novoed-testing-journey8",
              "name": "Testing Journey 8",
              "type": "Journey",
              "release_date": "2023-11-01T12:00:00Z",
              "close_date": "2024-01-31T17:00:00Z",
              "class_summary": null,
              "course_url": "https:\/\/cuessandbox.novoed.com\/#!\/courses\/novoed-testing-journey8\/home",
              "order_date": "2023-11-10T18:02:34Z"
            },
            {
              "user_id": 3260310,
              "course_id": 33547,
              "course_role_id": null,
              "last_activity": null,
              "role": "No Role",
              "is_course_admin": false,
              "enrolled": true,
              "enrolled_at": "2023-11-21T21:01:21Z",
              "course_home_first_visited_at": "2023-11-21T21:01:26.000Z",
              "completion_progress": "in_progress",
              "is_mentor": false,
              "unenrolled_at": null,
              "completed_at": null,
              "record_update_at": "2023-11-21T21:01:26Z",
              "self_paced_access_end": null,
              "catalog_id": "novoed-testing-journey9",
              "name": "Testing Journey 9",
              "type": "Journey",
              "release_date": "2023-11-01T12:00:00Z",
              "close_date": "2024-03-21T18:00:00Z",
              "class_summary": "\u003Cp class=\u0022froala-style-regular\u0022 dir=\u0022ltr\u0022\u003EJourney Information\u003C\/p\u003E",
              "course_url": "https:\/\/cuessandbox.novoed.com\/#!\/courses\/novoed-testing-journey9\/home",
              "order_date": "2023-11-21T21:01:21Z"
            },
            {
              "user_id": 3260310,
              "course_id": 33549,
              "course_role_id": null,
              "last_activity": null,
              "role": "No Role",
              "is_course_admin": false,
              "enrolled": true,
              "enrolled_at": "2023-11-21T21:02:56Z",
              "course_home_first_visited_at": "2023-11-21T21:02:58.000Z",
              "completion_progress": "in_progress",
              "is_mentor": false,
              "unenrolled_at": null,
              "completed_at": null,
              "record_update_at": "2023-11-21T21:02:58Z",
              "self_paced_access_end": null,
              "catalog_id": "novoed-testing-journey11",
              "name": "Testing Journey 11",
              "type": "Journey",
              "release_date": "2023-01-24T05:00:00Z",
              "close_date": "2024-02-28T17:00:00Z",
              "class_summary": null,
              "course_url": "https:\/\/cuessandbox.novoed.com\/#!\/courses\/novoed-testing-journey11\/home",
              "order_date": "2023-11-21T21:02:56Z"
            },
            {
              "user_id": 3260310,
              "course_id": 33551,
              "course_role_id": null,
              "last_activity": null,
              "role": "No Role",
              "is_course_admin": false,
              "enrolled": true,
              "enrolled_at": "2023-10-12T16:36:38Z",
              "course_home_first_visited_at": null,
              "completion_progress": "enrolled",
              "is_mentor": false,
              "unenrolled_at": null,
              "completed_at": null,
              "record_update_at": "2023-10-12T16:36:38Z",
              "self_paced_access_end": null,
              "catalog_id": "novoed-testing-journey13",
              "name": "Testing Journey 13",
              "type": "Journey",
              "release_date": "2023-04-01T12:00:00Z",
              "close_date": "2024-04-30T16:00:00Z",
              "class_summary": null,
              "course_url": "https:\/\/cuessandbox.novoed.com\/#!\/courses\/novoed-testing-journey13\/home",
              "order_date": "2023-10-12T16:36:38Z"
            },
            {
              "user_id": 3260310,
              "course_id": 33555,
              "course_role_id": null,
              "last_activity": null,
              "role": "No Role",
              "is_course_admin": false,
              "enrolled": true,
              "enrolled_at": "2023-10-12T16:37:33Z",
              "course_home_first_visited_at": null,
              "completion_progress": "enrolled",
              "is_mentor": false,
              "unenrolled_at": null,
              "completed_at": null,
              "record_update_at": "2023-10-12T16:37:33Z",
              "self_paced_access_end": "2023-10-14T16:37:33.000Z",
              "catalog_id": "novoed-testing-course6",
              "name": "Testing Course 6",
              "type": "Course",
              "release_date": "2023-09-01T12:00:00Z",
              "close_date": "2023-11-30T17:00:00Z",
              "class_summary": null,
              "course_url": "https:\/\/cuessandbox.novoed.com\/#!\/courses\/novoed-testing-course6\/home",
              "order_date": "2023-10-12T16:37:33Z"
            },
            {
              "user_id": 3260310,
              "course_id": 34476,
              "course_role_id": null,
              "last_activity": null,
              "role": "No Role",
              "is_course_admin": false,
              "enrolled": true,
              "enrolled_at": "2023-11-10T18:01:26Z",
              "course_home_first_visited_at": "2023-11-10T18:01:31.000Z",
              "completion_progress": "in_progress",
              "is_mentor": false,
              "unenrolled_at": null,
              "completed_at": null,
              "record_update_at": "2023-11-10T18:01:31Z",
              "self_paced_access_end": null,
              "catalog_id": "learningjourney16simple",
              "name": "Learning Journey 16 Simple",
              "type": "Journey",
              "release_date": "2023-10-27T05:00:00Z",
              "close_date": null,
              "class_summary": "\u003Cp class=\u0022froala-style-regular\u0022 dir=\u0022ltr\u0022\u003ETest Learning Journey with fewer educational elements\u003C\/p\u003E",
              "course_url": "https:\/\/cuessandbox.novoed.com\/#!\/courses\/learningjourney16simple\/home",
              "order_date": "2023-11-10T18:01:26Z"
            },
            {
              "user_id": 3260310,
              "course_id": 34983,
              "course_role_id": null,
              "last_activity": null,
              "role": "No Role",
              "is_course_admin": false,
              "enrolled": true,
              "enrolled_at": "2023-11-28T17:54:06Z",
              "course_home_first_visited_at": "2023-11-28T17:54:07.000Z",
              "completion_progress": "in_progress",
              "is_mentor": false,
              "unenrolled_at": null,
              "completed_at": null,
              "record_update_at": "2023-11-28T17:54:07Z",
              "self_paced_access_end": "2023-11-28T17:54:06.000Z",
              "catalog_id": "clone-simple-course",
              "name": "Another Testing Course",
              "type": "Course",
              "release_date": "2023-11-15T06:00:00Z",
              "close_date": null,
              "class_summary": null,
              "course_url": "https:\/\/cuessandbox.novoed.com\/#!\/courses\/clone-simple-course\/home",
              "order_date": "2023-11-28T17:54:06Z"
            }
          ],
          "completed": [
            {
              "user_id": 3260310,
              "course_id": 33533,
              "course_role_id": null,
              "last_activity": null,
              "role": "No Role",
              "is_course_admin": false,
              "enrolled": true,
              "enrolled_at": "2023-11-21T21:34:17Z",
              "course_home_first_visited_at": "2023-11-21T21:34:19.000Z",
              "completion_progress": "completed",
              "is_mentor": false,
              "unenrolled_at": null,
              "completed_at": "2023-11-21T21:34:17Z",
              "record_update_at": "2023-11-21T21:34:19Z",
              "self_paced_access_end": null,
              "catalog_id": "novoed-testing-journey1",
              "name": "Testing Journey 1",
              "type": "Journey",
              "release_date": "2023-08-01T12:00:00Z",
              "close_date": "2024-03-27T16:00:00Z",
              "class_summary": "\u003Cp class=\u0022froala-style-regular\u0022 dir=\u0022ltr\u0022\u003EExample journey description. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus vel tortor id diam faucibus hendrerit at nec arcu. Nam dignissim eleifend ultrices. Sed rhoncus massa sed neque consectetur, et varius arcu laoreet.\u003C\/p\u003E",
              "course_url": "https:\/\/cuessandbox.novoed.com\/#!\/courses\/novoed-testing-journey1\/home",
              "order_date": "2023-11-21T21:34:17Z"
            },
            {
              "user_id": 3260310,
              "course_id": 33534,
              "course_role_id": null,
              "last_activity": "2023-10-25T20:01:01.000Z",
              "role": "No Role",
              "is_course_admin": false,
              "enrolled": true,
              "enrolled_at": "2023-10-25T19:56:03Z",
              "course_home_first_visited_at": "2023-10-25T19:56:03.000Z",
              "completion_progress": "completed",
              "is_mentor": false,
              "unenrolled_at": null,
              "completed_at": "2023-10-25T20:01:01Z",
              "record_update_at": "2023-10-25T20:01:01Z",
              "self_paced_access_end": "2023-12-28T19:56:03.000Z",
              "catalog_id": "novoed-testing-course1",
              "name": "Testing Course 1",
              "type": "Course",
              "release_date": "2023-08-01T12:00:00Z",
              "close_date": "2024-01-31T17:00:00Z",
              "class_summary": null,
              "course_url": "https:\/\/cuessandbox.novoed.com\/#!\/courses\/novoed-testing-course1\/home",
              "order_date": "2023-10-25T19:56:03Z"
            },
            {
              "user_id": 3260310,
              "course_id": 33535,
              "course_role_id": null,
              "last_activity": "2023-10-25T20:36:04.000Z",
              "role": "No Role",
              "is_course_admin": false,
              "enrolled": true,
              "enrolled_at": "2023-10-25T20:33:11Z",
              "course_home_first_visited_at": "2023-10-25T20:33:11.000Z",
              "completion_progress": "completed",
              "is_mentor": false,
              "unenrolled_at": null,
              "completed_at": "2023-10-25T20:36:04Z",
              "record_update_at": "2023-10-25T20:36:04Z",
              "self_paced_access_end": "2023-10-25T20:33:11.000Z",
              "catalog_id": "novoed-testing-course2",
              "name": "Testing Course 2",
              "type": "Course",
              "release_date": "2023-08-01T12:00:00Z",
              "close_date": "2023-11-26T17:00:00Z",
              "class_summary": null,
              "course_url": "https:\/\/cuessandbox.novoed.com\/#!\/courses\/novoed-testing-course2\/home",
              "order_date": "2023-10-25T20:33:11Z"
            },
            {
              "user_id": 3260310,
              "course_id": 33536,
              "course_role_id": null,
              "last_activity": "2023-10-25T20:03:45.000Z",
              "role": "No Role",
              "is_course_admin": false,
              "enrolled": true,
              "enrolled_at": "2023-10-25T20:01:16Z",
              "course_home_first_visited_at": "2023-10-25T20:01:17.000Z",
              "completion_progress": "completed",
              "is_mentor": false,
              "unenrolled_at": null,
              "completed_at": "2023-10-25T20:03:45Z",
              "record_update_at": "2023-10-25T20:03:45Z",
              "self_paced_access_end": "2023-10-25T20:01:16.000Z",
              "catalog_id": "novoed-testing-course3",
              "name": "Testing Course 3",
              "type": "Course",
              "release_date": "2023-08-01T13:00:00Z",
              "close_date": "2023-11-27T16:00:00Z",
              "class_summary": "\u003Cp\u003EExample course description. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus vel tortor id diam faucibus hendrerit at nec arcu. Nam dignissim eleifend ultrices. Sed rhoncus massa sed neque consectetur, et varius arcu laoreet.\u003C\/p\u003E",
              "course_url": "https:\/\/cuessandbox.novoed.com\/#!\/courses\/novoed-testing-course3\/home",
              "order_date": "2023-10-25T20:01:16Z"
            },
            {
              "user_id": 3260310,
              "course_id": 33539,
              "course_role_id": null,
              "last_activity": null,
              "role": "No Role",
              "is_course_admin": false,
              "enrolled": true,
              "enrolled_at": "2023-10-25T19:55:46Z",
              "course_home_first_visited_at": "2023-10-25T19:55:48.000Z",
              "completion_progress": "completed",
              "is_mentor": false,
              "unenrolled_at": null,
              "completed_at": "2023-10-25T20:03:45Z",
              "record_update_at": "2023-10-25T20:03:45Z",
              "self_paced_access_end": null,
              "catalog_id": "novoed-testing-journey4",
              "name": "Testing Journey 4",
              "type": "Journey",
              "release_date": "2023-06-01T12:00:00Z",
              "close_date": "2024-03-28T16:00:00Z",
              "class_summary": "\u003Cp class=\u0022froala-style-regular\u0022 dir=\u0022ltr\u0022\u003EExample journey description...\u003C\/p\u003E",
              "course_url": "https:\/\/cuessandbox.novoed.com\/#!\/courses\/novoed-testing-journey4\/home",
              "order_date": "2023-10-25T19:55:46Z"
            },
            {
              "user_id": 3260310,
              "course_id": 33540,
              "course_role_id": null,
              "last_activity": null,
              "role": "No Role",
              "is_course_admin": false,
              "enrolled": true,
              "enrolled_at": "2023-10-25T20:15:12Z",
              "course_home_first_visited_at": "2023-10-25T20:15:13.000Z",
              "completion_progress": "completed",
              "is_mentor": false,
              "unenrolled_at": null,
              "completed_at": "2023-10-25T20:15:12Z",
              "record_update_at": "2023-10-25T20:15:13Z",
              "self_paced_access_end": null,
              "catalog_id": "novoed-testing-journey5",
              "name": "Testing Journey 5",
              "type": "Journey",
              "release_date": "2023-05-01T12:00:00Z",
              "close_date": "2024-02-29T17:00:00Z",
              "class_summary": null,
              "course_url": "https:\/\/cuessandbox.novoed.com\/#!\/courses\/novoed-testing-journey5\/home",
              "order_date": "2023-10-25T20:15:12Z"
            },
            {
              "user_id": 3260310,
              "course_id": 33541,
              "course_role_id": null,
              "last_activity": null,
              "role": "No Role",
              "is_course_admin": false,
              "enrolled": true,
              "enrolled_at": "2023-10-25T20:23:08Z",
              "course_home_first_visited_at": "2023-10-25T20:32:07.000Z",
              "completion_progress": "completed",
              "is_mentor": false,
              "unenrolled_at": null,
              "completed_at": "2023-10-25T20:36:04Z",
              "record_update_at": "2023-10-25T20:36:04Z",
              "self_paced_access_end": null,
              "catalog_id": "novoed-testing-journey6",
              "name": "Testing Journey 6",
              "type": "Journey",
              "release_date": "2023-04-01T12:00:00Z",
              "close_date": "2024-12-10T17:00:00Z",
              "class_summary": null,
              "course_url": "https:\/\/cuessandbox.novoed.com\/#!\/courses\/novoed-testing-journey6\/home",
              "order_date": "2023-10-25T20:23:08Z"
            },
            {
              "user_id": 3260310,
              "course_id": 33552,
              "course_role_id": null,
              "last_activity": null,
              "role": "No Role",
              "is_course_admin": false,
              "enrolled": true,
              "enrolled_at": "2023-11-28T15:37:15Z",
              "course_home_first_visited_at": "2023-11-28T15:37:22.000Z",
              "completion_progress": "completed",
              "is_mentor": false,
              "unenrolled_at": null,
              "completed_at": "2023-11-28T15:37:15Z",
              "record_update_at": "2023-11-28T15:37:22Z",
              "self_paced_access_end": null,
              "catalog_id": "novoed-testing-journey14",
              "name": "Testing Journey 14 for testing",
              "type": "Journey",
              "release_date": "2023-05-01T12:00:00Z",
              "close_date": "2024-05-30T16:00:00Z",
              "class_summary": null,
              "course_url": "https:\/\/cuessandbox.novoed.com\/#!\/courses\/novoed-testing-journey14\/home",
              "order_date": "2023-11-28T15:37:15Z"
            },
            {
              "user_id": 3260310,
              "course_id": 34472,
              "course_role_id": null,
              "last_activity": "2023-11-10T18:17:53.000Z",
              "role": "No Role",
              "is_course_admin": false,
              "enrolled": true,
              "enrolled_at": "2023-11-10T18:16:12Z",
              "course_home_first_visited_at": "2023-11-10T18:16:13.000Z",
              "completion_progress": "completed",
              "is_mentor": false,
              "unenrolled_at": null,
              "completed_at": "2023-11-10T18:18:57Z",
              "record_update_at": "2023-11-10T18:18:57Z",
              "self_paced_access_end": "2023-11-10T18:16:12.000Z",
              "catalog_id": "testingcourse8easy",
              "name": "Testing Course 9 Simple Journey",
              "type": "Course",
              "release_date": "2023-10-27T05:00:00Z",
              "close_date": null,
              "class_summary": null,
              "course_url": "https:\/\/cuessandbox.novoed.com\/#!\/courses\/testingcourse8easy\/home",
              "order_date": "2023-11-10T18:16:12Z"
            }
          ],
          "learning_journeys": [
            {
              "id": 32481,
              "catalog_id": "lj-cues-hmm",
              "name": "Harvard ManageMentor Learning Journey",
              "release_date": "2023-11-01T04:01:00Z",
              "end_date": null,
              "created_at": "2023-08-30T14:44:21Z",
              "official_release_date": null,
              "registration_close_date": null,
              "close_date": null,
              "is_self_paced": null,
              "type_of_registration_in_words": "Open based on Entitlements",
              "class_summary": "\u003Cp class=\u0022froala-style-regular\u0022 dir=\u0022ltr\u0022\u003EThis collection provides access to over 40 courses covering essential business topics, offering your credit union an easy way to invest in your management and leadership development. We hope you and your team will take full advantage of this on-demand learning and performance support resource from Harvard Business Publishing, the author of Harvard Business Review. \u003C\/p\u003E",
              "enrollment_limit_in_days": null,
              "type": "Journey",
              "used_for": 0,
              "primary_course_id": null,
              "is_primary": false,
              "course_url": "https:\/\/cues.novoed.com\/#!\/courses\/lj-cues-hmm\/home"
            },
            {
              "id": 33394,
              "catalog_id": "lj-cues-critical-thinking",
              "name": "Critical Thinking \u0026 Decision Making",
              "release_date": "2023-11-01T04:01:00Z",
              "end_date": null,
              "created_at": "2023-09-23T18:31:06Z",
              "official_release_date": null,
              "registration_close_date": null,
              "close_date": null,
              "is_self_paced": null,
              "type_of_registration_in_words": "Open based on Entitlements",
              "class_summary": "\u003Cp class=\u0022froala-style-regular\u0022 dir=\u0022ltr\u0022\u003ECritical thinking enhances decision-making, problem-solving, and communication skills by fostering analytical skills, an open mindset, and logical reasoning. In this journey, leaders can overcome cognitive biases, evaluate information effectively, and make informed decisions.\u003C\/p\u003E",
              "enrollment_limit_in_days": null,
              "type": "Journey",
              "used_for": 0,
              "primary_course_id": null,
              "is_primary": false,
              "course_url": "https:\/\/cues.novoed.com\/#!\/courses\/lj-cues-critical-thinking\/home"
            },
            {
              "id": 33440,
              "catalog_id": "lj-cues-resiliency",
              "name": "Resiliency",
              "release_date": "2023-11-01T04:01:00Z",
              "end_date": null,
              "created_at": "2023-09-25T17:52:42Z",
              "official_release_date": null,
              "registration_close_date": null,
              "close_date": null,
              "is_self_paced": null,
              "type_of_registration_in_words": "Open based on Entitlements",
              "class_summary": "\u003Cp class=\u0022froala-style-regular\u0022 dir=\u0022ltr\u0022\u003EResilience is the human capacity to meet adversity, complicated situations, and trauma head-on to learn and recover successfully. This learning journey will help leaders learn to sustain their energy, cope with disruptive changes, and adapt after setbacks.\u003C\/p\u003E",
              "enrollment_limit_in_days": null,
              "type": "Journey",
              "used_for": 0,
              "primary_course_id": null,
              "is_primary": false,
              "course_url": "https:\/\/cues.novoed.com\/#!\/courses\/lj-cues-resiliency\/home"
            },
            {
              "id": 33443,
              "catalog_id": "lj-cues-trust",
              "name": "Trust",
              "release_date": "2023-11-01T04:01:00Z",
              "end_date": null,
              "created_at": "2023-09-25T18:06:14Z",
              "official_release_date": null,
              "registration_close_date": null,
              "close_date": null,
              "is_self_paced": null,
              "type_of_registration_in_words": "Open based on Entitlements",
              "class_summary": "\u003Cp class=\u0022froala-style-regular\u0022 dir=\u0022ltr\u0022\u003EUnderstanding ways to build trust with individuals can support your relationships and drive positive results for leaders. These courses help new and existing leaders build trust in the workplace, keep connections open and genuine, and create an environment of respect for themselves and the individuals they are responsible for.\u003C\/p\u003E",
              "enrollment_limit_in_days": null,
              "type": "Journey",
              "used_for": 0,
              "primary_course_id": null,
              "is_primary": false,
              "course_url": "https:\/\/cues.novoed.com\/#!\/courses\/lj-cues-trust\/home"
            },
            {
              "id": 33444,
              "catalog_id": "lj-cues-leading-others",
              "name": "Leading Others",
              "release_date": "2023-11-01T04:01:00Z",
              "end_date": null,
              "created_at": "2023-09-25T18:09:47Z",
              "official_release_date": null,
              "registration_close_date": null,
              "close_date": null,
              "is_self_paced": null,
              "type_of_registration_in_words": "Open based on Entitlements",
              "class_summary": "\u003Cp class=\u0022froala-style-regular\u0022 dir=\u0022ltr\u0022\u003ELeaders motivate individuals or groups to achieve a shared mission or vision. They also inspire people to take steps toward achieving success, resolving conflict, and successfully navigating through change. This collection of courses will help team leaders develop skills to increase efficiency, promote learning, and manage team performance.\u003C\/p\u003E",
              "enrollment_limit_in_days": null,
              "type": "Journey",
              "used_for": 0,
              "primary_course_id": null,
              "is_primary": false,
              "course_url": "https:\/\/cues.novoed.com\/#!\/courses\/lj-cues-leading-others\/home"
            },
            {
              "id": 33445,
              "catalog_id": "lj-cues-leading-self",
              "name": "Leading Self",
              "release_date": "2023-11-01T04:01:00Z",
              "end_date": null,
              "created_at": "2023-09-25T18:15:04Z",
              "official_release_date": null,
              "registration_close_date": null,
              "close_date": null,
              "is_self_paced": null,
              "type_of_registration_in_words": "Open based on Entitlements",
              "class_summary": "\u003Cp class=\u0022froala-style-regular\u0022 dir=\u0022ltr\u0022\u003ESelf-leadership is crucial because it provides more motivation and accountability for your actions. Leaders who practice governing themselves tend to achieve their goals, build the respect of their teams and colleagues, and successfully fulfill the responsibilities of their roles. This collection of courses will help leaders productively respond to challenging situations and act consistently to the best of their ability.\u003C\/p\u003E",
              "enrollment_limit_in_days": null,
              "type": "Journey",
              "used_for": 0,
              "primary_course_id": null,
              "is_primary": false,
              "course_url": "https:\/\/cues.novoed.com\/#!\/courses\/lj-cues-leading-self\/home"
            },
            {
              "id": 33446,
              "catalog_id": "lj-cues-leading-the-business",
              "name": "Leading the Business",
              "release_date": "2023-11-01T04:01:00Z",
              "end_date": null,
              "created_at": "2023-09-25T18:20:54Z",
              "official_release_date": null,
              "registration_close_date": null,
              "close_date": null,
              "is_self_paced": null,
              "type_of_registration_in_words": "Open based on Entitlements",
              "class_summary": "\u003Cp class=\u0022froala-style-regular\u0022 dir=\u0022ltr\u0022\u003EThis collection of courses is designed to support and guide individuals in building organizational alignment and overall organizational development.\u003C\/p\u003E",
              "enrollment_limit_in_days": null,
              "type": "Journey",
              "used_for": 0,
              "primary_course_id": null,
              "is_primary": false,
              "course_url": "https:\/\/cues.novoed.com\/#!\/courses\/lj-cues-leading-the-business\/home"
            },
            {
              "id": 34965,
              "catalog_id": "lj-cues-dec-2024",
              "name": "CUES Director Education Center - Full Learning Journey 2024",
              "release_date": "2024-01-01T06:00:00Z",
              "end_date": null,
              "created_at": "2023-11-16T18:42:45Z",
              "official_release_date": null,
              "registration_close_date": null,
              "close_date": null,
              "is_self_paced": null,
              "type_of_registration_in_words": "Open based on Entitlements",
              "class_summary": "\u003Cp class=\u0022froala-style-regular\u0022 dir=\u0022ltr\u0022\u003ECUES Director Education Center offers courses and tools to enhance your performance as a board member. This journey covers credit union history, membership reporting, and other fiduciary responsibilities required to make strategic decisions in your credit union and the member\u0027s best interest. Completing all of the courses will grant you a full program certificate.\u003C\/p\u003E",
              "enrollment_limit_in_days": null,
              "type": "Journey",
              "used_for": 0,
              "primary_course_id": null,
              "is_primary": false,
              "course_url": "https:\/\/cues.novoed.com\/#!\/courses\/lj-cues-dec-2024\/home"
            },
            {
              "id": 34998,
              "catalog_id": "cues-governance-plus",
              "name": "Governance+",
              "release_date": "2023-12-11T06:00:00Z",
              "end_date": null,
              "created_at": "2023-11-17T18:03:27Z",
              "official_release_date": null,
              "registration_close_date": null,
              "close_date": null,
              "is_self_paced": null,
              "type_of_registration_in_words": "Open based on Entitlements",
              "class_summary": "\u003Cp class=\u0022froala-style-regular\u0022 dir=\u0022ltr\u0022\u003EBoard governance refers to principles, methods, and processes required to make sound and ethical decisions in your credit union or service organization\u2019s best interest. This learning journey provides access to additional articles, tools, and other resources to enhance the boards\u2019 ability to guide the organization\u2019s mission and vision, determine policies, and ensure accountability to the members.\u003C\/p\u003E\u003Cp class=\u0022froala-style-regular\u0022 dir=\u0022ltr\u0022\u003EPlease Note: Click Start My Journey, above, to open the courses for you to access. You must be enrolled in this learning journey before you can start any course in it.\u003C\/p\u003E",
              "enrollment_limit_in_days": null,
              "type": "Journey",
              "used_for": 0,
              "primary_course_id": null,
              "is_primary": false,
              "course_url": "https:\/\/cues.novoed.com\/#!\/courses\/cues-governance-plus\/home"
            },
            {
              "id": 35402,
              "catalog_id": "lj-cues-can-dec-2024",
              "name": "CUES Canadian Director Education Center - Full Learning Journey 2024",
              "release_date": "2024-01-01T06:00:00Z",
              "end_date": null,
              "created_at": "2023-12-08T15:37:15Z",
              "official_release_date": null,
              "registration_close_date": null,
              "close_date": null,
              "is_self_paced": null,
              "type_of_registration_in_words": "Open based on Entitlements",
              "class_summary": "\u003Cp class=\u0022froala-style-regular\u0022 dir=\u0022ltr\u0022\u003ECUES Canadian Director Education Center offers courses and tools to enhance your performance as a board member. This journey covers credit union history, membership reporting, and other fiduciary responsibilities required to make strategic decisions in your credit union and the member\u0027s best interest.\u003C\/p\u003E",
              "enrollment_limit_in_days": null,
              "type": "Journey",
              "used_for": 0,
              "primary_course_id": null,
              "is_primary": false,
              "course_url": "https:\/\/cues.novoed.com\/#!\/courses\/lj-cues-can-dec-2024\/home"
            }
          ]
        }
      },
    "recommendations":{
      "user_id": "1127824",
      "persona": null,
      "tier": null,
      "recommendation_id": "RID-1f-40c9-a26c-dfa978d7e683-CID-ba8cb2",
      "recommendations": [
        {
          "course_id": "29963",
          "item_id": "29963",
          "title": "Unconscious Bias",
          "url": "/node/27266/edit",
          "course_catalog_id": "v1eqq",
          "learning_journeys_arr": [
            "Diversity",
            "Relationship and Networking",
            "Trust"
          ],
          "learning_journeys": "Diversity|Relationship and Networking|Trust",
          "personas_arr": [
            "CEO",
            "Executive",
            "Manager",
            "Staff"
          ],
          "personas": "CEO|Executive|Manager|Staff",
          "content_classification": "CEO|Executive|Manager|Staff",
          "topics_arr": [
            "DEI"
          ],
          "topics": "DEI",
          "genres_arr": [
            "DEI"
          ],
          "genres": "DEI",
          "series": "General",
          "tiers_arr": [
            "tier_1",
            "tier_2"
          ],
          "tiers": "tier_1|tier_2",
          "tiers_access_levels": [
            "Unlimited & Unlimited+ Members",
            "Individual Members"
          ],
          "content_role_arr": [
            "tier_1",
            "tier_2"
          ],
          "content_role": "tier_1|tier_2",
          "status": "active",
          "drupal_id": "27266",
          "content_type": "course",
          "author": null,
          "updated_at": "2024-03-06 12:01:37",
          "creation_timestamp": "2024-03-06 11:14:57",
          "recommendation_id": "RID-1f-40c9-a26c-dfa978d7e683-CID-ba8cb2",
          "isAdminEnrolled": true,
          "course_record": {
            "name": "Unconscious Bias",
            "type": "Course",
            "catalog_id": "v1eqq",
            "release_date": "2023-11-01T04:01:00Z",
            "close_date": null,
            "class_summary": "\u003Cp\u003EStereotyping, prejudice, and discrimination are at the forefront of our society today. Everyone is responsible for their \"unconscious bias\" however not everyone makes the time to be aware of their own biases. You can begin to change the cycle of your own unconscious biases by learning what it is, how to overcome your biases and helping others to become aware of their own biases.\u003C/p\u003E",
            "course_url": "https://cues.novoed.com/#!/courses/v1eqq/home"
          }
        },
        {
          "course_id": "29881",
          "item_id": "29881",
          "title": "Leveraging Diversity Today",
          "url": "/node/27261/edit",
          "course_catalog_id": "z6qwm",
          "learning_journeys_arr": [
            "Applied Leadership",
            "Diversity",
            "Leading the Business"
          ],
          "learning_journeys": "Applied Leadership|Diversity|Leading the Business",
          "personas_arr": [
            "CEO",
            "Executive",
            "Manager",
            "Staff"
          ],
          "personas": "CEO|Executive|Manager|Staff",
          "content_classification": "CEO|Executive|Manager|Staff",
          "topics_arr": [
            "DEI"
          ],
          "topics": "DEI",
          "genres_arr": [
            "DEI"
          ],
          "genres": "DEI",
          "series": "General",
          "tiers_arr": [
            "tier_1",
            "tier_2"
          ],
          "tiers": "tier_1|tier_2",
          "tiers_access_levels": [
            "Unlimited & Unlimited+ Members",
            "Individual Members"
          ],
          "content_role_arr": [
            "tier_1",
            "tier_2"
          ],
          "content_role": "tier_1|tier_2",
          "status": "active",
          "drupal_id": "27261",
          "content_type": "course",
          "author": null,
          "updated_at": "2024-03-06 15:39:29",
          "creation_timestamp": "2024-03-06 11:11:37",
          "recommendation_id": "RID-1f-40c9-a26c-dfa978d7e683-CID-ba8cb2",
          "isAdminEnrolled": false,
          "course_record": {
            "name": "Leveraging Diversity Today",
            "type": "Course",
            "catalog_id": "z6qwm",
            "release_date": "2023-11-01T04:01:00Z",
            "close_date": null,
            "class_summary": "\u003Cp\u003EIn today's diverse and global business environments, we all need a high level of sensitivity and tolerance. Understanding and adapting to different cultural and social dynamics in the workplace is essential to helping us succeed professionally, collaborate effectively, and promote the innovation needed to achieve important team and organizational results. This course will demonstrate the following:\u003C/p\u003E\u003Cp\u003E• Why diversity matters\u003Cbr\u003E • Understanding diversity\u003Cbr\u003E • Applying diversity skills\u003C/p\u003E",
            "course_url": "https://cues.novoed.com/#!/courses/z6qwm/home"
          }
        },
        {
          "course_id": "29649",
          "item_id": "29649",
          "title": "Building Your Networking Skills",
          "url": "/node/27271/edit",
          "course_catalog_id": "4546k",
          "learning_journeys_arr": [
            "Leading Others",
            "Relationship and Networking"
          ],
          "learning_journeys": "Leading Others|Relationship and Networking",
          "personas_arr": [
            "Manager",
            "Staff"
          ],
          "personas": "Manager|Staff",
          "content_classification": "Manager|Staff",
          "topics_arr": [
            "Growth"
          ],
          "topics": "Growth",
          "genres_arr": [
            "Growth"
          ],
          "genres": "Growth",
          "series": "General",
          "tiers_arr": [
            "tier_1",
            "tier_2"
          ],
          "tiers": "tier_1|tier_2",
          "tiers_access_levels": [
            "Unlimited & Unlimited+ Members",
            "Individual Members"
          ],
          "content_role_arr": [
            "tier_1",
            "tier_2"
          ],
          "content_role": "tier_1|tier_2",
          "status": "active",
          "drupal_id": "27271",
          "content_type": "course",
          "author": null,
          "updated_at": "2024-03-22 08:13:26",
          "creation_timestamp": "2024-03-06 14:48:57",
          "recommendation_id": "RID-1f-40c9-a26c-dfa978d7e683-CID-ba8cb2",
          "isAdminEnrolled": false,
          "course_record": {
            "name": "Building Your Networking Skills",
            "type": "Course",
            "catalog_id": "4546k",
            "release_date": "2023-11-01T04:01:00Z",
            "close_date": null,
            "class_summary": "\u003Cp\u003EIn today's business world, collaboration is the way work gets done, and to collaborate successfully, you need a strong network of people who can help you accomplish your goals. This course focuses on three aspects of networking:\u003C/p\u003E • Cultivating your professional networking skills\u003Cbr\u003E • Making the most of business networking events\u003Cbr\u003E • Strengthening your developmental network, i.e., people who will support your career growth",
            "course_url": "https://cues.novoed.com/#!/courses/4546k/home"
          }
        },
        {
          "course_id": "29504",
          "item_id": "29504",
          "title": "Enhancing Emotional Intelligence",
          "url": "/node/27281/edit",
          "course_catalog_id": "a3arz",
          "learning_journeys_arr": [
            "Leading Others",
            "Relationship and Networking"
          ],
          "learning_journeys": "Leading Others|Relationship and Networking",
          "personas_arr": [
            "Staff"
          ],
          "personas": "Staff",
          "content_classification": "Staff",
          "topics_arr": [
            "Growth"
          ],
          "topics": "Growth",
          "genres_arr": [
            "Growth"
          ],
          "genres": "Growth",
          "series": "General",
          "tiers_arr": [
            "tier_1",
            "tier_2"
          ],
          "tiers": "tier_1|tier_2",
          "tiers_access_levels": [
            "Unlimited & Unlimited+ Members",
            "Individual Members"
          ],
          "content_role_arr": [
            "tier_1",
            "tier_2"
          ],
          "content_role": "tier_1|tier_2",
          "status": "active",
          "drupal_id": "27281",
          "content_type": "course",
          "author": null,
          "updated_at": "2024-03-06 15:42:20",
          "creation_timestamp": "2024-03-06 14:52:55",
          "recommendation_id": "RID-1f-40c9-a26c-dfa978d7e683-CID-ba8cb2",
          "isAdminEnrolled": false,
          "course_record": {
            "name": "Enhancing Emotional Intelligence",
            "type": "Course",
            "catalog_id": "a3arz",
            "release_date": "2023-11-01T04:01:00Z",
            "close_date": null,
            "class_summary": "\u003Cp\u003EExplore the critical role of emotional intelligence in the workplace and its impact on individual and organizational success with \"Enhancing Emotional Intelligence.\" Through this course, participants will:\u003C/p\u003E\u003Cul\u003E\u003Cli\u003EUnderstand the importance of emotional intelligence for employees and leaders in the modern workplace\u003C/li\u003E \u003Cli\u003EDevelop practical self-awareness and self-management techniques for navigating emotions in professional environments\u003C/li\u003E \u003Cli\u003ERecognize different types of empathy and their relevance to workplace dynamics\u003C/li\u003E \u003Cli\u003EAcquire and apply social skills critical to emotional intelligence\u003C/li\u003E \u003Cli\u003EIdentify and strengthen personal emotional intelligence through self-reflection activities and a comprehensive assessment\u003C/li\u003E\u003C/ul\u003E\u003Cp\u003E\u003Cstrong\u003EApproximate Completion Time: \u003C/strong\u003E2 Hours\u003C/p\u003E",
            "course_url": "https://cues.novoed.com/#!/courses/a3arz/home"
          }
        },
        {
          "course_id": "30337",
          "item_id": "30337",
          "title": "Time Management - Harvard ManageMentor",
          "url": "/node/27701/edit",
          "course_catalog_id": "hmm-time-mgmt",
          "learning_journeys_arr": [
            "Harvard ManageMentor Learning Journey"
          ],
          "learning_journeys": "Harvard ManageMentor Learning Journey",
          "personas_arr": [
            "CEO",
            "Executive",
            "Manager",
            "Staff",
            "Board"
          ],
          "personas": "CEO|Executive|Manager|Staff|Board",
          "content_classification": "CEO|Executive|Manager|Staff|Board",
          "topics_arr": [
            "Human Resources",
            "Leadership"
          ],
          "topics": "Human Resources|Leadership",
          "genres_arr": [
            "Human Resources",
            "Leadership"
          ],
          "genres": "Human Resources|Leadership",
          "series": "HMM",
          "tiers_arr": [
            "tier_1"
          ],
          "tiers": "tier_1",
          "tiers_access_levels": [
            "Unlimited & Unlimited+ Members"
          ],
          "content_role_arr": [
            "tier_1"
          ],
          "content_role": "tier_1",
          "status": "active",
          "drupal_id": "27701",
          "content_type": "course",
          "author": null,
          "updated_at": "2024-03-21 15:22:24",
          "creation_timestamp": "2024-03-08 08:26:37",
          "recommendation_id": "RID-1f-40c9-a26c-dfa978d7e683-CID-ba8cb2",
          "isAdminEnrolled": false,
          "course_record": {
            "name": "Time Management - Harvard ManageMentor",
            "type": "Course",
            "catalog_id": "hmm-time-mgmt",
            "release_date": "2023-11-01T04:01:00Z",
            "close_date": null,
            "class_summary": null,
            "course_url": "https://cues.novoed.com/#!/courses/hmm-time-mgmt/home"
          }
        },
        {
          "course_id": "30285",
          "item_id": "30285",
          "title": "Develop Your Mentoring Skills",
          "url": "/node/27496/edit",
          "course_catalog_id": "bvjwy",
          "learning_journeys_arr": [
            "Coaching and Mentoring",
            "Leading Others"
          ],
          "learning_journeys": "Coaching and Mentoring|Leading Others",
          "personas_arr": [
            "CEO",
            "Executive",
            "Manager"
          ],
          "personas": "CEO|Executive|Manager",
          "content_classification": "CEO|Executive|Manager",
          "topics_arr": [
            "Growth",
            "Human Resources",
            "Leadership"
          ],
          "topics": "Growth|Human Resources|Leadership",
          "genres_arr": [
            "Growth",
            "Human Resources",
            "Leadership"
          ],
          "genres": "Growth|Human Resources|Leadership",
          "series": "General",
          "tiers_arr": [
            "tier_1",
            "tier_2"
          ],
          "tiers": "tier_1|tier_2",
          "tiers_access_levels": [
            "Unlimited & Unlimited+ Members",
            "Individual Members"
          ],
          "content_role_arr": [
            "tier_1",
            "tier_2"
          ],
          "content_role": "tier_1|tier_2",
          "status": "active",
          "drupal_id": "27496",
          "content_type": "course",
          "author": null,
          "updated_at": "2024-03-07 11:54:30",
          "creation_timestamp": "2024-03-07 11:53:24",
          "recommendation_id": "RID-1f-40c9-a26c-dfa978d7e683-CID-ba8cb2",
          "isAdminEnrolled": false,
          "course_record": {
            "name": "Develop Your Mentoring Skills",
            "type": "Course",
            "catalog_id": "bvjwy",
            "release_date": "2023-11-01T04:01:00Z",
            "close_date": null,
            "class_summary": "\u003Cp\u003EConnection, Communication, and Collaboration are the 3 C's of mentorship. Each of these elements plays a role in nurturing a dynamic and impactful mentor-mentee relationship. In this course, you will understand the important skills of a successful mentor and how to support others through motivation and active listening.\u003C/p\u003E",
            "course_url": "https://cues.novoed.com/#!/courses/bvjwy/home"
          }
        },
        {
          "course_id": "31265",
          "item_id": "31265",
          "title": "Diversity, Inclusion, and Belonging - Harvard ManageMentor",
          "url": "/node/27481/edit",
          "course_catalog_id": "hmm-diversity-inclusion-belonging",
          "learning_journeys_arr": [
            "Board Journey",
            "Harvard ManageMentor Learning Journey"
          ],
          "learning_journeys": "Board Journey|Harvard ManageMentor Learning Journey",
          "personas_arr": [
            "CEO",
            "Executive",
            "Manager",
            "Staff",
            "Board"
          ],
          "personas": "CEO|Executive|Manager|Staff|Board",
          "content_classification": "CEO|Executive|Manager|Staff|Board",
          "topics_arr": [
            "DEI",
            "Governance",
            "Human Resources",
            "Leadership"
          ],
          "topics": "DEI|Governance|Human Resources|Leadership",
          "genres_arr": [
            "DEI",
            "Governance",
            "Human Resources",
            "Leadership"
          ],
          "genres": "DEI|Governance|Human Resources|Leadership",
          "series": "HMM",
          "tiers_arr": [
            "tier_1"
          ],
          "tiers": "tier_1",
          "tiers_access_levels": [
            "Unlimited & Unlimited+ Members"
          ],
          "content_role_arr": [
            "tier_1"
          ],
          "content_role": "tier_1",
          "status": "active",
          "drupal_id": "27481",
          "content_type": "course",
          "author": null,
          "updated_at": "2024-03-21 14:50:56",
          "creation_timestamp": "2024-03-07 11:46:27",
          "recommendation_id": "RID-1f-40c9-a26c-dfa978d7e683-CID-ba8cb2",
          "isAdminEnrolled": false,
          "course_record": {
            "name": "Diversity, Inclusion, and Belonging - Harvard ManageMentor",
            "type": "Course",
            "catalog_id": "hmm-diversity-inclusion-belonging",
            "release_date": "2023-11-01T04:01:00Z",
            "close_date": null,
            "class_summary": "\u003Cp\u003EEmbracing diversity is not only the right thing to do but also a strategic advantage that leads to a stronger and more resilient organization. It brings a true sense of belonging and it unlocks the full potential of your team. Explore actionable steps to foster inclusion and a genuine sense of belonging within your organization.\u003C/p\u003E",
            "course_url": "https://cues.novoed.com/#!/courses/hmm-diversity-inclusion-belonging/home"
          }
        },
        {
          "course_id": "31231",
          "item_id": "31231",
          "title": "Career Management - Harvard ManageMentor",
          "url": "/node/27661/edit",
          "course_catalog_id": "hmm-career-mgmt",
          "learning_journeys_arr": [
            "Harvard ManageMentor Learning Journey"
          ],
          "learning_journeys": "Harvard ManageMentor Learning Journey",
          "personas_arr": [
            "CEO",
            "Executive",
            "Manager",
            "Staff"
          ],
          "personas": "CEO|Executive|Manager|Staff",
          "content_classification": "CEO|Executive|Manager|Staff",
          "topics_arr": [
            "Human Resources"
          ],
          "topics": "Human Resources",
          "genres_arr": [
            "Human Resources"
          ],
          "genres": "Human Resources",
          "series": "HMM",
          "tiers_arr": [
            "tier_1"
          ],
          "tiers": "tier_1",
          "tiers_access_levels": [
            "Unlimited & Unlimited+ Members"
          ],
          "content_role_arr": [
            "tier_1"
          ],
          "content_role": "tier_1",
          "status": "active",
          "drupal_id": "27661",
          "content_type": "course",
          "author": null,
          "updated_at": "2024-03-21 14:43:29",
          "creation_timestamp": "2024-03-08 08:16:05",
          "recommendation_id": "RID-1f-40c9-a26c-dfa978d7e683-CID-ba8cb2",
          "isAdminEnrolled": false,
          "course_record": {
            "name": "Career Management - Harvard ManageMentor",
            "type": "Course",
            "catalog_id": "hmm-career-mgmt",
            "release_date": "2023-11-01T04:01:00Z",
            "close_date": null,
            "class_summary": "\u003Cp\u003EAchieving a fulfilling career path is within your grasp, and though others may support you, it ultimately depends on your initiative. Discover how to take charge of your career by exploring your true self, leveraging opportunities within setbacks, and embracing continuous learning as a way of life.\u003C/p\u003E",
            "course_url": "https://cues.novoed.com/#!/courses/hmm-career-mgmt/home"
          }
        },
        {
          "course_id": "30678",
          "item_id": "30678",
          "title": "Trust: A Leader's Tool",
          "url": "/node/27321/edit",
          "course_catalog_id": "2a05y",
          "learning_journeys_arr": [
            "Leading Others",
            "Trust"
          ],
          "learning_journeys": "Leading Others|Trust",
          "personas_arr": [
            "CEO",
            "Executive",
            "Manager"
          ],
          "personas": "CEO|Executive|Manager",
          "content_classification": "CEO|Executive|Manager",
          "topics_arr": [
            "Leadership"
          ],
          "topics": "Leadership",
          "genres_arr": [
            "Leadership"
          ],
          "genres": "Leadership",
          "series": "General",
          "tiers_arr": [
            "tier_1",
            "tier_2"
          ],
          "tiers": "tier_1|tier_2",
          "tiers_access_levels": [
            "Unlimited & Unlimited+ Members",
            "Individual Members"
          ],
          "content_role_arr": [
            "tier_1",
            "tier_2"
          ],
          "content_role": "tier_1|tier_2",
          "status": "active",
          "drupal_id": "27321",
          "content_type": "course",
          "author": null,
          "updated_at": "2024-03-07 09:16:54",
          "creation_timestamp": "2024-03-07 09:15:49",
          "recommendation_id": "RID-1f-40c9-a26c-dfa978d7e683-CID-ba8cb2",
          "isAdminEnrolled": true,
          "course_record": {
            "name": "Trust: A Leader's Tool",
            "type": "Course",
            "catalog_id": "2a05y",
            "release_date": "2023-11-01T04:01:00Z",
            "close_date": null,
            "class_summary": "\u003Cp\u003EUnderstanding ways to build trust with individuals can support your relationships as a leader. This course focuses on how to build trust in the workplace, including the value of workplace ethics and transparent leadership.\u003C/p\u003E",
            "course_url": "https://cues.novoed.com/#!/courses/2a05y/home"
          }
        },
        {
          "course_id": "29882",
          "item_id": "29882",
          "title": "Fostering Employee Engagement",
          "url": "/node/27451/edit",
          "course_catalog_id": "68eoa",
          "learning_journeys_arr": [
            "Applied Leadership",
            "Leading the Business"
          ],
          "learning_journeys": "Applied Leadership|Leading the Business",
          "personas_arr": [
            "CEO",
            "Executive"
          ],
          "personas": "CEO|Executive",
          "content_classification": "CEO|Executive",
          "topics_arr": [
            "Leadership"
          ],
          "topics": "Leadership",
          "genres_arr": [
            "Leadership"
          ],
          "genres": "Leadership",
          "series": "General",
          "tiers_arr": [
            "tier_1",
            "tier_2"
          ],
          "tiers": "tier_1|tier_2",
          "tiers_access_levels": [
            "Unlimited & Unlimited+ Members",
            "Individual Members"
          ],
          "content_role_arr": [
            "tier_1",
            "tier_2"
          ],
          "content_role": "tier_1|tier_2",
          "status": "active",
          "drupal_id": "27451",
          "content_type": "course",
          "author": null,
          "updated_at": "2024-03-07 11:24:27",
          "creation_timestamp": "2024-03-07 11:23:15",
          "recommendation_id": "RID-1f-40c9-a26c-dfa978d7e683-CID-ba8cb2",
          "isAdminEnrolled": false,
          "course_record": {
            "name": "Fostering Employee Engagement",
            "type": "Course",
            "catalog_id": "68eoa",
            "release_date": "2023-11-01T04:01:00Z",
            "close_date": null,
            "class_summary": "\u003Cp\u003EThough organizations worldwide recognize engagement's critical impact on employee productivity and retention, global engagement levels remain disturbingly low. According to Gallup Inc. only 32% of U.S. employees and 13% of employees worldwide report that they are enthusiastic about and committed to their work and workplace.\u003C/p\u003E\u003Cp\u003EThis course is designed to help leaders at all levels:\u003Cbr\u003E • Recognize the importance of employee engagement\u003Cbr\u003E • Understand barriers to employee engagement\u003Cbr\u003E • Assess levels of employee engagement\u003Cbr\u003E • Adopt engagement strategies at the team level\u003Cbr\u003E • Establish and track organization-wide engagement practices\u003C/p\u003E",
            "course_url": "https://cues.novoed.com/#!/courses/68eoa/home"
          }
        },
        {
          "course_id": "30960",
          "item_id": "30960",
          "title": "Diversity and Inclusion",
          "url": "/node/27256/edit",
          "course_catalog_id": "a3ax8",
          "learning_journeys_arr": [
            "Board Journey",
            "Diversity"
          ],
          "learning_journeys": "Board Journey|Diversity",
          "personas_arr": [
            "CEO",
            "Executive",
            "Manager",
            "Staff"
          ],
          "personas": "CEO|Executive|Manager|Staff",
          "content_classification": "CEO|Executive|Manager|Staff",
          "topics_arr": [
            "DEI"
          ],
          "topics": "DEI",
          "genres_arr": [
            "DEI"
          ],
          "genres": "DEI",
          "series": "General",
          "tiers_arr": [
            "tier_1",
            "tier_2"
          ],
          "tiers": "tier_1|tier_2",
          "tiers_access_levels": [
            "Unlimited & Unlimited+ Members",
            "Individual Members"
          ],
          "content_role_arr": [
            "tier_1",
            "tier_2"
          ],
          "content_role": "tier_1|tier_2",
          "status": "active",
          "drupal_id": "27256",
          "content_type": "course",
          "author": null,
          "updated_at": "2024-03-06 15:43:01",
          "creation_timestamp": "2024-03-06 11:09:52",
          "recommendation_id": "RID-1f-40c9-a26c-dfa978d7e683-CID-ba8cb2",
          "isAdminEnrolled": false,
          "course_record": {
            "name": "Diversity and Inclusion",
            "type": "Course",
            "catalog_id": "a3ax8",
            "release_date": "2023-11-01T04:01:00Z",
            "close_date": null,
            "class_summary": "\u003Cp\u003EExplore this course to gain knowledge on important issues such as 'Workplace Inclusion', 'Gender Equality' and 'Managing Workplace Diversity'.\u003C/p\u003E",
            "course_url": "https://cues.novoed.com/#!/courses/a3ax8/home"
          }
        },
        {
          "course_id": "30456",
          "item_id": "30456",
          "title": "Focus on the Member",
          "url": "/node/27461/edit",
          "course_catalog_id": "orzpw",
          "learning_journeys_arr": [
            "Leading the Business",
            "Member Focus"
          ],
          "learning_journeys": "Leading the Business|Member Focus",
          "personas_arr": [
            "CEO",
            "Executive",
            "Manager",
            "Staff"
          ],
          "personas": "CEO|Executive|Manager|Staff",
          "content_classification": "CEO|Executive|Manager|Staff",
          "topics_arr": [
            "Growth",
            "Leadership"
          ],
          "topics": "Growth|Leadership",
          "genres_arr": [
            "Growth",
            "Leadership"
          ],
          "genres": "Growth|Leadership",
          "series": "General",
          "tiers_arr": [
            "tier_1",
            "tier_2"
          ],
          "tiers": "tier_1|tier_2",
          "tiers_access_levels": [
            "Unlimited & Unlimited+ Members",
            "Individual Members"
          ],
          "content_role_arr": [
            "tier_1",
            "tier_2"
          ],
          "content_role": "tier_1|tier_2",
          "status": "active",
          "drupal_id": "27461",
          "content_type": "course",
          "author": null,
          "updated_at": "2024-03-07 11:38:13",
          "creation_timestamp": "2024-03-07 11:36:46",
          "recommendation_id": "RID-1f-40c9-a26c-dfa978d7e683-CID-ba8cb2",
          "isAdminEnrolled": false,
          "course_record": {
            "name": "Focus on the Member",
            "type": "Course",
            "catalog_id": "orzpw",
            "release_date": "2023-11-01T04:01:00Z",
            "close_date": null,
            "class_summary": "\u003Cp\u003EThis course explores different aspects of member experience such as 'Responding to a Member', 'Creating A Member Centered Culture', 'Building a Member Focused Organization' and 'Credit Union Member Experience'.\u003C/p\u003E",
            "course_url": "https://cues.novoed.com/#!/courses/orzpw/home"
          }
        },
        {
          "course_id": "29884",
          "item_id": "29884",
          "title": "Delegating Effectively",
          "url": "/node/27506/edit",
          "course_catalog_id": "ymyz3",
          "learning_journeys_arr": [
            "Applied Leadership",
            "Leading Others"
          ],
          "learning_journeys": "Applied Leadership|Leading Others",
          "personas_arr": [
            "CEO",
            "Executive"
          ],
          "personas": "CEO|Executive",
          "content_classification": "CEO|Executive",
          "topics_arr": [
            "Leadership"
          ],
          "topics": "Leadership",
          "genres_arr": [
            "Leadership"
          ],
          "genres": "Leadership",
          "series": "General",
          "tiers_arr": [
            "tier_1",
            "tier_2"
          ],
          "tiers": "tier_1|tier_2",
          "tiers_access_levels": [
            "Unlimited & Unlimited+ Members",
            "Individual Members"
          ],
          "content_role_arr": [
            "tier_1",
            "tier_2"
          ],
          "content_role": "tier_1|tier_2",
          "status": "active",
          "drupal_id": "27506",
          "content_type": "course",
          "author": null,
          "updated_at": "2024-03-07 11:58:09",
          "creation_timestamp": "2024-03-07 11:57:01",
          "recommendation_id": "RID-1f-40c9-a26c-dfa978d7e683-CID-ba8cb2",
          "isAdminEnrolled": false,
          "course_record": {
            "name": "Delegating Effectively",
            "type": "Course",
            "catalog_id": "ymyz3",
            "release_date": "2023-11-01T04:01:00Z",
            "close_date": null,
            "class_summary": "\u003Cp\u003EDelegation is an important tool in developing your team members and in managing your own priorities. This course highlights practical approaches to delegation that will accelerate team performance and provide employees opportunities to learn new skills. It will help you:\u003C/p\u003E\u003Cp\u003E• Understand the essential characteristics of delegation\u003Cbr\u003E • Recognize the importance of delegation to leadership effectiveness and employee development\u003Cbr\u003E • Prepare to delegate effectively\u003Cbr\u003E • Manage delegated work\u003C/p\u003E",
            "course_url": "https://cues.novoed.com/#!/courses/ymyz3/home"
          }
        },
        {
          "course_id": "30465",
          "item_id": "30465",
          "title": "Self-Development",
          "url": "/node/27341/edit",
          "course_catalog_id": "lrrqn",
          "learning_journeys_arr": [
            "Leading Self",
            "Self-Development"
          ],
          "learning_journeys": "Leading Self|Self-Development",
          "personas_arr": [
            "CEO",
            "Executive",
            "Manager",
            "Staff",
            "Board"
          ],
          "personas": "CEO|Executive|Manager|Staff|Board",
          "content_classification": "CEO|Executive|Manager|Staff|Board",
          "topics_arr": [
            "Growth",
            "Leadership"
          ],
          "topics": "Growth|Leadership",
          "genres_arr": [
            "Growth",
            "Leadership"
          ],
          "genres": "Growth|Leadership",
          "series": "General",
          "tiers_arr": [
            "tier_1",
            "tier_2"
          ],
          "tiers": "tier_1|tier_2",
          "tiers_access_levels": [
            "Unlimited & Unlimited+ Members",
            "Individual Members"
          ],
          "content_role_arr": [
            "tier_1",
            "tier_2"
          ],
          "content_role": "tier_1|tier_2",
          "status": "active",
          "drupal_id": "27341",
          "content_type": "course",
          "author": null,
          "updated_at": "2024-03-07 09:26:21",
          "creation_timestamp": "2024-03-07 09:24:54",
          "recommendation_id": "RID-1f-40c9-a26c-dfa978d7e683-CID-ba8cb2",
          "isAdminEnrolled": false,
          "course_record": {
            "name": "Self-Development",
            "type": "Course",
            "catalog_id": "lrrqn",
            "release_date": "2023-11-01T04:01:00Z",
            "close_date": null,
            "class_summary": "\u003Cp\u003EDevelop your skills in remaining personally committed and actively work to continuously improve yourself.\u003C/p\u003E",
            "course_url": "https://cues.novoed.com/#!/courses/lrrqn/home"
          }
        },
        {
          "course_id": "30325",
          "item_id": "30325",
          "title": "Authentic Leadership",
          "url": "/node/27291/edit",
          "course_catalog_id": "xqyyv",
          "learning_journeys_arr": [
            "Leadership Foundations",
            "Leading Others"
          ],
          "learning_journeys": "Leadership Foundations|Leading Others",
          "personas_arr": [
            "Executive",
            "Manager"
          ],
          "personas": "Executive|Manager",
          "content_classification": "Executive|Manager",
          "topics_arr": [
            "Leadership"
          ],
          "topics": "Leadership",
          "genres_arr": [
            "Leadership"
          ],
          "genres": "Leadership",
          "series": "General",
          "tiers_arr": [
            "tier_1",
            "tier_2"
          ],
          "tiers": "tier_1|tier_2",
          "tiers_access_levels": [
            "Unlimited & Unlimited+ Members",
            "Individual Members"
          ],
          "content_role_arr": [
            "tier_1",
            "tier_2"
          ],
          "content_role": "tier_1|tier_2",
          "status": "active",
          "drupal_id": "27291",
          "content_type": "course",
          "author": null,
          "updated_at": "2024-03-07 08:50:02",
          "creation_timestamp": "2024-03-07 08:48:33",
          "recommendation_id": "RID-1f-40c9-a26c-dfa978d7e683-CID-ba8cb2",
          "isAdminEnrolled": false,
          "course_record": {
            "name": "Authentic Leadership",
            "type": "Course",
            "catalog_id": "xqyyv",
            "release_date": "2023-11-01T04:01:00Z",
            "close_date": null,
            "class_summary": "\u003Cp\u003EAuthenticity is the healthy alignment between internal values and beliefs and external behavior. Authenticity comes from finding your style and your way of leading — and making life decisions that reflect your values and your personality.\u003C/p\u003E",
            "course_url": "https://cues.novoed.com/#!/courses/xqyyv/home"
          }
        },
        {
          "course_id": "30675",
          "item_id": "30675",
          "title": "Time Management",
          "url": "/node/27326/edit",
          "course_catalog_id": "kxeva",
          "learning_journeys_arr": [
            "Leading Self",
            "Self-Development"
          ],
          "learning_journeys": "Leading Self|Self-Development",
          "personas_arr": [
            "Manager",
            "Staff"
          ],
          "personas": "Manager|Staff",
          "content_classification": "Manager|Staff",
          "topics_arr": [
            "Growth"
          ],
          "topics": "Growth",
          "genres_arr": [
            "Growth"
          ],
          "genres": "Growth",
          "series": "General",
          "tiers_arr": [
            "tier_1",
            "tier_2"
          ],
          "tiers": "tier_1|tier_2",
          "tiers_access_levels": [
            "Unlimited & Unlimited+ Members",
            "Individual Members"
          ],
          "content_role_arr": [
            "tier_1",
            "tier_2"
          ],
          "content_role": "tier_1|tier_2",
          "status": "active",
          "drupal_id": "27326",
          "content_type": "course",
          "author": null,
          "updated_at": "2024-03-07 09:19:02",
          "creation_timestamp": "2024-03-07 09:17:09",
          "recommendation_id": "RID-1f-40c9-a26c-dfa978d7e683-CID-ba8cb2",
          "isAdminEnrolled": false,
          "course_record": {
            "name": "Time Management",
            "type": "Course",
            "catalog_id": "kxeva",
            "release_date": "2023-11-01T05:01:00Z",
            "close_date": null,
            "class_summary": "\u003Cp\u003EHave a look at this course to learn how to manage your time more effectively. Effective time management increases your focus and improves your productivity. Greater focus allows you to capture bigger opportunities. It also allows you to spend more time on the projects, goals, and people that matter. \u003C/p\u003E",
            "course_url": "https://cues.novoed.com/#!/courses/kxeva/home"
          }
        },
        {
          "course_id": "30286",
          "item_id": "30286",
          "title": "Communicate Effectively",
          "url": "/node/27611/edit",
          "course_catalog_id": "z6q59",
          "learning_journeys_arr": [
            "Communication",
            "Leading Self"
          ],
          "learning_journeys": "Communication|Leading Self",
          "personas_arr": [
            "CEO",
            "Executive",
            "Manager",
            "Staff"
          ],
          "personas": "CEO|Executive|Manager|Staff",
          "content_classification": "CEO|Executive|Manager|Staff",
          "topics_arr": [
            "Leadership"
          ],
          "topics": "Leadership",
          "genres_arr": [
            "Leadership"
          ],
          "genres": "Leadership",
          "series": "General",
          "tiers_arr": [
            "tier_1",
            "tier_2"
          ],
          "tiers": "tier_1|tier_2",
          "tiers_access_levels": [
            "Unlimited & Unlimited+ Members",
            "Individual Members"
          ],
          "content_role_arr": [
            "tier_1",
            "tier_2"
          ],
          "content_role": "tier_1|tier_2",
          "status": "active",
          "drupal_id": "27611",
          "content_type": "course",
          "author": null,
          "updated_at": "2024-05-20 14:36:57",
          "creation_timestamp": "2024-03-07 13:12:07",
          "recommendation_id": "RID-1f-40c9-a26c-dfa978d7e683-CID-ba8cb2",
          "isAdminEnrolled": false,
          "course_record": {
            "name": "Communicate Effectively",
            "type": "Course",
            "catalog_id": "z6q59",
            "release_date": "2023-11-01T04:01:00Z",
            "close_date": null,
            "class_summary": "\u003Cp\u003EThe ability to communicate effectively is very important to companies because it allows companies to be productive and operate effectively. Employees can experience an increase in morale, productivity, and commitment if they are able to communicate up and down the communication chain in an organization. This course deals with:\u003C/p\u003E\u003Cp\u003E• Why communication matters\u003Cbr\u003E • Understanding communication\u003Cbr\u003E • Communicating successfully\u003C/p\u003E",
            "course_url": "https://cues.novoed.com/#!/courses/z6q59/home"
          }
        },
        {
          "course_id": "31233",
          "item_id": "31233",
          "title": "Coaching - Harvard ManageMentor",
          "url": "/node/27856/edit",
          "course_catalog_id": "hmm-coaching",
          "learning_journeys_arr": [
            "Harvard ManageMentor Learning Journey"
          ],
          "learning_journeys": "Harvard ManageMentor Learning Journey",
          "personas_arr": [
            "CEO",
            "Executive",
            "Manager"
          ],
          "personas": "CEO|Executive|Manager",
          "content_classification": "CEO|Executive|Manager",
          "topics_arr": [
            "Human Resources",
            "Leadership"
          ],
          "topics": "Human Resources|Leadership",
          "genres_arr": [
            "Human Resources",
            "Leadership"
          ],
          "genres": "Human Resources|Leadership",
          "series": "HMM",
          "tiers_arr": [
            "tier_1"
          ],
          "tiers": "tier_1",
          "tiers_access_levels": [
            "Unlimited & Unlimited+ Members"
          ],
          "content_role_arr": [
            "tier_1"
          ],
          "content_role": "tier_1",
          "status": "active",
          "drupal_id": "27856",
          "content_type": "course",
          "author": null,
          "updated_at": "2024-03-21 14:44:31",
          "creation_timestamp": "2024-03-08 08:56:44",
          "recommendation_id": "RID-1f-40c9-a26c-dfa978d7e683-CID-ba8cb2",
          "isAdminEnrolled": false,
          "course_record": {
            "name": "Coaching - Harvard ManageMentor",
            "type": "Course",
            "catalog_id": "hmm-coaching",
            "release_date": "2023-11-01T04:01:00Z",
            "close_date": null,
            "class_summary": "\u003Cp\u003EUnlocking the full potential of your team begins with cultivating members who can make intelligent, independent decisions, a vital element for achieving success.  Fortunately, coaching provides a transformative approach to help individuals hone their skills and build unwavering confidence. Refine your coaching skills to create a supportive environment that nurtures essential capabilities within your team, fostering a culture of high performance and self-reliance.\u003C/p\u003E",
            "course_url": "https://cues.novoed.com/#!/courses/hmm-coaching/home"
          }
        },
        {
          "course_id": "30673",
          "item_id": "30673",
          "title": "How To Thrive as a Remote Workforce",
          "url": "/node/27441/edit",
          "course_catalog_id": "yk53l",
          "learning_journeys_arr": [
            "Leading Self",
            "Self-Development"
          ],
          "learning_journeys": "Leading Self|Self-Development",
          "personas_arr": [
            "CEO",
            "Executive",
            "Manager",
            "Staff"
          ],
          "personas": "CEO|Executive|Manager|Staff",
          "content_classification": "CEO|Executive|Manager|Staff",
          "topics_arr": [],
          "topics": "",
          "genres_arr": [],
          "genres": "",
          "series": "General",
          "tiers_arr": [
            "tier_1",
            "tier_2"
          ],
          "tiers": "tier_1|tier_2",
          "tiers_access_levels": [
            "Unlimited & Unlimited+ Members",
            "Individual Members"
          ],
          "content_role_arr": [
            "tier_1",
            "tier_2"
          ],
          "content_role": "tier_1|tier_2",
          "status": "active",
          "drupal_id": "27441",
          "content_type": "course",
          "author": null,
          "updated_at": "2024-03-07 11:18:20",
          "creation_timestamp": "2024-03-07 11:16:59",
          "recommendation_id": "RID-1f-40c9-a26c-dfa978d7e683-CID-ba8cb2",
          "isAdminEnrolled": false,
          "course_record": {
            "name": "How To Thrive as a Remote Workforce",
            "type": "Course",
            "catalog_id": "yk53l",
            "release_date": "2023-11-01T05:01:00Z",
            "close_date": null,
            "class_summary": "\u003Cp\u003EA remote work culture is becoming a common way of business operations. This course is curated to equip L&D teams and managers with resources and materials that can influence a positive, thriving, and healthy remote work culture.\u003C/p\u003E",
            "course_url": "https://cues.novoed.com/#!/courses/yk53l/home"
          }
        },
        {
          "course_id": "31235",
          "item_id": "31235",
          "title": "Customer Focus - Harvard ManageMentor",
          "url": "/node/27846/edit",
          "course_catalog_id": "hmm-cust-focus",
          "learning_journeys_arr": [
            "Harvard ManageMentor Learning Journey"
          ],
          "learning_journeys": "Harvard ManageMentor Learning Journey",
          "personas_arr": [
            "Executive",
            "Manager",
            "Staff"
          ],
          "personas": "Executive|Manager|Staff",
          "content_classification": "Executive|Manager|Staff",
          "topics_arr": [
            "Human Resources",
            "Operations"
          ],
          "topics": "Human Resources|Operations",
          "genres_arr": [
            "Human Resources",
            "Operations"
          ],
          "genres": "Human Resources|Operations",
          "series": "HMM",
          "tiers_arr": [
            "tier_1"
          ],
          "tiers": "tier_1",
          "tiers_access_levels": [
            "Unlimited & Unlimited+ Members"
          ],
          "content_role_arr": [
            "tier_1"
          ],
          "content_role": "tier_1",
          "status": "active",
          "drupal_id": "27846",
          "content_type": "course",
          "author": null,
          "updated_at": "2024-03-21 14:46:54",
          "creation_timestamp": "2024-03-08 08:55:40",
          "recommendation_id": "RID-1f-40c9-a26c-dfa978d7e683-CID-ba8cb2",
          "isAdminEnrolled": false,
          "course_record": {
            "name": "Customer Focus - Harvard ManageMentor",
            "type": "Course",
            "catalog_id": "hmm-cust-focus",
            "release_date": "2023-11-01T04:01:00Z",
            "close_date": null,
            "class_summary": "\u003Cp\u003ECredit unions maintain their trajectory by prioritizing members as their primary focus. Gain valuable insights into effectively meeting member needs and implementing actions that lead to enhanced member satisfaction.\u003C/p\u003E",
            "course_url": "https://cues.novoed.com/#!/courses/hmm-cust-focus/home"
          }
        },
        {
          "course_id": "30318",
          "item_id": "30318",
          "title": "Critical Thinking",
          "url": "/node/27591/edit",
          "course_catalog_id": "lrne3",
          "learning_journeys_arr": [
            "Critical Thinking & Decision Making",
            "Leading Self"
          ],
          "learning_journeys": "Critical Thinking & Decision Making|Leading Self",
          "personas_arr": [
            "Executive",
            "Manager",
            "Staff"
          ],
          "personas": "Executive|Manager|Staff",
          "content_classification": "Executive|Manager|Staff",
          "topics_arr": [],
          "topics": "",
          "genres_arr": [],
          "genres": "",
          "series": "General",
          "tiers_arr": [
            "tier_1",
            "tier_2"
          ],
          "tiers": "tier_1|tier_2",
          "tiers_access_levels": [
            "Unlimited & Unlimited+ Members",
            "Individual Members"
          ],
          "content_role_arr": [
            "tier_1",
            "tier_2"
          ],
          "content_role": "tier_1|tier_2",
          "status": "active",
          "drupal_id": "27591",
          "content_type": "course",
          "author": null,
          "updated_at": "2024-05-20 14:39:09",
          "creation_timestamp": "2024-03-07 13:06:02",
          "recommendation_id": "RID-1f-40c9-a26c-dfa978d7e683-CID-ba8cb2",
          "isAdminEnrolled": true,
          "course_record": {
            "name": "Critical Thinking",
            "type": "Course",
            "catalog_id": "lrne3",
            "release_date": "2023-11-01T04:01:00Z",
            "close_date": null,
            "class_summary": "\u003Cp\u003ECritical thinking is a key skill for organizational leaders. It is disciplined thinking that is rational, open-minded, and informed by evidence. Critical thinking is not its own skill and encompasses both effective communication and problem-solving abilities. This course provides insight into critical thinking in the workplace and how to develop your skills.\u003C/p\u003E",
            "course_url": "https://cues.novoed.com/#!/courses/lrne3/home"
          }
        },
        {
          "course_id": "29891",
          "item_id": "29891",
          "title": "Change Agility",
          "url": "/node/27616/edit",
          "course_catalog_id": "v4par",
          "learning_journeys_arr": [
            "Change Management",
            "Leading Others"
          ],
          "learning_journeys": "Change Management|Leading Others",
          "personas_arr": [
            "CEO",
            "Executive",
            "Manager"
          ],
          "personas": "CEO|Executive|Manager",
          "content_classification": "CEO|Executive|Manager",
          "topics_arr": [
            "Operations"
          ],
          "topics": "Operations",
          "genres_arr": [
            "Operations"
          ],
          "genres": "Operations",
          "series": "General",
          "tiers_arr": [
            "tier_1",
            "tier_2"
          ],
          "tiers": "tier_1|tier_2",
          "tiers_access_levels": [
            "Unlimited & Unlimited+ Members",
            "Individual Members"
          ],
          "content_role_arr": [
            "tier_1",
            "tier_2"
          ],
          "content_role": "tier_1|tier_2",
          "status": "active",
          "drupal_id": "27616",
          "content_type": "course",
          "author": null,
          "updated_at": "2024-05-20 14:36:22",
          "creation_timestamp": "2024-03-07 13:13:24",
          "recommendation_id": "RID-1f-40c9-a26c-dfa978d7e683-CID-ba8cb2",
          "isAdminEnrolled": false,
          "course_record": {
            "name": "Change Agility",
            "type": "Course",
            "catalog_id": "v4par",
            "release_date": "2023-11-01T04:01:00Z",
            "close_date": null,
            "class_summary": "\u003Cp\u003EEmbrace change. By adapting and remaining agile with change you can build new skills that will help you develop and become a resource for your organization.\u003C/p\u003E",
            "course_url": "https://cues.novoed.com/#!/courses/v4par/home"
          }
        },
        {
          "course_id": "30314",
          "item_id": "30314",
          "title": "Problem Solving",
          "url": "/node/27371/edit",
          "course_catalog_id": "328b5",
          "learning_journeys_arr": [
            "Critical Thinking & Decision Making",
            "Leading Self"
          ],
          "learning_journeys": "Critical Thinking & Decision Making|Leading Self",
          "personas_arr": [
            "CEO",
            "Executive",
            "Manager"
          ],
          "personas": "CEO|Executive|Manager",
          "content_classification": "CEO|Executive|Manager",
          "topics_arr": [],
          "topics": "",
          "genres_arr": [],
          "genres": "",
          "series": "General",
          "tiers_arr": [
            "tier_1",
            "tier_2"
          ],
          "tiers": "tier_1|tier_2",
          "tiers_access_levels": [
            "Unlimited & Unlimited+ Members",
            "Individual Members"
          ],
          "content_role_arr": [
            "tier_1",
            "tier_2"
          ],
          "content_role": "tier_1|tier_2",
          "status": "active",
          "drupal_id": "27371",
          "content_type": "course",
          "author": null,
          "updated_at": "2024-03-07 10:43:54",
          "creation_timestamp": "2024-03-07 10:42:36",
          "recommendation_id": "RID-1f-40c9-a26c-dfa978d7e683-CID-ba8cb2",
          "isAdminEnrolled": false,
          "course_record": {
            "name": "Problem Solving",
            "type": "Course",
            "catalog_id": "328b5",
            "release_date": "2023-11-01T04:01:00Z",
            "close_date": null,
            "class_summary": "\u003Cp\u003EBuild your skills in identifying effective solutions to problems, individually and with others. Problem solving is linked to other cognitive competencies including self-management and critical thinking. It is a key workplace skill that empowers employees to manage change, communicate effectively and bring a fresh perspective to old problems.\u003C/p\u003E",
            "course_url": "https://cues.novoed.com/#!/courses/328b5/home"
          }
        },
        {
          "course_id": "30321",
          "item_id": "30321",
          "title": "Giving Effective Feedback",
          "url": "/node/27446/edit",
          "course_catalog_id": "v1epj",
          "learning_journeys_arr": [
            "Feedback",
            "Leading Others"
          ],
          "learning_journeys": "Feedback|Leading Others",
          "personas_arr": [
            "CEO",
            "Executive",
            "Manager"
          ],
          "personas": "CEO|Executive|Manager",
          "content_classification": "CEO|Executive|Manager",
          "topics_arr": [
            "Growth",
            "Leadership"
          ],
          "topics": "Growth|Leadership",
          "genres_arr": [
            "Growth",
            "Leadership"
          ],
          "genres": "Growth|Leadership",
          "series": "General",
          "tiers_arr": [
            "tier_1",
            "tier_2"
          ],
          "tiers": "tier_1|tier_2",
          "tiers_access_levels": [
            "Unlimited & Unlimited+ Members",
            "Individual Members"
          ],
          "content_role_arr": [
            "tier_1",
            "tier_2"
          ],
          "content_role": "tier_1|tier_2",
          "status": "active",
          "drupal_id": "27446",
          "content_type": "course",
          "author": null,
          "updated_at": "2024-03-07 11:22:51",
          "creation_timestamp": "2024-03-07 11:21:45",
          "recommendation_id": "RID-1f-40c9-a26c-dfa978d7e683-CID-ba8cb2",
          "isAdminEnrolled": false,
          "course_record": {
            "name": "Giving Effective Feedback",
            "type": "Course",
            "catalog_id": "v1epj",
            "release_date": "2023-11-01T04:01:00Z",
            "close_date": null,
            "class_summary": "\u003Cp\u003EBeing able to give effective feedback is essential to your professional effectiveness and critical to the success of your organization. Giving good feedback can help you more effectively address a variety of business needs, such as identifying new market opportunities, developing new products, and improving processes. Whatever your role, whether individual contributor or leader, this course will help you:\u003C/p\u003E\u003Cp\u003E• Deepen your knowledge of feedback concepts and methods\u003C/p\u003E\u003Cp\u003E• Understand how feedback can be applied to help address a variety of business challenges\u003C/p\u003E\u003Cp\u003E• Strengthen your ability to give effective feedback on which business problems or opportunities are most important to address\u003C/p\u003E",
            "course_url": "https://cues.novoed.com/#!/courses/v1epj/home"
          }
        },
        {
          "course_id": "30309",
          "item_id": "30309",
          "title": "Difficult Conversations",
          "url": "/node/27486/edit",
          "course_catalog_id": "rywag",
          "learning_journeys_arr": [
            "Communication",
            "Leading Others"
          ],
          "learning_journeys": "Communication|Leading Others",
          "personas_arr": [
            "Executive",
            "Manager"
          ],
          "personas": "Executive|Manager",
          "content_classification": "Executive|Manager",
          "topics_arr": [
            "Growth",
            "Human Resources"
          ],
          "topics": "Growth|Human Resources",
          "genres_arr": [
            "Growth",
            "Human Resources"
          ],
          "genres": "Growth|Human Resources",
          "series": "General",
          "tiers_arr": [
            "tier_1",
            "tier_2"
          ],
          "tiers": "tier_1|tier_2",
          "tiers_access_levels": [
            "Unlimited & Unlimited+ Members",
            "Individual Members"
          ],
          "content_role_arr": [
            "tier_1",
            "tier_2"
          ],
          "content_role": "tier_1|tier_2",
          "status": "active",
          "drupal_id": "27486",
          "content_type": "course",
          "author": null,
          "updated_at": "2024-03-07 11:50:38",
          "creation_timestamp": "2024-03-07 11:49:39",
          "recommendation_id": "RID-1f-40c9-a26c-dfa978d7e683-CID-ba8cb2",
          "isAdminEnrolled": false,
          "course_record": {
            "name": "Difficult Conversations",
            "type": "Course",
            "catalog_id": "rywag",
            "release_date": "2023-11-01T04:01:00Z",
            "close_date": null,
            "class_summary": "\u003Cp\u003EManaging difficult conversations is an inevitable part of everyone's job. Your ability to successfully navigate challenging interactions builds your credibility as a trustworthy colleague and contributes to the effectiveness of our organization.\u003C/p\u003E\u003Cp\u003EIn this course you will learn how to:\u003C/p\u003E\u003Cp\u003E• Identify the causes of difficult conversations\u003Cbr\u003E • Communicate effectively during a difficult conversation\u003Cbr\u003E • Resolve conflicts\u003C/p\u003E",
            "course_url": "https://cues.novoed.com/#!/courses/rywag/home"
          }
        },
        {
          "course_id": "29883",
          "item_id": "29883",
          "title": "Ensure Accountability",
          "url": "/node/27476/edit",
          "course_catalog_id": "wr3ar",
          "learning_journeys_arr": [
            "Applied Leadership",
            "Leading Others"
          ],
          "learning_journeys": "Applied Leadership|Leading Others",
          "personas_arr": [
            "CEO",
            "Executive",
            "Manager"
          ],
          "personas": "CEO|Executive|Manager",
          "content_classification": "CEO|Executive|Manager",
          "topics_arr": [
            "Leadership"
          ],
          "topics": "Leadership",
          "genres_arr": [
            "Leadership"
          ],
          "genres": "Leadership",
          "series": "General",
          "tiers_arr": [
            "tier_1",
            "tier_2"
          ],
          "tiers": "tier_1|tier_2",
          "tiers_access_levels": [
            "Unlimited & Unlimited+ Members",
            "Individual Members"
          ],
          "content_role_arr": [
            "tier_1",
            "tier_2"
          ],
          "content_role": "tier_1|tier_2",
          "status": "active",
          "drupal_id": "27476",
          "content_type": "course",
          "author": null,
          "updated_at": "2024-03-07 11:42:40",
          "creation_timestamp": "2024-03-07 11:41:35",
          "recommendation_id": "RID-1f-40c9-a26c-dfa978d7e683-CID-ba8cb2",
          "isAdminEnrolled": false,
          "course_record": {
            "name": "Ensure Accountability",
            "type": "Course",
            "catalog_id": "wr3ar",
            "release_date": "2023-11-01T04:01:00Z",
            "close_date": null,
            "class_summary": "\u003Cp\u003EAccountability is a responsibility taken to the next level. To have accountability, everyone must take ownership of the outcomes they are responsible for. Individuals that model accountability at the highest level are empowered.\u003C/p\u003E",
            "course_url": "https://cues.novoed.com/#!/courses/wr3ar/home"
          }
        },
        {
          "course_id": "30459",
          "item_id": "30459",
          "title": "Basics of Business Acumen",
          "url": "/node/27296/edit",
          "course_catalog_id": "jbv8a",
          "learning_journeys_arr": [
            "Leading the Business",
            "Operations, Strategy, & Regulatory"
          ],
          "learning_journeys": "Leading the Business|Operations, Strategy, & Regulatory",
          "personas_arr": [
            "Executive"
          ],
          "personas": "Executive",
          "content_classification": "Executive",
          "topics_arr": [
            "Leadership",
            "Operations",
            "Strategy"
          ],
          "topics": "Leadership|Operations|Strategy",
          "genres_arr": [
            "Leadership",
            "Operations",
            "Strategy"
          ],
          "genres": "Leadership|Operations|Strategy",
          "series": "General",
          "tiers_arr": [
            "tier_1",
            "tier_2"
          ],
          "tiers": "tier_1|tier_2",
          "tiers_access_levels": [
            "Unlimited & Unlimited+ Members",
            "Individual Members"
          ],
          "content_role_arr": [
            "tier_1",
            "tier_2"
          ],
          "content_role": "tier_1|tier_2",
          "status": "active",
          "drupal_id": "27296",
          "content_type": "course",
          "author": null,
          "updated_at": "2024-03-07 08:55:53",
          "creation_timestamp": "2024-03-07 08:52:38",
          "recommendation_id": "RID-1f-40c9-a26c-dfa978d7e683-CID-ba8cb2",
          "isAdminEnrolled": false,
          "course_record": {
            "name": "Basics of Business Acumen",
            "type": "Course",
            "catalog_id": "jbv8a",
            "release_date": "2023-11-01T04:01:00Z",
            "close_date": null,
            "class_summary": "\u003Cp\u003EIn this course, you will learn about what business acumen is, the value it provides an individual, and ways you can develop it.\u003C/p\u003E",
            "course_url": "https://cues.novoed.com/#!/courses/jbv8a/home"
          }
        },
        {
          "course_id": "31284",
          "item_id": "31284",
          "title": "Project Management - Harvard ManageMentor",
          "url": "/node/27731/edit",
          "course_catalog_id": "hmm-project-mgmt",
          "learning_journeys_arr": [
            "Harvard ManageMentor Learning Journey"
          ],
          "learning_journeys": "Harvard ManageMentor Learning Journey",
          "personas_arr": [
            "Executive",
            "Manager",
            "Staff"
          ],
          "personas": "Executive|Manager|Staff",
          "content_classification": "Executive|Manager|Staff",
          "topics_arr": [
            "Human Resources",
            "Marketing",
            "Operations"
          ],
          "topics": "Human Resources|Marketing|Operations",
          "genres_arr": [
            "Human Resources",
            "Marketing",
            "Operations"
          ],
          "genres": "Human Resources|Marketing|Operations",
          "series": "HMM",
          "tiers_arr": [
            "tier_1"
          ],
          "tiers": "tier_1",
          "tiers_access_levels": [
            "Unlimited & Unlimited+ Members"
          ],
          "content_role_arr": [
            "tier_1"
          ],
          "content_role": "tier_1",
          "status": "active",
          "drupal_id": "27731",
          "content_type": "course",
          "author": null,
          "updated_at": "2024-03-21 15:18:54",
          "creation_timestamp": "2024-03-08 08:32:25",
          "recommendation_id": "RID-1f-40c9-a26c-dfa978d7e683-CID-ba8cb2",
          "isAdminEnrolled": false,
          "course_record": {
            "name": "Project Management - Harvard ManageMentor",
            "type": "Course",
            "catalog_id": "hmm-project-mgmt",
            "release_date": "2023-11-01T04:01:00Z",
            "close_date": null,
            "class_summary": "\u003Cp\u003EThe impact of successful project management extends beyond immediate outcomes, leaving a lasting impression of excellence and accomplishment for your organization. Learn project management best practices to orchestrate seamless collaboration and deliver exceptional results that drive success and elevate your organization's competitive edge.\u003C/p\u003E",
            "course_url": "https://cues.novoed.com/#!/courses/hmm-project-mgmt/home"
          }
        },
        {
          "course_id": "30677",
          "item_id": "30677",
          "title": "Build Trust in the Workplace",
          "url": "/node/27316/edit",
          "course_catalog_id": "alea2",
          "learning_journeys_arr": [
            "Leading Others",
            "Trust"
          ],
          "learning_journeys": "Leading Others|Trust",
          "personas_arr": [
            "CEO",
            "Executive",
            "Manager"
          ],
          "personas": "CEO|Executive|Manager",
          "content_classification": "CEO|Executive|Manager",
          "topics_arr": [
            "Growth",
            "Human Resources",
            "Leadership"
          ],
          "topics": "Growth|Human Resources|Leadership",
          "genres_arr": [
            "Growth",
            "Human Resources",
            "Leadership"
          ],
          "genres": "Growth|Human Resources|Leadership",
          "series": "General",
          "tiers_arr": [
            "tier_1",
            "tier_2"
          ],
          "tiers": "tier_1|tier_2",
          "tiers_access_levels": [
            "Unlimited & Unlimited+ Members",
            "Individual Members"
          ],
          "content_role_arr": [
            "tier_1",
            "tier_2"
          ],
          "content_role": "tier_1|tier_2",
          "status": "active",
          "drupal_id": "27316",
          "content_type": "course",
          "author": null,
          "updated_at": "2024-03-07 09:14:30",
          "creation_timestamp": "2024-03-07 09:13:19",
          "recommendation_id": "RID-1f-40c9-a26c-dfa978d7e683-CID-ba8cb2",
          "isAdminEnrolled": false,
          "course_record": {
            "name": "Build Trust in the Workplace",
            "type": "Course",
            "catalog_id": "alea2",
            "release_date": "2023-11-01T04:01:00Z",
            "close_date": null,
            "class_summary": "\u003Cp\u003EGaining the confidence and trust of others through honesty, integrity, and authenticity. Business leaders who are proficient and deliberate in exercising the 3 Cs (competence, caring and consistent values) are successful in establishing trusting relationships, laying the foundation for increased productivity and better morale.\u003C/p\u003E",
            "course_url": "https://cues.novoed.com/#!/courses/alea2/home"
          }
        },
        {
          "course_id": "31227",
          "item_id": "31227",
          "title": "Budgeting - Harvard ManageMentor",
          "url": "/node/27676/edit",
          "course_catalog_id": "hmm-budgeting",
          "learning_journeys_arr": [
            "Harvard ManageMentor Learning Journey"
          ],
          "learning_journeys": "Harvard ManageMentor Learning Journey",
          "personas_arr": [
            "CEO",
            "Executive",
            "Manager"
          ],
          "personas": "CEO|Executive|Manager",
          "content_classification": "CEO|Executive|Manager",
          "topics_arr": [
            "Operations"
          ],
          "topics": "Operations",
          "genres_arr": [
            "Operations"
          ],
          "genres": "Operations",
          "series": "HMM",
          "tiers_arr": [
            "tier_1"
          ],
          "tiers": "tier_1",
          "tiers_access_levels": [
            "Unlimited & Unlimited+ Members"
          ],
          "content_role_arr": [
            "tier_1"
          ],
          "content_role": "tier_1",
          "status": "active",
          "drupal_id": "27676",
          "content_type": "course",
          "author": null,
          "updated_at": "2024-03-21 14:41:40",
          "creation_timestamp": "2024-03-08 08:21:53",
          "recommendation_id": "RID-1f-40c9-a26c-dfa978d7e683-CID-ba8cb2",
          "isAdminEnrolled": false,
          "course_record": {
            "name": "Budgeting - Harvard ManageMentor",
            "type": "Course",
            "catalog_id": "hmm-budgeting",
            "release_date": "2023-11-01T04:01:00Z",
            "close_date": null,
            "class_summary": "\u003Cp\u003ERegardless of their field, budgeting is a fundamental skill that every individual should possess. As a leader, it is essential to be mindful of the budget and available resources while making decisions. Hone your budgeting skills with this course and effectively steer the organization towards success and sustainable growth.\u003C/p\u003E",
            "course_url": "https://cues.novoed.com/#!/courses/hmm-budgeting/home"
          }
        },
        {
          "course_id": "31273",
          "item_id": "31273",
          "title": "Leading People - Harvard ManageMentor",
          "url": "/node/27786/edit",
          "course_catalog_id": "hmm-leading-people",
          "learning_journeys_arr": [
            "Harvard ManageMentor Learning Journey"
          ],
          "learning_journeys": "Harvard ManageMentor Learning Journey",
          "personas_arr": [
            "CEO",
            "Executive",
            "Manager"
          ],
          "personas": "CEO|Executive|Manager",
          "content_classification": "CEO|Executive|Manager",
          "topics_arr": [
            "Leadership"
          ],
          "topics": "Leadership",
          "genres_arr": [
            "Leadership"
          ],
          "genres": "Leadership",
          "series": "HMM",
          "tiers_arr": [
            "tier_1"
          ],
          "tiers": "tier_1",
          "tiers_access_levels": [
            "Unlimited & Unlimited+ Members"
          ],
          "content_role_arr": [
            "tier_1"
          ],
          "content_role": "tier_1",
          "status": "active",
          "drupal_id": "27786",
          "content_type": "course",
          "author": null,
          "updated_at": "2024-03-21 15:09:13",
          "creation_timestamp": "2024-03-08 08:44:58",
          "recommendation_id": "RID-1f-40c9-a26c-dfa978d7e683-CID-ba8cb2",
          "isAdminEnrolled": false,
          "course_record": {
            "name": "Leading People - Harvard ManageMentor",
            "type": "Course",
            "catalog_id": "hmm-leading-people",
            "release_date": "2023-11-01T04:01:00Z",
            "close_date": null,
            "class_summary": "\u003Cp\u003EBecoming an effective and inspiring leader starts with defining your values and vision while honing your self-management skills. Align your leadership with purpose and foster a culture of trust and collaboration, creating a unified and high-performing team that drives collective success.\u003C/p\u003E",
            "course_url": "https://cues.novoed.com/#!/courses/hmm-leading-people/home"
          }
        },
        {
          "course_id": "31262",
          "item_id": "31262",
          "title": "Developing Employees - Harvard ManageMentor",
          "url": "/node/27831/edit",
          "course_catalog_id": "hmm-devel-employees",
          "learning_journeys_arr": [
            "Harvard ManageMentor Learning Journey"
          ],
          "learning_journeys": "Harvard ManageMentor Learning Journey",
          "personas_arr": [
            "CEO",
            "Executive",
            "Manager"
          ],
          "personas": "CEO|Executive|Manager",
          "content_classification": "CEO|Executive|Manager",
          "topics_arr": [
            "Human Resources",
            "Leadership"
          ],
          "topics": "Human Resources|Leadership",
          "genres_arr": [
            "Human Resources",
            "Leadership"
          ],
          "genres": "Human Resources|Leadership",
          "series": "HMM",
          "tiers_arr": [
            "tier_1"
          ],
          "tiers": "tier_1",
          "tiers_access_levels": [
            "Unlimited & Unlimited+ Members"
          ],
          "content_role_arr": [
            "tier_1"
          ],
          "content_role": "tier_1",
          "status": "active",
          "drupal_id": "27831",
          "content_type": "course",
          "author": null,
          "updated_at": "2024-03-21 14:48:55",
          "creation_timestamp": "2024-03-08 08:52:19",
          "recommendation_id": "RID-1f-40c9-a26c-dfa978d7e683-CID-ba8cb2",
          "isAdminEnrolled": false,
          "course_record": {
            "name": "Developing Employees - Harvard ManageMentor",
            "type": "Course",
            "catalog_id": "hmm-devel-employees",
            "release_date": "2023-11-01T04:01:00Z",
            "close_date": null,
            "class_summary": "\u003Cp\u003EPrioritizing the development of your employees is essential to keeping your team and organization on the path to success. Explore effective ways to foster growth even when your schedule is tight, curate enriching learning experiences, and craft development plans that leave a lasting impact.\u003C/p\u003E",
            "course_url": "https://cues.novoed.com/#!/courses/hmm-devel-employees/home"
          }
        },
        {
          "course_id": "31236",
          "item_id": "31236",
          "title": "Decision Making - Harvard ManageMentor",
          "url": "/node/27841/edit",
          "course_catalog_id": "hmm-decision-making",
          "learning_journeys_arr": [
            "Harvard ManageMentor Learning Journey"
          ],
          "learning_journeys": "Harvard ManageMentor Learning Journey",
          "personas_arr": [
            "CEO",
            "Executive",
            "Manager",
            "Staff",
            "Board"
          ],
          "personas": "CEO|Executive|Manager|Staff|Board",
          "content_classification": "CEO|Executive|Manager|Staff|Board",
          "topics_arr": [
            "Governance",
            "Leadership",
            "Strategy"
          ],
          "topics": "Governance|Leadership|Strategy",
          "genres_arr": [
            "Governance",
            "Leadership",
            "Strategy"
          ],
          "genres": "Governance|Leadership|Strategy",
          "series": "HMM",
          "tiers_arr": [
            "tier_1"
          ],
          "tiers": "tier_1",
          "tiers_access_levels": [
            "Unlimited & Unlimited+ Members"
          ],
          "content_role_arr": [
            "tier_1"
          ],
          "content_role": "tier_1",
          "status": "active",
          "drupal_id": "27841",
          "content_type": "course",
          "author": null,
          "updated_at": "2024-03-21 14:47:32",
          "creation_timestamp": "2024-03-08 08:55:09",
          "recommendation_id": "RID-1f-40c9-a26c-dfa978d7e683-CID-ba8cb2",
          "isAdminEnrolled": false,
          "course_record": {
            "name": "Decision Making - Harvard ManageMentor",
            "type": "Course",
            "catalog_id": "hmm-decision-making",
            "release_date": "2023-11-01T04:01:00Z",
            "close_date": null,
            "class_summary": "\u003Cp\u003ERegardless of your leadership status, you will encounter numerous situations that demand decision-making. The ability to make intelligent choices is a crucial factor in achieving business success. Elevate your decision-making skills by understanding how to steer clear of common pitfalls and adopt an effective process that fosters innovative thinking.\u003C/p\u003E",
            "course_url": "https://cues.novoed.com/#!/courses/hmm-decision-making/home"
          }
        },
        {
          "course_id": "30326",
          "item_id": "30326",
          "title": "Building Effective Teams",
          "url": "/node/27646/edit",
          "course_catalog_id": "pkn6m",
          "learning_journeys_arr": [
            "Leading Others",
            "Leading Teams"
          ],
          "learning_journeys": "Leading Others|Leading Teams",
          "personas_arr": [
            "Executive",
            "Manager"
          ],
          "personas": "Executive|Manager",
          "content_classification": "Executive|Manager",
          "topics_arr": [
            "Leadership",
            "Strategy"
          ],
          "topics": "Leadership|Strategy",
          "genres_arr": [
            "Leadership",
            "Strategy"
          ],
          "genres": "Leadership|Strategy",
          "series": "General",
          "tiers_arr": [
            "tier_1",
            "tier_2"
          ],
          "tiers": "tier_1|tier_2",
          "tiers_access_levels": [
            "Unlimited & Unlimited+ Members",
            "Individual Members"
          ],
          "content_role_arr": [
            "tier_1",
            "tier_2"
          ],
          "content_role": "tier_1|tier_2",
          "status": "active",
          "drupal_id": "27646",
          "content_type": "course",
          "author": null,
          "updated_at": "2024-05-20 14:33:13",
          "creation_timestamp": "2024-03-07 13:23:08",
          "recommendation_id": "RID-1f-40c9-a26c-dfa978d7e683-CID-ba8cb2",
          "isAdminEnrolled": false,
          "course_record": {
            "name": "Building Effective Teams",
            "type": "Course",
            "catalog_id": "pkn6m",
            "release_date": "2023-11-01T04:01:00Z",
            "close_date": null,
            "class_summary": "\u003Cp\u003EA team can be defined as a group of people with complementary skills who work together to accomplish something beyond their individual self-interests. The belief that working in teams makes us more creative and productive is so widespread that when faced with a challenging, new task, organizations are quick to assume that teams are the best way to get the job done.\u003C/p\u003E",
            "course_url": "https://cues.novoed.com/#!/courses/pkn6m/home"
          }
        },
        {
          "course_id": "29887",
          "item_id": "29887",
          "title": "Leading Effective Meetings",
          "url": "/node/27391/edit",
          "course_catalog_id": "ryee9",
          "learning_journeys_arr": [
            "Applied Leadership"
          ],
          "learning_journeys": "Applied Leadership",
          "personas_arr": [
            "CEO",
            "Executive",
            "Manager"
          ],
          "personas": "CEO|Executive|Manager",
          "content_classification": "CEO|Executive|Manager",
          "topics_arr": [],
          "topics": "",
          "genres_arr": [],
          "genres": "",
          "series": "General",
          "tiers_arr": [
            "tier_1",
            "tier_2"
          ],
          "tiers": "tier_1|tier_2",
          "tiers_access_levels": [
            "Unlimited & Unlimited+ Members",
            "Individual Members"
          ],
          "content_role_arr": [
            "tier_1",
            "tier_2"
          ],
          "content_role": "tier_1|tier_2",
          "status": "active",
          "drupal_id": "27391",
          "content_type": "course",
          "author": null,
          "updated_at": "2024-03-07 10:56:39",
          "creation_timestamp": "2024-03-07 10:54:57",
          "recommendation_id": "RID-1f-40c9-a26c-dfa978d7e683-CID-ba8cb2",
          "isAdminEnrolled": false,
          "course_record": {
            "name": "Leading Effective Meetings",
            "type": "Course",
            "catalog_id": "ryee9",
            "release_date": "2023-11-01T04:01:00Z",
            "close_date": null,
            "class_summary": "\u003Cp\u003EManaging a Meeting - easy! Right? Wrong? You can't deny that a good meeting feels great when it takes place and a bad one feels like a huge waste of your time! Knowing the secrets of meeting management and becoming skilled in the techniques will create harmony, creativity, and productive outcomes.\u003C/p\u003E",
            "course_url": "https://cues.novoed.com/#!/courses/ryee9/home"
          }
        },
        {
          "course_id": "30444",
          "item_id": "30444",
          "title": "Managing Team Conflict",
          "url": "/node/27381/edit",
          "course_catalog_id": "45b6e",
          "learning_journeys_arr": [
            "Leading Others",
            "Leading Teams"
          ],
          "learning_journeys": "Leading Others|Leading Teams",
          "personas_arr": [
            "CEO",
            "Executive",
            "Manager"
          ],
          "personas": "CEO|Executive|Manager",
          "content_classification": "CEO|Executive|Manager",
          "topics_arr": [
            "Leadership"
          ],
          "topics": "Leadership",
          "genres_arr": [
            "Leadership"
          ],
          "genres": "Leadership",
          "series": "General",
          "tiers_arr": [
            "tier_1",
            "tier_2"
          ],
          "tiers": "tier_1|tier_2",
          "tiers_access_levels": [
            "Unlimited & Unlimited+ Members",
            "Individual Members"
          ],
          "content_role_arr": [
            "tier_1",
            "tier_2"
          ],
          "content_role": "tier_1|tier_2",
          "status": "active",
          "drupal_id": "27381",
          "content_type": "course",
          "author": null,
          "updated_at": "2024-03-07 10:47:19",
          "creation_timestamp": "2024-03-07 10:46:20",
          "recommendation_id": "RID-1f-40c9-a26c-dfa978d7e683-CID-ba8cb2",
          "isAdminEnrolled": false,
          "course_record": {
            "name": "Managing Team Conflict",
            "type": "Course",
            "catalog_id": "45b6e",
            "release_date": "2023-11-01T04:01:00Z",
            "close_date": null,
            "class_summary": "\u003Cp\u003EManaging conflict effectively can lead to positive results for your team. This course will:\u003C/p\u003E\u003Cp\u003E• Review conflict management techniques\u003Cbr\u003E • Analyze and prepare to manage conflict\u003Cbr\u003E • Facilitate a conflict resolution discussion\u003Cbr\u003E • Follow up after a conflict resolution discussion\u003C/p\u003E",
            "course_url": "https://cues.novoed.com/#!/courses/45b6e/home"
          }
        },
        {
          "course_id": "31288",
          "item_id": "31288",
          "title": "Stress Management - Harvard ManageMentor",
          "url": "/node/27716/edit",
          "course_catalog_id": "hmm-stress-mgmt",
          "learning_journeys_arr": [
            "Harvard ManageMentor Learning Journey"
          ],
          "learning_journeys": "Harvard ManageMentor Learning Journey",
          "personas_arr": [
            "CEO",
            "Executive",
            "Manager",
            "Staff",
            "Board"
          ],
          "personas": "CEO|Executive|Manager|Staff|Board",
          "content_classification": "CEO|Executive|Manager|Staff|Board",
          "topics_arr": [
            "Governance",
            "Human Resources",
            "Leadership"
          ],
          "topics": "Governance|Human Resources|Leadership",
          "genres_arr": [
            "Governance",
            "Human Resources",
            "Leadership"
          ],
          "genres": "Governance|Human Resources|Leadership",
          "series": "HMM",
          "tiers_arr": [
            "tier_1"
          ],
          "tiers": "tier_1",
          "tiers_access_levels": [
            "Unlimited & Unlimited+ Members"
          ],
          "content_role_arr": [
            "tier_1"
          ],
          "content_role": "tier_1",
          "status": "active",
          "drupal_id": "27716",
          "content_type": "course",
          "author": null,
          "updated_at": "2024-03-21 15:21:23",
          "creation_timestamp": "2024-03-08 08:29:20",
          "recommendation_id": "RID-1f-40c9-a26c-dfa978d7e683-CID-ba8cb2",
          "isAdminEnrolled": false,
          "course_record": {
            "name": "Stress Management - Harvard ManageMentor",
            "type": "Course",
            "catalog_id": "hmm-stress-mgmt",
            "release_date": "2023-11-01T04:01:00Z",
            "close_date": null,
            "class_summary": "\u003Cp\u003EManaging stress is a transformative skill that not only enhances your personal well-being but also fosters a positive and vibrant work environment, benefiting both you and your team. Cultivate resilience and pave the way for a more fulfilling and balanced life.\u003C/p\u003E",
            "course_url": "https://cues.novoed.com/#!/courses/hmm-stress-mgmt/home"
          }
        },
        {
          "course_id": "31232",
          "item_id": "31232",
          "title": "Change Management - Harvard ManageMentor",
          "url": "/node/27861/edit",
          "course_catalog_id": "hmm-change-mgmt",
          "learning_journeys_arr": [
            "Harvard ManageMentor Learning Journey"
          ],
          "learning_journeys": "Harvard ManageMentor Learning Journey",
          "personas_arr": [
            "CEO",
            "Executive",
            "Manager",
            "Staff"
          ],
          "personas": "CEO|Executive|Manager|Staff",
          "content_classification": "CEO|Executive|Manager|Staff",
          "topics_arr": [
            "Governance",
            "Growth",
            "Leadership",
            "Strategy"
          ],
          "topics": "Governance|Growth|Leadership|Strategy",
          "genres_arr": [
            "Governance",
            "Growth",
            "Leadership",
            "Strategy"
          ],
          "genres": "Governance|Growth|Leadership|Strategy",
          "series": "HMM",
          "tiers_arr": [
            "tier_1"
          ],
          "tiers": "tier_1",
          "tiers_access_levels": [
            "Unlimited & Unlimited+ Members"
          ],
          "content_role_arr": [
            "tier_1"
          ],
          "content_role": "tier_1",
          "status": "active",
          "drupal_id": "27861",
          "content_type": "course",
          "author": null,
          "updated_at": "2024-03-21 14:39:58",
          "creation_timestamp": "2024-03-08 08:57:17",
          "recommendation_id": "RID-1f-40c9-a26c-dfa978d7e683-CID-ba8cb2",
          "isAdminEnrolled": false,
          "course_record": {
            "name": "Change Management - Harvard ManageMentor",
            "type": "Course",
            "catalog_id": "hmm-change-mgmt",
            "release_date": "2023-11-01T04:01:00Z",
            "close_date": null,
            "class_summary": "\u003Cp\u003EChange can be frightening, yet it's an unavoidable aspect of life. However, with the right navigation skills, you can transform uncertainty into valuable opportunities that positively impact you, your team, and your organization. Discover diverse strategies to enhance your adaptability and embrace unexpected changes with confidence and resilience.\u003C/p\u003E",
            "course_url": "https://cues.novoed.com/#!/courses/hmm-change-mgmt/home"
          }
        },
        {
          "course_id": "31229",
          "item_id": "31229",
          "title": "Business Case Development - Harvard ManageMentor",
          "url": "/node/27671/edit",
          "course_catalog_id": "hmm-bus-case-dev",
          "learning_journeys_arr": [
            "Harvard ManageMentor Learning Journey"
          ],
          "learning_journeys": "Harvard ManageMentor Learning Journey",
          "personas_arr": [
            "CEO",
            "Executive",
            "Manager",
            "Staff"
          ],
          "personas": "CEO|Executive|Manager|Staff",
          "content_classification": "CEO|Executive|Manager|Staff",
          "topics_arr": [
            "Growth",
            "Strategy"
          ],
          "topics": "Growth|Strategy",
          "genres_arr": [
            "Growth",
            "Strategy"
          ],
          "genres": "Growth|Strategy",
          "series": "HMM",
          "tiers_arr": [
            "tier_1"
          ],
          "tiers": "tier_1",
          "tiers_access_levels": [
            "Unlimited & Unlimited+ Members"
          ],
          "content_role_arr": [
            "tier_1"
          ],
          "content_role": "tier_1",
          "status": "active",
          "drupal_id": "27671",
          "content_type": "course",
          "author": null,
          "updated_at": "2024-03-21 14:42:15",
          "creation_timestamp": "2024-03-08 08:20:03",
          "recommendation_id": "RID-1f-40c9-a26c-dfa978d7e683-CID-ba8cb2",
          "isAdminEnrolled": false,
          "course_record": {
            "name": "Business Case Development - Harvard ManageMentor",
            "type": "Course",
            "catalog_id": "hmm-bus-case-dev",
            "release_date": "2023-11-01T04:01:00Z",
            "close_date": null,
            "class_summary": "\u003Cp\u003EIdentifying a remarkable opportunity to benefit your organization is just the beginning; the key lies in effectively communicating it. Learn the art of crafting and delivering a compelling business case that aligns seamlessly with your company's strategic priorities.\u003C/p\u003E",
            "course_url": "https://cues.novoed.com/#!/courses/hmm-bus-case-dev/home"
          }
        },
        {
          "course_id": "30446",
          "item_id": "30446",
          "title": "Leading Change",
          "url": "/node/27396/edit",
          "course_catalog_id": "z0qpb",
          "learning_journeys_arr": [
            "Change Management",
            "Leading Teams",
            "Leading the Business"
          ],
          "learning_journeys": "Change Management|Leading Teams|Leading the Business",
          "personas_arr": [
            "CEO",
            "Executive",
            "Manager"
          ],
          "personas": "CEO|Executive|Manager",
          "content_classification": "CEO|Executive|Manager",
          "topics_arr": [
            "Leadership"
          ],
          "topics": "Leadership",
          "genres_arr": [
            "Leadership"
          ],
          "genres": "Leadership",
          "series": "General",
          "tiers_arr": [
            "tier_1",
            "tier_2"
          ],
          "tiers": "tier_1|tier_2",
          "tiers_access_levels": [
            "Unlimited & Unlimited+ Members",
            "Individual Members"
          ],
          "content_role_arr": [
            "tier_1",
            "tier_2"
          ],
          "content_role": "tier_1|tier_2",
          "status": "active",
          "drupal_id": "27396",
          "content_type": "course",
          "author": null,
          "updated_at": "2024-03-07 10:58:25",
          "creation_timestamp": "2024-03-07 10:56:51",
          "recommendation_id": "RID-1f-40c9-a26c-dfa978d7e683-CID-ba8cb2",
          "isAdminEnrolled": false,
          "course_record": {
            "name": "Leading Change",
            "type": "Course",
            "catalog_id": "z0qpb",
            "release_date": "2023-11-01T04:01:00Z",
            "close_date": null,
            "class_summary": "\u003Cp\u003EChange is the norm in organizational life. Today's global, interconnected business environment is in constant flux. All organizations live in a \"VUCA\" world—volatile, uncertain, complex, and ambiguous. To succeed in this context of continuous change, everyone in an organization needs to be skilled in responding to change. But leaders need to do more. Leaders need to know when and how to launch change initiatives in response to today's business challenges. Leaders also need to know how to effectively implement changes, whether they have initiated that change or not. Finally, all leaders need to know how to manage the human side of change. Change may be essential for organizational performance, but it is never comfortable or easy.\u003C/p\u003E\u003Cp\u003EThis course is designed for leaders at all levels who want to develop their ability to successfully initiate or implement change initiatives. It will help you:\u003Cbr\u003E • Be ready for change\u003Cbr\u003E • Initiate change\u003Cbr\u003E • Implement change\u003Cbr\u003E • Manage resistance to change.\u003C/p\u003E",
            "course_url": "https://cues.novoed.com/#!/courses/z0qpb/home"
          }
        },
        {
          "course_id": "29889",
          "item_id": "29889",
          "title": "Cultivating Your Personal Adaptability",
          "url": "/node/27586/edit",
          "course_catalog_id": "qve9l",
          "learning_journeys_arr": [
            "Change Management",
            "Leading Self"
          ],
          "learning_journeys": "Change Management|Leading Self",
          "personas_arr": [
            "CEO",
            "Executive",
            "Manager",
            "Staff"
          ],
          "personas": "CEO|Executive|Manager|Staff",
          "content_classification": "CEO|Executive|Manager|Staff",
          "topics_arr": [
            "Leadership"
          ],
          "topics": "Leadership",
          "genres_arr": [
            "Leadership"
          ],
          "genres": "Leadership",
          "series": "General",
          "tiers_arr": [
            "tier_1",
            "tier_2"
          ],
          "tiers": "tier_1|tier_2",
          "tiers_access_levels": [
            "Unlimited & Unlimited+ Members",
            "Individual Members"
          ],
          "content_role_arr": [
            "tier_1",
            "tier_2"
          ],
          "content_role": "tier_1|tier_2",
          "status": "active",
          "drupal_id": "27586",
          "content_type": "course",
          "author": null,
          "updated_at": "2024-05-20 13:51:46",
          "creation_timestamp": "2024-03-07 12:20:03",
          "recommendation_id": "RID-1f-40c9-a26c-dfa978d7e683-CID-ba8cb2",
          "isAdminEnrolled": false,
          "course_record": {
            "name": "Cultivating Your Personal Adaptability",
            "type": "Course",
            "catalog_id": "qve9l",
            "release_date": "2023-11-01T04:01:00Z",
            "close_date": null,
            "class_summary": "\u003Cp\u003EThis course is intended to help employees and leaders at all levels understand key elements of personal adaptability and learn about strategies for cultivating their personal adaptability to support professional and organizational success. Complete this course to discover:\u003C/p\u003E\u003Cp\u003E• Why personal adaptability matters\u003Cbr\u003E • Essential elements of personal adaptability\u003Cbr\u003E • How to enhance your personal adaptability\u003C/p\u003E",
            "course_url": "https://cues.novoed.com/#!/courses/qve9l/home"
          }
        },
        {
          "course_id": "30322",
          "item_id": "30322",
          "title": "Introduction to Leadership",
          "url": "/node/27411/edit",
          "course_catalog_id": "nnk7x",
          "learning_journeys_arr": [
            "Leadership Foundations",
            "Leading Others"
          ],
          "learning_journeys": "Leadership Foundations|Leading Others",
          "personas_arr": [
            "Manager",
            "Staff"
          ],
          "personas": "Manager|Staff",
          "content_classification": "Manager|Staff",
          "topics_arr": [
            "Leadership"
          ],
          "topics": "Leadership",
          "genres_arr": [
            "Leadership"
          ],
          "genres": "Leadership",
          "series": "General",
          "tiers_arr": [
            "tier_1",
            "tier_2"
          ],
          "tiers": "tier_1|tier_2",
          "tiers_access_levels": [
            "Unlimited & Unlimited+ Members",
            "Individual Members"
          ],
          "content_role_arr": [
            "tier_1",
            "tier_2"
          ],
          "content_role": "tier_1|tier_2",
          "status": "active",
          "drupal_id": "27411",
          "content_type": "course",
          "author": null,
          "updated_at": "2024-03-08 09:14:04",
          "creation_timestamp": "2024-03-07 11:02:22",
          "recommendation_id": "RID-1f-40c9-a26c-dfa978d7e683-CID-ba8cb2",
          "isAdminEnrolled": true,
          "course_record": {
            "name": "Introduction to Leadership",
            "type": "Course",
            "catalog_id": "nnk7x",
            "release_date": "2023-11-01T04:01:00Z",
            "close_date": null,
            "class_summary": "\u003Cp\u003EThis content supports the important aspects of being a manager. This collection is an assortment of quality content reinforcing aspects valuable to being a manager.\u003C/p\u003E",
            "course_url": "https://cues.novoed.com/#!/courses/nnk7x/home"
          }
        },
        {
          "course_id": "30327",
          "item_id": "30327",
          "title": "Teamwork",
          "url": "/node/27331/edit",
          "course_catalog_id": "e86n5",
          "learning_journeys_arr": [
            "Leading Others",
            "Leading Teams"
          ],
          "learning_journeys": "Leading Others|Leading Teams",
          "personas_arr": [
            "Executive",
            "Manager"
          ],
          "personas": "Executive|Manager",
          "content_classification": "Executive|Manager",
          "topics_arr": [
            "Leadership"
          ],
          "topics": "Leadership",
          "genres_arr": [
            "Leadership"
          ],
          "genres": "Leadership",
          "series": "General",
          "tiers_arr": [
            "tier_1",
            "tier_2"
          ],
          "tiers": "tier_1|tier_2",
          "tiers_access_levels": [
            "Unlimited & Unlimited+ Members",
            "Individual Members"
          ],
          "content_role_arr": [
            "tier_1",
            "tier_2"
          ],
          "content_role": "tier_1|tier_2",
          "status": "active",
          "drupal_id": "27331",
          "content_type": "course",
          "author": null,
          "updated_at": "2024-03-07 09:21:02",
          "creation_timestamp": "2024-03-07 09:19:30",
          "recommendation_id": "RID-1f-40c9-a26c-dfa978d7e683-CID-ba8cb2",
          "isAdminEnrolled": false,
          "course_record": {
            "name": "Teamwork",
            "type": "Course",
            "catalog_id": "e86n5",
            "release_date": "2023-11-01T04:01:00Z",
            "close_date": null,
            "class_summary": "\u003Cp\u003ERegardless of whether you are a leader or a member of a team, you can impact team trust, morale, collaboration, conflict, and effectiveness. This course shares the value of teamwork and how to work in a proactive and collaborative way with others so you can be counted on when needed.\u003C/p\u003E",
            "course_url": "https://cues.novoed.com/#!/courses/e86n5/home"
          }
        },
        {
          "course_id": "30315",
          "item_id": "30315",
          "title": "Making Decisions",
          "url": "/node/27386/edit",
          "course_catalog_id": "nyna2",
          "learning_journeys_arr": [
            "Critical Thinking & Decision Making",
            "Leading Self"
          ],
          "learning_journeys": "Critical Thinking & Decision Making|Leading Self",
          "personas_arr": [
            "CEO",
            "Executive",
            "Manager",
            "Staff"
          ],
          "personas": "CEO|Executive|Manager|Staff",
          "content_classification": "CEO|Executive|Manager|Staff",
          "topics_arr": [
            "Leadership"
          ],
          "topics": "Leadership",
          "genres_arr": [
            "Leadership"
          ],
          "genres": "Leadership",
          "series": "General",
          "tiers_arr": [
            "tier_1",
            "tier_2"
          ],
          "tiers": "tier_1|tier_2",
          "tiers_access_levels": [
            "Unlimited & Unlimited+ Members",
            "Individual Members"
          ],
          "content_role_arr": [
            "tier_1",
            "tier_2"
          ],
          "content_role": "tier_1|tier_2",
          "status": "active",
          "drupal_id": "27386",
          "content_type": "course",
          "author": null,
          "updated_at": "2024-03-07 10:49:06",
          "creation_timestamp": "2024-03-07 10:47:53",
          "recommendation_id": "RID-1f-40c9-a26c-dfa978d7e683-CID-ba8cb2",
          "isAdminEnrolled": false,
          "course_record": {
            "name": "Making Decisions",
            "type": "Course",
            "catalog_id": "nyna2",
            "release_date": "2023-11-01T04:01:00Z",
            "close_date": null,
            "class_summary": "\u003Cp\u003EDecision-making is the act of choosing between two or more courses of action. Do you know what your decision-making style is? Have you thought about how many times in a day you make decisions? Some decisions need to be made quickly while others need time, data, and experts to bring about the final decision.\u003C/p\u003E\u003Cp\u003E This course covers: \u003Cbr\u003E • Why decision-making matters\u003Cbr\u003E • Effective decision making \u003Cbr\u003E • Decision making tips and techniques.\u003C/p\u003E",
            "course_url": "https://cues.novoed.com/#!/courses/nyna2/home"
          }
        },
        {
          "course_id": "30323",
          "item_id": "30323",
          "title": "Develop a Leader's Mindset",
          "url": "/node/27501/edit",
          "course_catalog_id": "68le3",
          "learning_journeys_arr": [
            "Leadership Foundations",
            "Leading Others"
          ],
          "learning_journeys": "Leadership Foundations|Leading Others",
          "personas_arr": [
            "Manager"
          ],
          "personas": "Manager",
          "content_classification": "Manager",
          "topics_arr": [
            "Leadership"
          ],
          "topics": "Leadership",
          "genres_arr": [
            "Leadership"
          ],
          "genres": "Leadership",
          "series": "General",
          "tiers_arr": [
            "tier_1",
            "tier_2"
          ],
          "tiers": "tier_1|tier_2",
          "tiers_access_levels": [
            "Unlimited & Unlimited+ Members",
            "Individual Members"
          ],
          "content_role_arr": [
            "tier_1",
            "tier_2"
          ],
          "content_role": "tier_1|tier_2",
          "status": "active",
          "drupal_id": "27501",
          "content_type": "course",
          "author": null,
          "updated_at": "2024-03-07 11:56:24",
          "creation_timestamp": "2024-03-07 11:55:06",
          "recommendation_id": "RID-1f-40c9-a26c-dfa978d7e683-CID-ba8cb2",
          "isAdminEnrolled": true,
          "course_record": {
            "name": "Develop a Leader's Mindset",
            "type": "Course",
            "catalog_id": "68le3",
            "release_date": "2023-11-01T04:01:00Z",
            "close_date": null,
            "class_summary": "\u003Cp\u003EMoving into your first leadership position is a big shift. If you are promoted from within, you must work to redefine your responsibilities and relationships. This role is not always going to be easy but set yourself up for success by shifting your mindset and identifying ways you can continue your development.\u003C/p\u003E",
            "course_url": "https://cues.novoed.com/#!/courses/68le3/home"
          }
        },
        {
          "course_id": "29892",
          "item_id": "29892",
          "title": "Developing Your Coaching Skills",
          "url": "/node/27491/edit",
          "course_catalog_id": "z0qnb",
          "learning_journeys_arr": [
            "Coaching and Mentoring",
            "Leading Others"
          ],
          "learning_journeys": "Coaching and Mentoring|Leading Others",
          "personas_arr": [
            "Manager"
          ],
          "personas": "Manager",
          "content_classification": "Manager",
          "topics_arr": [
            "Human Resources"
          ],
          "topics": "Human Resources",
          "genres_arr": [
            "Human Resources"
          ],
          "genres": "Human Resources",
          "series": "General",
          "tiers_arr": [
            "tier_1",
            "tier_2"
          ],
          "tiers": "tier_1|tier_2",
          "tiers_access_levels": [
            "Unlimited & Unlimited+ Members",
            "Individual Members"
          ],
          "content_role_arr": [
            "tier_1",
            "tier_2"
          ],
          "content_role": "tier_1|tier_2",
          "status": "active",
          "drupal_id": "27491",
          "content_type": "course",
          "author": null,
          "updated_at": "2024-03-07 11:53:06",
          "creation_timestamp": "2024-03-07 11:51:58",
          "recommendation_id": "RID-1f-40c9-a26c-dfa978d7e683-CID-ba8cb2",
          "isAdminEnrolled": false,
          "course_record": {
            "name": "Developing Your Coaching Skills",
            "type": "Course",
            "catalog_id": "z0qnb",
            "release_date": "2023-11-01T04:01:00Z",
            "close_date": null,
            "class_summary": "\u003Cp\u003ESuccessful managers know the importance of helping their employees improve their performance and develop new skills. Coaching is one of the most effective ways to accelerate employees' professional growth.\u003C/p\u003E\u003Cp\u003E When you coach, your role includes clarifying an employee's opportunity for development, collaboratively developing a plan of action, and providing support and resources to help employees accomplish their goals.\u003Cbr\u003E In this course, you will learn how to:\u003C/p\u003E\u003Cp\u003E• Plan for and conduct a formal coaching session\u003Cbr\u003E • Apply coaching skills such as listening and asking questions\u003Cbr\u003E • Monitor the progress of a coaching process\u003Cbr\u003E • Use coaching skills when opportunities arise\u003C/p\u003E",
            "course_url": "https://cues.novoed.com/#!/courses/z0qnb/home"
          }
        },
        {
          "course_id": "30672",
          "item_id": "30672",
          "title": "Becoming an Agile Learner",
          "url": "/node/27306/edit",
          "course_catalog_id": "lpowj",
          "learning_journeys_arr": [
            "Leading Self",
            "Self-Development"
          ],
          "learning_journeys": "Leading Self|Self-Development",
          "personas_arr": [
            "Manager",
            "Staff"
          ],
          "personas": "Manager|Staff",
          "content_classification": "Manager|Staff",
          "topics_arr": [
            "Leadership"
          ],
          "topics": "Leadership",
          "genres_arr": [
            "Leadership"
          ],
          "genres": "Leadership",
          "series": "General",
          "tiers_arr": [
            "tier_1",
            "tier_2"
          ],
          "tiers": "tier_1|tier_2",
          "tiers_access_levels": [
            "Unlimited & Unlimited+ Members",
            "Individual Members"
          ],
          "content_role_arr": [
            "tier_1",
            "tier_2"
          ],
          "content_role": "tier_1|tier_2",
          "status": "active",
          "drupal_id": "27306",
          "content_type": "course",
          "author": null,
          "updated_at": "2024-03-07 09:09:46",
          "creation_timestamp": "2024-03-07 09:08:16",
          "recommendation_id": "RID-1f-40c9-a26c-dfa978d7e683-CID-ba8cb2",
          "isAdminEnrolled": false,
          "course_record": {
            "name": "Becoming an Agile Learner",
            "type": "Course",
            "catalog_id": "lpowj",
            "release_date": "2023-11-01T04:01:00Z",
            "close_date": null,
            "class_summary": "\u003Cp\u003ELearning agility is a crucial skill in today's challenging business environments. We need to be active learners who experiment and take risks to solve new problems, leveraging what we learn from both successes and failures.\u003C/p\u003E\u003Cp\u003E This course will help individuals:\u003Cbr\u003E • Understand why learning agility is so important to their professional development and their organization's success\u003Cbr\u003E • Discover ways to cultivate their learning agility.\u003C/p\u003E",
            "course_url": "https://cues.novoed.com/#!/courses/lpowj/home"
          }
        },
        {
          "course_id": "29535",
          "item_id": "29535",
          "title": "Business and Stakeholder Relationships",
          "url": "/node/27276/edit",
          "course_catalog_id": "qe3rw",
          "learning_journeys_arr": [
            "Leading the Business",
            "Relationship and Networking"
          ],
          "learning_journeys": "Leading the Business|Relationship and Networking",
          "personas_arr": [
            "Staff"
          ],
          "personas": "Staff",
          "content_classification": "Staff",
          "topics_arr": [
            "Growth"
          ],
          "topics": "Growth",
          "genres_arr": [
            "Growth"
          ],
          "genres": "Growth",
          "series": "General",
          "tiers_arr": [
            "tier_1",
            "tier_2"
          ],
          "tiers": "tier_1|tier_2",
          "tiers_access_levels": [
            "Unlimited & Unlimited+ Members",
            "Individual Members"
          ],
          "content_role_arr": [
            "tier_1",
            "tier_2"
          ],
          "content_role": "tier_1|tier_2",
          "status": "active",
          "drupal_id": "27276",
          "content_type": "course",
          "author": null,
          "updated_at": "2024-03-06 15:41:46",
          "creation_timestamp": "2024-03-06 14:50:59",
          "recommendation_id": "RID-1f-40c9-a26c-dfa978d7e683-CID-ba8cb2",
          "isAdminEnrolled": false,
          "course_record": {
            "name": "Business and Stakeholder Relationships",
            "type": "Course",
            "catalog_id": "qe3rw",
            "release_date": "2023-11-01T04:01:00Z",
            "close_date": null,
            "class_summary": "\u003Cp\u003EA stakeholder has an interest in a company, its business operations, and performance. Stakeholders can be people, other businesses, the community, or governments. In this course you will explore the importance of building relationships, the value of your network, and how harmonious relationships produce effect results for your organization and its members. \u003C/p\u003E",
            "course_url": "https://cues.novoed.com/#!/courses/qe3rw/home"
          }
        },
        {
          "course_id": "30288",
          "item_id": "30288",
          "title": "Communication - Listening",
          "url": "/node/27606/edit",
          "course_catalog_id": "bvj07",
          "learning_journeys_arr": [
            "Communication",
            "Leading Self"
          ],
          "learning_journeys": "Communication|Leading Self",
          "personas_arr": [
            "CEO",
            "Executive",
            "Manager",
            "Staff",
            "Board"
          ],
          "personas": "CEO|Executive|Manager|Staff|Board",
          "content_classification": "CEO|Executive|Manager|Staff|Board",
          "topics_arr": [
            "Leadership"
          ],
          "topics": "Leadership",
          "genres_arr": [
            "Leadership"
          ],
          "genres": "Leadership",
          "series": "General",
          "tiers_arr": [
            "tier_1",
            "tier_2"
          ],
          "tiers": "tier_1|tier_2",
          "tiers_access_levels": [
            "Unlimited & Unlimited+ Members",
            "Individual Members"
          ],
          "content_role_arr": [
            "tier_1",
            "tier_2"
          ],
          "content_role": "tier_1|tier_2",
          "status": "active",
          "drupal_id": "27606",
          "content_type": "course",
          "author": null,
          "updated_at": "2024-05-20 14:37:39",
          "creation_timestamp": "2024-03-07 13:10:45",
          "recommendation_id": "RID-1f-40c9-a26c-dfa978d7e683-CID-ba8cb2",
          "isAdminEnrolled": false,
          "course_record": {
            "name": "Communication - Listening",
            "type": "Course",
            "catalog_id": "bvj07",
            "release_date": "2023-11-01T04:01:00Z",
            "close_date": null,
            "class_summary": "\u003Cp\u003EIn this course, you will learn more about listening which is one of the most essential qualities required for effective communication.\u003Cbr\u003E\u003C/p\u003E",
            "course_url": "https://cues.novoed.com/#!/courses/bvj07/home"
          }
        },
        {
          "course_id": "30319",
          "item_id": "30319",
          "title": "Receiving Feedback",
          "url": "/node/27356/edit",
          "course_catalog_id": "72b7q",
          "learning_journeys_arr": [
            "Feedback",
            "Leading Self"
          ],
          "learning_journeys": "Feedback|Leading Self",
          "personas_arr": [
            "Manager",
            "Staff"
          ],
          "personas": "Manager|Staff",
          "content_classification": "Manager|Staff",
          "topics_arr": [
            "Growth",
            "Human Resources"
          ],
          "topics": "Growth|Human Resources",
          "genres_arr": [
            "Growth",
            "Human Resources"
          ],
          "genres": "Growth|Human Resources",
          "series": "General",
          "tiers_arr": [
            "tier_1",
            "tier_2"
          ],
          "tiers": "tier_1|tier_2",
          "tiers_access_levels": [
            "Unlimited & Unlimited+ Members",
            "Individual Members"
          ],
          "content_role_arr": [
            "tier_1",
            "tier_2"
          ],
          "content_role": "tier_1|tier_2",
          "status": "active",
          "drupal_id": "27356",
          "content_type": "course",
          "author": null,
          "updated_at": "2024-03-07 10:37:46",
          "creation_timestamp": "2024-03-07 10:36:03",
          "recommendation_id": "RID-1f-40c9-a26c-dfa978d7e683-CID-ba8cb2",
          "isAdminEnrolled": false,
          "course_record": {
            "name": "Receiving Feedback",
            "type": "Course",
            "catalog_id": "72b7q",
            "release_date": "2023-11-01T04:01:00Z",
            "close_date": null,
            "class_summary": "\u003Cp\u003EThis course reviews the benefits of feedback, how to receive feedback, and how to seek out feedback from others.\u003C/p\u003E",
            "course_url": "https://cues.novoed.com/#!/courses/72b7q/home"
          }
        },
        {
          "course_id": "31263",
          "item_id": "31263",
          "title": "Difficult Interactions - Harvard ManageMentor",
          "url": "/node/27826/edit",
          "course_catalog_id": "hmm-difficult-interactions",
          "learning_journeys_arr": [
            "Harvard ManageMentor Learning Journey"
          ],
          "learning_journeys": "Harvard ManageMentor Learning Journey",
          "personas_arr": [
            "CEO",
            "Executive",
            "Manager",
            "Staff",
            "Board"
          ],
          "personas": "CEO|Executive|Manager|Staff|Board",
          "content_classification": "CEO|Executive|Manager|Staff|Board",
          "topics_arr": [
            "Governance",
            "Leadership"
          ],
          "topics": "Governance|Leadership",
          "genres_arr": [
            "Governance",
            "Leadership"
          ],
          "genres": "Governance|Leadership",
          "series": "HMM",
          "tiers_arr": [
            "tier_1"
          ],
          "tiers": "tier_1",
          "tiers_access_levels": [
            "Unlimited & Unlimited+ Members"
          ],
          "content_role_arr": [
            "tier_1"
          ],
          "content_role": "tier_1",
          "status": "active",
          "drupal_id": "27826",
          "content_type": "course",
          "author": null,
          "updated_at": "2024-03-21 14:49:34",
          "creation_timestamp": "2024-03-08 08:52:17",
          "recommendation_id": "RID-1f-40c9-a26c-dfa978d7e683-CID-ba8cb2",
          "isAdminEnrolled": false,
          "course_record": {
            "name": "Difficult Interactions - Harvard ManageMentor",
            "type": "Course",
            "catalog_id": "hmm-difficult-interactions",
            "release_date": "2023-11-01T04:01:00Z",
            "close_date": null,
            "class_summary": "Conflict is an inherent aspect of communication, making it challenging to navigate but ultimately inevitable. Learn valuable techniques to effectively handle disagreements and coach your team members to do the same.",
            "course_url": "https://cues.novoed.com/#!/courses/hmm-difficult-interactions/home"
          }
        },
        {
          "course_id": "30457",
          "item_id": "30457",
          "title": "Building a Member Experience Focus",
          "url": "/node/27651/edit",
          "course_catalog_id": "bvjk7",
          "learning_journeys_arr": [
            "Leading the Business",
            "Member Focus"
          ],
          "learning_journeys": "Leading the Business|Member Focus",
          "personas_arr": [
            "Executive",
            "Manager",
            "Staff"
          ],
          "personas": "Executive|Manager|Staff",
          "content_classification": "Executive|Manager|Staff",
          "topics_arr": [
            "Growth",
            "Operations"
          ],
          "topics": "Growth|Operations",
          "genres_arr": [
            "Growth",
            "Operations"
          ],
          "genres": "Growth|Operations",
          "series": "General",
          "tiers_arr": [
            "tier_1",
            "tier_2"
          ],
          "tiers": "tier_1|tier_2",
          "tiers_access_levels": [
            "Unlimited & Unlimited+ Members",
            "Individual Members"
          ],
          "content_role_arr": [
            "tier_1",
            "tier_2"
          ],
          "content_role": "tier_1|tier_2",
          "status": "active",
          "drupal_id": "27651",
          "content_type": "course",
          "author": null,
          "updated_at": "2024-05-20 14:32:22",
          "creation_timestamp": "2024-03-07 13:24:13",
          "recommendation_id": "RID-1f-40c9-a26c-dfa978d7e683-CID-ba8cb2",
          "isAdminEnrolled": false,
          "course_record": {
            "name": "Building a Member Experience Focus",
            "type": "Course",
            "catalog_id": "bvjk7",
            "release_date": "2023-11-01T04:01:00Z",
            "close_date": null,
            "class_summary": "\u003Cp\u003EAll things begin with the member. Putting the focus on building a member experience focus both as an individual and within an organization will help to drive business results and success. This course includes examples from businesses that have succeeded in building a member experience focus.\u003C/p\u003E",
            "course_url": "https://cues.novoed.com/#!/courses/bvjk7/home"
          }
        },
        {
          "course_id": "31226",
          "item_id": "31226",
          "title": "Attracting and Cultivating Talent - Harvard ManageMentor",
          "url": "/node/27681/edit",
          "course_catalog_id": "hmm-attract-talent",
          "learning_journeys_arr": [
            "Harvard ManageMentor Learning Journey"
          ],
          "learning_journeys": "Harvard ManageMentor Learning Journey",
          "personas_arr": [
            "CEO",
            "Executive",
            "Manager"
          ],
          "personas": "CEO|Executive|Manager",
          "content_classification": "CEO|Executive|Manager",
          "topics_arr": [
            "Human Resources"
          ],
          "topics": "Human Resources",
          "genres_arr": [
            "Human Resources"
          ],
          "genres": "Human Resources",
          "series": "HMM",
          "tiers_arr": [
            "tier_1"
          ],
          "tiers": "tier_1",
          "tiers_access_levels": [
            "Unlimited & Unlimited+ Members"
          ],
          "content_role_arr": [
            "tier_1"
          ],
          "content_role": "tier_1",
          "status": "active",
          "drupal_id": "27681",
          "content_type": "course",
          "author": null,
          "updated_at": "2024-03-21 14:41:08",
          "creation_timestamp": "2024-03-08 08:22:29",
          "recommendation_id": "RID-1f-40c9-a26c-dfa978d7e683-CID-ba8cb2",
          "isAdminEnrolled": false,
          "course_record": {
            "name": "Attracting and Cultivating Talent - Harvard ManageMentor",
            "type": "Course",
            "catalog_id": "hmm-attract-talent",
            "release_date": "2023-11-01T04:01:00Z",
            "close_date": null,
            "class_summary": "\u003Cp\u003EAs a leader, attracting and cultivating talent is vital for the long-term success of our team and organization. By creating an enticing work environment and showcasing opportunities for growth, we can appeal to skilled individuals who align with our vision and values. Learn how to recruit and develop the right individuals that will contribute to the success of the organization.\u003C/p\u003E",
            "course_url": "https://cues.novoed.com/#!/courses/hmm-attract-talent/home"
          }
        },
        {
          "course_id": "31291",
          "item_id": "31291",
          "title": "Writing Skills - Harvard ManageMentor",
          "url": "/node/27706/edit",
          "course_catalog_id": "hmm-writing-skills",
          "learning_journeys_arr": [
            "Harvard ManageMentor Learning Journey"
          ],
          "learning_journeys": "Harvard ManageMentor Learning Journey",
          "personas_arr": [
            "CEO",
            "Executive",
            "Manager",
            "Staff",
            "Board"
          ],
          "personas": "CEO|Executive|Manager|Staff|Board",
          "content_classification": "CEO|Executive|Manager|Staff|Board",
          "topics_arr": [
            "Governance",
            "Human Resources",
            "Leadership"
          ],
          "topics": "Governance|Human Resources|Leadership",
          "genres_arr": [
            "Governance",
            "Human Resources",
            "Leadership"
          ],
          "genres": "Governance|Human Resources|Leadership",
          "series": "HMM",
          "tiers_arr": [
            "tier_1"
          ],
          "tiers": "tier_1",
          "tiers_access_levels": [
            "Unlimited & Unlimited+ Members"
          ],
          "content_role_arr": [
            "tier_1"
          ],
          "content_role": "tier_1",
          "status": "active",
          "drupal_id": "27706",
          "content_type": "course",
          "author": null,
          "updated_at": "2024-03-21 15:22:55",
          "creation_timestamp": "2024-03-08 08:26:42",
          "recommendation_id": "RID-1f-40c9-a26c-dfa978d7e683-CID-ba8cb2",
          "isAdminEnrolled": false,
          "course_record": {
            "name": "Writing Skills - Harvard ManageMentor",
            "type": "Course",
            "catalog_id": "hmm-writing-skills",
            "release_date": "2023-11-01T04:01:00Z",
            "close_date": null,
            "class_summary": "\u003Cp\u003EEffective communication hinges on strong writing skills. Refine and sharpen your writing abilities to convey your thoughts persuasively, forging meaningful connections that propel your aspirations forward.\u003C/p\u003E",
            "course_url": "https://cues.novoed.com/#!/courses/hmm-writing-skills/home"
          }
        },
        {
          "course_id": "31289",
          "item_id": "31289",
          "title": "Team Management - Harvard ManageMentor",
          "url": "/node/27711/edit",
          "course_catalog_id": "hmm-team-mgmt",
          "learning_journeys_arr": [
            "Harvard ManageMentor Learning Journey"
          ],
          "learning_journeys": "Harvard ManageMentor Learning Journey",
          "personas_arr": [
            "CEO",
            "Executive",
            "Manager"
          ],
          "personas": "CEO|Executive|Manager",
          "content_classification": "CEO|Executive|Manager",
          "topics_arr": [
            "Human Resources",
            "Leadership"
          ],
          "topics": "Human Resources|Leadership",
          "genres_arr": [
            "Human Resources",
            "Leadership"
          ],
          "genres": "Human Resources|Leadership",
          "series": "HMM",
          "tiers_arr": [
            "tier_1"
          ],
          "tiers": "tier_1",
          "tiers_access_levels": [
            "Unlimited & Unlimited+ Members"
          ],
          "content_role_arr": [
            "tier_1"
          ],
          "content_role": "tier_1",
          "status": "active",
          "drupal_id": "27711",
          "content_type": "course",
          "author": null,
          "updated_at": "2024-04-18 11:32:00",
          "creation_timestamp": "2024-03-08 08:28:37",
          "recommendation_id": "RID-1f-40c9-a26c-dfa978d7e683-CID-ba8cb2",
          "isAdminEnrolled": false,
          "course_record": {
            "name": "Team Management - Harvard ManageMentor",
            "type": "Course",
            "catalog_id": "hmm-team-mgmt",
            "release_date": "2023-11-01T04:01:00Z",
            "close_date": null,
            "class_summary": "\u003Cp\u003EAt the heart of organizations lie teams - cohesive groups of individuals with shared identity and a capacity for effective collaboration. Cultivate a culture of trust and open communication, fostering an environment where productivity soars and collective achievements reach unprecedented heights.\u003C/p\u003E",
            "course_url": "https://cues.novoed.com/#!/courses/hmm-team-mgmt/home"
          }
        },
        {
          "course_id": "31264",
          "item_id": "31264",
          "title": "Digital Intelligence - Harvard ManageMentor",
          "url": "/node/27821/edit",
          "course_catalog_id": "hmm-digital-intelligence",
          "learning_journeys_arr": [
            "Harvard ManageMentor Learning Journey"
          ],
          "learning_journeys": "Harvard ManageMentor Learning Journey",
          "personas_arr": [
            "CEO",
            "Executive",
            "Manager",
            "Staff",
            "Board"
          ],
          "personas": "CEO|Executive|Manager|Staff|Board",
          "content_classification": "CEO|Executive|Manager|Staff|Board",
          "topics_arr": [
            "Marketing",
            "Operations"
          ],
          "topics": "Marketing|Operations",
          "genres_arr": [
            "Marketing",
            "Operations"
          ],
          "genres": "Marketing|Operations",
          "series": "HMM",
          "tiers_arr": [
            "tier_1"
          ],
          "tiers": "tier_1",
          "tiers_access_levels": [
            "Unlimited & Unlimited+ Members"
          ],
          "content_role_arr": [
            "tier_1"
          ],
          "content_role": "tier_1",
          "status": "active",
          "drupal_id": "27821",
          "content_type": "course",
          "author": null,
          "updated_at": "2024-03-21 14:50:03",
          "creation_timestamp": "2024-03-08 08:52:16",
          "recommendation_id": "RID-1f-40c9-a26c-dfa978d7e683-CID-ba8cb2",
          "isAdminEnrolled": false,
          "course_record": {
            "name": "Digital Intelligence - Harvard ManageMentor",
            "type": "Course",
            "catalog_id": "hmm-digital-intelligence",
            "release_date": "2023-11-01T04:01:00Z",
            "close_date": null,
            "class_summary": "In today's ever-evolving landscape, technology continuously reshapes the way we work, regardless of our roles. With a vast array of digital tools and data at our disposal, staying up to date can be overwhelming. Explore the essential capabilities both you and your team require to thrive in this technology-driven world.",
            "course_url": "https://cues.novoed.com/#!/courses/hmm-digital-intelligence/home"
          }
        },
        {
          "course_id": "30324",
          "item_id": "30324",
          "title": "Compassion: A Driver for Leadership Success",
          "url": "/node/27601/edit",
          "course_catalog_id": "72eor",
          "learning_journeys_arr": [
            "Leadership Foundations",
            "Leading Others"
          ],
          "learning_journeys": "Leadership Foundations|Leading Others",
          "personas_arr": [
            "CEO",
            "Executive",
            "Manager",
            "Staff"
          ],
          "personas": "CEO|Executive|Manager|Staff",
          "content_classification": "CEO|Executive|Manager|Staff",
          "topics_arr": [
            "Leadership"
          ],
          "topics": "Leadership",
          "genres_arr": [
            "Leadership"
          ],
          "genres": "Leadership",
          "series": "General",
          "tiers_arr": [
            "tier_1",
            "tier_2"
          ],
          "tiers": "tier_1|tier_2",
          "tiers_access_levels": [
            "Unlimited & Unlimited+ Members",
            "Individual Members"
          ],
          "content_role_arr": [
            "tier_1",
            "tier_2"
          ],
          "content_role": "tier_1|tier_2",
          "status": "active",
          "drupal_id": "27601",
          "content_type": "course",
          "author": null,
          "updated_at": "2024-05-20 14:38:08",
          "creation_timestamp": "2024-03-07 13:09:34",
          "recommendation_id": "RID-1f-40c9-a26c-dfa978d7e683-CID-ba8cb2",
          "isAdminEnrolled": true,
          "course_record": {
            "name": "Compassion: A Driver for Leadership Success",
            "type": "Course",
            "catalog_id": "72eor",
            "release_date": "2023-11-01T04:01:00Z",
            "close_date": null,
            "class_summary": "\u003Cp\u003EWhether you are working with staff or members, having compassion is key as you act in a caring and inclusive way toward all, regardless of individual differences. This course provides insight on how to use compassion to enhance customer experience and successfully lead others.\u003C/p\u003E",
            "course_url": "https://cues.novoed.com/#!/courses/72eor/home"
          }
        },
        {
          "course_id": "30311",
          "item_id": "30311",
          "title": "Business Communication",
          "url": "/node/27641/edit",
          "course_catalog_id": "0r1g6",
          "learning_journeys_arr": [
            "Communication",
            "Leading Others"
          ],
          "learning_journeys": "Communication|Leading Others",
          "personas_arr": [
            "Executive",
            "Manager",
            "Staff"
          ],
          "personas": "Executive|Manager|Staff",
          "content_classification": "Executive|Manager|Staff",
          "topics_arr": [
            "Leadership",
            "Operations"
          ],
          "topics": "Leadership|Operations",
          "genres_arr": [
            "Leadership",
            "Operations"
          ],
          "genres": "Leadership|Operations",
          "series": "General",
          "tiers_arr": [
            "tier_1",
            "tier_2"
          ],
          "tiers": "tier_1|tier_2",
          "tiers_access_levels": [
            "Unlimited & Unlimited+ Members",
            "Individual Members"
          ],
          "content_role_arr": [
            "tier_1",
            "tier_2"
          ],
          "content_role": "tier_1|tier_2",
          "status": "active",
          "drupal_id": "27641",
          "content_type": "course",
          "author": null,
          "updated_at": "2024-05-20 14:33:44",
          "creation_timestamp": "2024-03-07 13:21:50",
          "recommendation_id": "RID-1f-40c9-a26c-dfa978d7e683-CID-ba8cb2",
          "isAdminEnrolled": false,
          "course_record": {
            "name": "Business Communication",
            "type": "Course",
            "catalog_id": "0r1g6",
            "release_date": "2023-11-01T04:01:00Z",
            "close_date": null,
            "class_summary": "\u003Cp\u003EEffective business communication is essential for the success and growth of every organization. Unlike everyday communications, business communication is goal oriented, reduces errors, and increases productivity. Get a better understanding of effective business communication from this informative course.\u003C/p\u003E",
            "course_url": "https://cues.novoed.com/#!/courses/0r1g6/home"
          }
        },
        {
          "course_id": "30458",
          "item_id": "30458",
          "title": "Serving the Member: An Employee Perspective",
          "url": "/node/27336/edit",
          "course_catalog_id": "68qey",
          "learning_journeys_arr": [
            "Leading the Business",
            "Member Focus"
          ],
          "learning_journeys": "Leading the Business|Member Focus",
          "personas_arr": [
            "Manager",
            "Staff"
          ],
          "personas": "Manager|Staff",
          "content_classification": "Manager|Staff",
          "topics_arr": [
            "Growth",
            "Human Resources",
            "Leadership"
          ],
          "topics": "Growth|Human Resources|Leadership",
          "genres_arr": [
            "Growth",
            "Human Resources",
            "Leadership"
          ],
          "genres": "Growth|Human Resources|Leadership",
          "series": "General",
          "tiers_arr": [
            "tier_1",
            "tier_2"
          ],
          "tiers": "tier_1|tier_2",
          "tiers_access_levels": [
            "Unlimited & Unlimited+ Members",
            "Individual Members"
          ],
          "content_role_arr": [
            "tier_1",
            "tier_2"
          ],
          "content_role": "tier_1|tier_2",
          "status": "active",
          "drupal_id": "27336",
          "content_type": "course",
          "author": null,
          "updated_at": "2024-03-07 09:24:34",
          "creation_timestamp": "2024-03-07 09:21:11",
          "recommendation_id": "RID-1f-40c9-a26c-dfa978d7e683-CID-ba8cb2",
          "isAdminEnrolled": false,
          "course_record": {
            "name": "Serving the Member: An Employee Perspective",
            "type": "Course",
            "catalog_id": "68qey",
            "release_date": "2023-11-01T04:01:00Z",
            "close_date": null,
            "class_summary": "\u003Cp\u003EThis course explores different aspects of member experience such as knowing your member, listening, and responding to members and enhancing your member centricity.\u003C/p\u003E",
            "course_url": "https://cues.novoed.com/#!/courses/68qey/home"
          }
        },
        {
          "course_id": "30464",
          "item_id": "30464",
          "title": "Entrepreneurial Thinking",
          "url": "/node/27471/edit",
          "course_catalog_id": "v4gzq",
          "learning_journeys_arr": [
            "Leading the Business",
            "Operations, Strategy, & Regulatory"
          ],
          "learning_journeys": "Leading the Business|Operations, Strategy, & Regulatory",
          "personas_arr": [
            "CEO",
            "Executive"
          ],
          "personas": "CEO|Executive",
          "content_classification": "CEO|Executive",
          "topics_arr": [
            "Growth",
            "Innovation",
            "Leadership",
            "Operations",
            "Strategy"
          ],
          "topics": "Growth|Innovation|Leadership|Operations|Strategy",
          "genres_arr": [
            "Growth",
            "Innovation",
            "Leadership",
            "Operations",
            "Strategy"
          ],
          "genres": "Growth|Innovation|Leadership|Operations|Strategy",
          "series": "General",
          "tiers_arr": [
            "tier_1",
            "tier_2"
          ],
          "tiers": "tier_1|tier_2",
          "tiers_access_levels": [
            "Unlimited & Unlimited+ Members",
            "Individual Members"
          ],
          "content_role_arr": [
            "tier_1",
            "tier_2"
          ],
          "content_role": "tier_1|tier_2",
          "status": "active",
          "drupal_id": "27471",
          "content_type": "course",
          "author": null,
          "updated_at": "2024-03-07 11:41:09",
          "creation_timestamp": "2024-03-07 11:39:45",
          "recommendation_id": "RID-1f-40c9-a26c-dfa978d7e683-CID-ba8cb2",
          "isAdminEnrolled": false,
          "course_record": {
            "name": "Entrepreneurial Thinking",
            "type": "Course",
            "catalog_id": "v4gzq",
            "release_date": "2023-11-01T04:01:00Z",
            "close_date": null,
            "class_summary": "\u003Cp\u003ELearn how to think creatively and take ownership of your job and performance. Entrepreneurial thinking not only positions you for success but also the organization. This course focuses on how your way of thinking can impact success by:\u003C/p\u003E \u003Cul\u003E \u003Cli\u003EIdentifying new opportunities to position the organization for success.\u003C/li\u003E \u003Cli\u003ERecognize the need for change before it becomes critical.\u003C/li\u003E \u003Cli\u003EIdentify marketplace opportunities and discover the most appropriate ways and time to capitalize on them.\u003C/li\u003E\u003C/ul\u003E",
            "course_url": "https://cues.novoed.com/#!/courses/v4gzq/home"
          }
        },
        {
          "course_id": "31261",
          "item_id": "31261",
          "title": "Delegating - Harvard ManageMentor",
          "url": "/node/27836/edit",
          "course_catalog_id": "hmm-delegating",
          "learning_journeys_arr": [
            "Harvard ManageMentor Learning Journey"
          ],
          "learning_journeys": "Harvard ManageMentor Learning Journey",
          "personas_arr": [
            "CEO",
            "Executive",
            "Manager"
          ],
          "personas": "CEO|Executive|Manager",
          "content_classification": "CEO|Executive|Manager",
          "topics_arr": [
            "Leadership"
          ],
          "topics": "Leadership",
          "genres_arr": [
            "Leadership"
          ],
          "genres": "Leadership",
          "series": "HMM",
          "tiers_arr": [
            "tier_1"
          ],
          "tiers": "tier_1",
          "tiers_access_levels": [
            "Unlimited & Unlimited+ Members"
          ],
          "content_role_arr": [
            "tier_1"
          ],
          "content_role": "tier_1",
          "status": "active",
          "drupal_id": "27836",
          "content_type": "course",
          "author": null,
          "updated_at": "2024-03-21 14:48:25",
          "creation_timestamp": "2024-03-08 08:54:41",
          "recommendation_id": "RID-1f-40c9-a26c-dfa978d7e683-CID-ba8cb2",
          "isAdminEnrolled": false,
          "course_record": {
            "name": "Delegating - Harvard ManageMentor",
            "type": "Course",
            "catalog_id": "hmm-delegating",
            "release_date": "2023-11-01T04:01:00Z",
            "close_date": null,
            "class_summary": "\u003Cp\u003EWhile our initial instinct may lead us to tackle all tasks independently, it is not a practical approach. Delegation holds the key to both your personal success and that of your team. Learn how to discern tasks suitable for delegation and provide support for delegated work, enabling your team to thrive and achieve collective success.\u003C/p\u003E",
            "course_url": "https://cues.novoed.com/#!/courses/hmm-delegating/home"
          }
        },
        {
          "course_id": "30341",
          "item_id": "30341",
          "title": "Strategic Thinking - Harvard ManageMentor",
          "url": "/node/27686/edit",
          "course_catalog_id": "hmm-strategic-thinking",
          "learning_journeys_arr": [
            "Harvard ManageMentor Learning Journey"
          ],
          "learning_journeys": "Harvard ManageMentor Learning Journey",
          "personas_arr": [
            "CEO",
            "Executive",
            "Manager",
            "Staff"
          ],
          "personas": "CEO|Executive|Manager|Staff",
          "content_classification": "CEO|Executive|Manager|Staff",
          "topics_arr": [
            "Governance",
            "Strategy"
          ],
          "topics": "Governance|Strategy",
          "genres_arr": [
            "Governance",
            "Strategy"
          ],
          "genres": "Governance|Strategy",
          "series": "HMM",
          "tiers_arr": [
            "tier_1"
          ],
          "tiers": "tier_1",
          "tiers_access_levels": [
            "Unlimited & Unlimited+ Members"
          ],
          "content_role_arr": [
            "tier_1"
          ],
          "content_role": "tier_1",
          "status": "active",
          "drupal_id": "27686",
          "content_type": "course",
          "author": null,
          "updated_at": "2024-03-21 14:53:25",
          "creation_timestamp": "2024-03-08 08:23:49",
          "recommendation_id": "RID-1f-40c9-a26c-dfa978d7e683-CID-ba8cb2",
          "isAdminEnrolled": false,
          "course_record": {
            "name": "Strategic Thinking - Harvard ManageMentor",
            "type": "Course",
            "catalog_id": "hmm-strategic-thinking",
            "release_date": "2023-11-01T04:01:00Z",
            "close_date": null,
            "class_summary": null,
            "course_url": "https://cues.novoed.com/#!/courses/hmm-strategic-thinking/home"
          }
        },
        {
          "course_id": "31279",
          "item_id": "31279",
          "title": "Performance Appraisal - Harvard ManageMentor",
          "url": "/node/27761/edit",
          "course_catalog_id": "hmm-perf-appraisal",
          "learning_journeys_arr": [
            "Harvard ManageMentor Learning Journey"
          ],
          "learning_journeys": "Harvard ManageMentor Learning Journey",
          "personas_arr": [
            "CEO",
            "Executive",
            "Manager",
            "Staff"
          ],
          "personas": "CEO|Executive|Manager|Staff",
          "content_classification": "CEO|Executive|Manager|Staff",
          "topics_arr": [
            "Human Resources",
            "Leadership"
          ],
          "topics": "Human Resources|Leadership",
          "genres_arr": [
            "Human Resources",
            "Leadership"
          ],
          "genres": "Human Resources|Leadership",
          "series": "HMM",
          "tiers_arr": [
            "tier_1"
          ],
          "tiers": "tier_1",
          "tiers_access_levels": [
            "Unlimited & Unlimited+ Members"
          ],
          "content_role_arr": [
            "tier_1"
          ],
          "content_role": "tier_1",
          "status": "active",
          "drupal_id": "27761",
          "content_type": "course",
          "author": null,
          "updated_at": "2024-03-21 15:15:26",
          "creation_timestamp": "2024-03-08 08:37:53",
          "recommendation_id": "RID-1f-40c9-a26c-dfa978d7e683-CID-ba8cb2",
          "isAdminEnrolled": false,
          "course_record": {
            "name": "Performance Appraisal - Harvard ManageMentor",
            "type": "Course",
            "catalog_id": "hmm-perf-appraisal",
            "release_date": "2023-11-01T04:01:00Z",
            "close_date": null,
            "class_summary": "\u003Cp\u003EPerformance appraisal is a critical aspect of reinforcing positive behaviors and addressing areas that require improvement. Effective performance appraisals foster a culture of growth and continuous improvement. Learn how to identify opportunities for professional development and how to deliver effective feedback.\u003C/p\u003E",
            "course_url": "https://cues.novoed.com/#!/courses/hmm-perf-appraisal/home"
          }
        },
        {
          "course_id": "31285",
          "item_id": "31285",
          "title": "Sharpening Your Business Acumen - Harvard ManageMentor",
          "url": "/node/27726/edit",
          "course_catalog_id": "hmm-sharpen-bus-acumen",
          "learning_journeys_arr": [
            "Harvard ManageMentor Learning Journey"
          ],
          "learning_journeys": "Harvard ManageMentor Learning Journey",
          "personas_arr": [
            "Executive",
            "Manager",
            "Staff"
          ],
          "personas": "Executive|Manager|Staff",
          "content_classification": "Executive|Manager|Staff",
          "topics_arr": [
            "Human Resources",
            "Marketing",
            "Operations",
            "Strategy"
          ],
          "topics": "Human Resources|Marketing|Operations|Strategy",
          "genres_arr": [
            "Human Resources",
            "Marketing",
            "Operations",
            "Strategy"
          ],
          "genres": "Human Resources|Marketing|Operations|Strategy",
          "series": "HMM",
          "tiers_arr": [
            "tier_1"
          ],
          "tiers": "tier_1",
          "tiers_access_levels": [
            "Unlimited & Unlimited+ Members"
          ],
          "content_role_arr": [
            "tier_1"
          ],
          "content_role": "tier_1",
          "status": "active",
          "drupal_id": "27726",
          "content_type": "course",
          "author": null,
          "updated_at": "2024-03-21 15:19:30",
          "creation_timestamp": "2024-03-08 08:31:18",
          "recommendation_id": "RID-1f-40c9-a26c-dfa978d7e683-CID-ba8cb2",
          "isAdminEnrolled": false,
          "course_record": {
            "name": "Sharpening Your Business Acumen - Harvard ManageMentor",
            "type": "Course",
            "catalog_id": "hmm-sharpen-bus-acumen",
            "release_date": "2023-11-01T04:01:00Z",
            "close_date": null,
            "class_summary": "\u003Cp\u003EYou can help your organization thrive by focusing on developing your business acumen—the understanding of how organizations function, create value, and gain a competitive edge. Familiarize yourself with the tools to make informed choices, drive growth, and contribute significantly to the long-term success of your organization.\u003C/p\u003E",
            "course_url": "https://cues.novoed.com/#!/courses/hmm-sharpen-bus-acumen/home"
          }
        },
        {
          "course_id": "30674",
          "item_id": "30674",
          "title": "Becoming Action Oriented",
          "url": "/node/27301/edit",
          "course_catalog_id": "91egn",
          "learning_journeys_arr": [
            "Leading Self",
            "Self-Development"
          ],
          "learning_journeys": "Leading Self|Self-Development",
          "personas_arr": [
            "Manager",
            "Staff"
          ],
          "personas": "Manager|Staff",
          "content_classification": "Manager|Staff",
          "topics_arr": [],
          "topics": "",
          "genres_arr": [],
          "genres": "",
          "series": "General",
          "tiers_arr": [
            "tier_1",
            "tier_2"
          ],
          "tiers": "tier_1|tier_2",
          "tiers_access_levels": [
            "Unlimited & Unlimited+ Members",
            "Individual Members"
          ],
          "content_role_arr": [
            "tier_1",
            "tier_2"
          ],
          "content_role": "tier_1|tier_2",
          "status": "active",
          "drupal_id": "27301",
          "content_type": "course",
          "author": null,
          "updated_at": "2024-03-07 09:07:34",
          "creation_timestamp": "2024-03-07 08:56:34",
          "recommendation_id": "RID-1f-40c9-a26c-dfa978d7e683-CID-ba8cb2",
          "isAdminEnrolled": false,
          "course_record": {
            "name": "Becoming Action Oriented",
            "type": "Course",
            "catalog_id": "91egn",
            "release_date": "2023-11-01T04:01:00Z",
            "close_date": null,
            "class_summary": "\u003Cp\u003ELearning to be diligent and work hard to obtain positive results can move you to great heights in your career. This course will help you see how you can better demonstrate energy and drive in overcoming challenges, deal with failure and procrastination and seek opportunities for improvement.\u003C/p\u003E",
            "course_url": "https://cues.novoed.com/#!/courses/91egn/home"
          }
        },
        {
          "course_id": "30460",
          "item_id": "30460",
          "title": "Credit Union Growth Strategy",
          "url": "/node/27596/edit",
          "course_catalog_id": "kxbnq",
          "learning_journeys_arr": [
            "Leading the Business",
            "Operations, Strategy, & Regulatory"
          ],
          "learning_journeys": "Leading the Business|Operations, Strategy, & Regulatory",
          "personas_arr": [
            "CEO",
            "Executive",
            "Manager"
          ],
          "personas": "CEO|Executive|Manager",
          "content_classification": "CEO|Executive|Manager",
          "topics_arr": [
            "Growth",
            "Strategy"
          ],
          "topics": "Growth|Strategy",
          "genres_arr": [
            "Growth",
            "Strategy"
          ],
          "genres": "Growth|Strategy",
          "series": "General",
          "tiers_arr": [
            "tier_1",
            "tier_2"
          ],
          "tiers": "tier_1|tier_2",
          "tiers_access_levels": [
            "Unlimited & Unlimited+ Members",
            "Individual Members"
          ],
          "content_role_arr": [
            "tier_1",
            "tier_2"
          ],
          "content_role": "tier_1|tier_2",
          "status": "active",
          "drupal_id": "27596",
          "content_type": "course",
          "author": null,
          "updated_at": "2024-05-20 14:38:40",
          "creation_timestamp": "2024-03-07 13:07:51",
          "recommendation_id": "RID-1f-40c9-a26c-dfa978d7e683-CID-ba8cb2",
          "isAdminEnrolled": false,
          "course_record": {
            "name": "Credit Union Growth Strategy",
            "type": "Course",
            "catalog_id": "kxbnq",
            "release_date": "2023-11-01T04:01:00Z",
            "close_date": null,
            "class_summary": "\u003Cp\u003ECredit union growth encompasses a wide variety of fields. Lending, marketing, and collaborations can have a huge impact on your credit union. In this course we will explore several areas of growth and how you can improve their growth potential.\u003C/p\u003E",
            "course_url": "https://cues.novoed.com/#!/courses/kxbnq/home"
          }
        },
        {
          "course_id": "30313",
          "item_id": "30313",
          "title": "Public Speaking & Presentation Skills",
          "url": "/node/27361/edit",
          "course_catalog_id": "ryw7b",
          "learning_journeys_arr": [
            "Communication",
            "Leading Self"
          ],
          "learning_journeys": "Communication|Leading Self",
          "personas_arr": [
            "Manager",
            "Staff"
          ],
          "personas": "Manager|Staff",
          "content_classification": "Manager|Staff",
          "topics_arr": [
            "Growth",
            "Leadership"
          ],
          "topics": "Growth|Leadership",
          "genres_arr": [
            "Growth",
            "Leadership"
          ],
          "genres": "Growth|Leadership",
          "series": "General",
          "tiers_arr": [
            "tier_1",
            "tier_2"
          ],
          "tiers": "tier_1|tier_2",
          "tiers_access_levels": [
            "Unlimited & Unlimited+ Members",
            "Individual Members"
          ],
          "content_role_arr": [
            "tier_1",
            "tier_2"
          ],
          "content_role": "tier_1|tier_2",
          "status": "active",
          "drupal_id": "27361",
          "content_type": "course",
          "author": null,
          "updated_at": "2024-03-07 10:39:30",
          "creation_timestamp": "2024-03-07 10:38:15",
          "recommendation_id": "RID-1f-40c9-a26c-dfa978d7e683-CID-ba8cb2",
          "isAdminEnrolled": false,
          "course_record": {
            "name": "Public Speaking & Presentation Skills",
            "type": "Course",
            "catalog_id": "ryw7b",
            "release_date": "2023-11-01T04:01:00Z",
            "close_date": null,
            "class_summary": "\u003Cp\u003EDeveloping your public speaking skills can benefit you in small and large ways. Whether you are providing a project update to a team, presenting a new idea to leadership, or speaking at an event, you can develop your skills. This course will help you learn to analyze your audience, create powerful & memorable content, develop effective visual aids, and enhance your vocal and body language skills. In addition, you'll learn about presenting as a team, presenting internationally, and handling presentation anxiety.\u003C/p\u003E",
            "course_url": "https://cues.novoed.com/#!/courses/ryw7b/home"
          }
        },
        {
          "course_id": "31275",
          "item_id": "31275",
          "title": "Managing Your Boss - Harvard ManageMentor",
          "url": "/node/27776/edit",
          "course_catalog_id": "hmm-managing-your-boss",
          "learning_journeys_arr": [
            "Harvard ManageMentor Learning Journey"
          ],
          "learning_journeys": "Harvard ManageMentor Learning Journey",
          "personas_arr": [
            "CEO",
            "Executive",
            "Manager",
            "Staff"
          ],
          "personas": "CEO|Executive|Manager|Staff",
          "content_classification": "CEO|Executive|Manager|Staff",
          "topics_arr": [
            "Human Resources",
            "Leadership"
          ],
          "topics": "Human Resources|Leadership",
          "genres_arr": [
            "Human Resources",
            "Leadership"
          ],
          "genres": "Human Resources|Leadership",
          "series": "HMM",
          "tiers_arr": [
            "tier_1"
          ],
          "tiers": "tier_1",
          "tiers_access_levels": [
            "Unlimited & Unlimited+ Members"
          ],
          "content_role_arr": [
            "tier_1"
          ],
          "content_role": "tier_1",
          "status": "active",
          "drupal_id": "27776",
          "content_type": "course",
          "author": null,
          "updated_at": "2024-03-21 15:10:29",
          "creation_timestamp": "2024-03-08 08:41:16",
          "recommendation_id": "RID-1f-40c9-a26c-dfa978d7e683-CID-ba8cb2",
          "isAdminEnrolled": false,
          "course_record": {
            "name": "Managing Your Boss - Harvard ManageMentor",
            "type": "Course",
            "catalog_id": "hmm-managing-your-boss",
            "release_date": "2023-11-01T04:01:00Z",
            "close_date": null,
            "class_summary": "\u003Cp\u003EA strong manager-employee relationship thrives on open communication and mutual respect, paving the way for a fulfilling career journey. Explore effective methods to resolve differences and conflicts with your manager while maintaining focus on essential business priorities.\u003C/p\u003E",
            "course_url": "https://cues.novoed.com/#!/courses/hmm-managing-your-boss/home"
          }
        },
        {
          "course_id": "31267",
          "item_id": "31267",
          "title": "Feedback Essentials - Harvard ManageMentor",
          "url": "/node/27811/edit",
          "course_catalog_id": "hmm-feedback-essentials",
          "learning_journeys_arr": [
            "Harvard ManageMentor Learning Journey"
          ],
          "learning_journeys": "Harvard ManageMentor Learning Journey",
          "personas_arr": [
            "CEO",
            "Executive",
            "Manager",
            "Staff",
            "Board"
          ],
          "personas": "CEO|Executive|Manager|Staff|Board",
          "content_classification": "CEO|Executive|Manager|Staff|Board",
          "topics_arr": [
            "Governance",
            "Human Resources",
            "Leadership"
          ],
          "topics": "Governance|Human Resources|Leadership",
          "genres_arr": [
            "Governance",
            "Human Resources",
            "Leadership"
          ],
          "genres": "Governance|Human Resources|Leadership",
          "series": "HMM",
          "tiers_arr": [
            "tier_1"
          ],
          "tiers": "tier_1",
          "tiers_access_levels": [
            "Unlimited & Unlimited+ Members"
          ],
          "content_role_arr": [
            "tier_1"
          ],
          "content_role": "tier_1",
          "status": "active",
          "drupal_id": "27811",
          "content_type": "course",
          "author": null,
          "updated_at": "2024-03-21 14:55:26",
          "creation_timestamp": "2024-03-08 08:52:11",
          "recommendation_id": "RID-1f-40c9-a26c-dfa978d7e683-CID-ba8cb2",
          "isAdminEnrolled": false,
          "course_record": {
            "name": "Feedback Essentials - Harvard ManageMentor",
            "type": "Course",
            "catalog_id": "hmm-feedback-essentials",
            "release_date": "2023-11-01T04:01:00Z",
            "close_date": null,
            "class_summary": "\u003Cp\u003EFeedback is a catalyst for learning, promoting stronger relationships, and enhancing overall performance. By fostering a culture of open and constructive feedback, you create an environment where continuous growth and improvement thrive, enabling everyone to reach their full potential and achieve collective excellence.\u003C/p\u003E",
            "course_url": "https://cues.novoed.com/#!/courses/hmm-feedback-essentials/home"
          }
        },
        {
          "course_id": "31266",
          "item_id": "31266",
          "title": "Ethics at Work - Harvard ManageMentor",
          "url": "/node/27816/edit",
          "course_catalog_id": "hmm-ethics-at-work",
          "learning_journeys_arr": [
            "Harvard ManageMentor Learning Journey"
          ],
          "learning_journeys": "Harvard ManageMentor Learning Journey",
          "personas_arr": [
            "CEO",
            "Executive",
            "Manager",
            "Staff",
            "Board"
          ],
          "personas": "CEO|Executive|Manager|Staff|Board",
          "content_classification": "CEO|Executive|Manager|Staff|Board",
          "topics_arr": [
            "Governance",
            "Human Resources",
            "Leadership"
          ],
          "topics": "Governance|Human Resources|Leadership",
          "genres_arr": [
            "Governance",
            "Human Resources",
            "Leadership"
          ],
          "genres": "Governance|Human Resources|Leadership",
          "series": "HMM",
          "tiers_arr": [
            "tier_1"
          ],
          "tiers": "tier_1",
          "tiers_access_levels": [
            "Unlimited & Unlimited+ Members"
          ],
          "content_role_arr": [
            "tier_1"
          ],
          "content_role": "tier_1",
          "status": "active",
          "drupal_id": "27816",
          "content_type": "course",
          "author": null,
          "updated_at": "2024-03-21 14:54:58",
          "creation_timestamp": "2024-03-08 08:52:14",
          "recommendation_id": "RID-1f-40c9-a26c-dfa978d7e683-CID-ba8cb2",
          "isAdminEnrolled": false,
          "course_record": {
            "name": "Ethics at Work - Harvard ManageMentor",
            "type": "Course",
            "catalog_id": "hmm-ethics-at-work",
            "release_date": "2023-11-01T04:01:00Z",
            "close_date": null,
            "class_summary": "\u003Cp\u003EIntegrating ethics into decision-making throughout your team and organization is not only morally sound but also a sound business strategy. Delve deeper into ethics and learn effective ways to cultivate integrity in the workplace, while gaining valuable insights into making ethical decisions through practical methods.\u003C/p\u003E",
            "course_url": "https://cues.novoed.com/#!/courses/hmm-ethics-at-work/home"
          }
        },
        {
          "course_id": "31234",
          "item_id": "31234",
          "title": "Crisis Management - Harvard ManageMentor",
          "url": "/node/27851/edit",
          "course_catalog_id": "hmm-crisis-mgmt",
          "learning_journeys_arr": [
            "Harvard ManageMentor Learning Journey"
          ],
          "learning_journeys": "Harvard ManageMentor Learning Journey",
          "personas_arr": [
            "CEO",
            "Executive",
            "Manager",
            "Staff",
            "Board"
          ],
          "personas": "CEO|Executive|Manager|Staff|Board",
          "content_classification": "CEO|Executive|Manager|Staff|Board",
          "topics_arr": [
            "Governance",
            "Human Resources",
            "Leadership",
            "Marketing"
          ],
          "topics": "Governance|Human Resources|Leadership|Marketing",
          "genres_arr": [
            "Governance",
            "Human Resources",
            "Leadership",
            "Marketing"
          ],
          "genres": "Governance|Human Resources|Leadership|Marketing",
          "series": "HMM",
          "tiers_arr": [
            "tier_1"
          ],
          "tiers": "tier_1",
          "tiers_access_levels": [
            "Unlimited & Unlimited+ Members"
          ],
          "content_role_arr": [
            "tier_1"
          ],
          "content_role": "tier_1",
          "status": "active",
          "drupal_id": "27851",
          "content_type": "course",
          "author": null,
          "updated_at": "2024-03-21 14:46:04",
          "creation_timestamp": "2024-03-08 08:56:13",
          "recommendation_id": "RID-1f-40c9-a26c-dfa978d7e683-CID-ba8cb2",
          "isAdminEnrolled": false,
          "course_record": {
            "name": "Crisis Management - Harvard ManageMentor",
            "type": "Course",
            "catalog_id": "hmm-crisis-mgmt",
            "release_date": "2023-11-01T04:01:00Z",
            "close_date": null,
            "class_summary": "Navigating through crises is undeniably difficult, but amidst these challenging moments lie opportunities for pivotal turning points in our lives. Equip yourself with essential skills to navigate crises with empathy, compassion, and effective communication.",
            "course_url": "https://cues.novoed.com/#!/courses/hmm-crisis-mgmt/home"
          }
        },
        {
          "course_id": "31230",
          "item_id": "31230",
          "title": "Business Plan Development - Harvard ManageMentor",
          "url": "/node/27666/edit",
          "course_catalog_id": "hmm-bus-plan-dev",
          "learning_journeys_arr": [
            "Harvard ManageMentor Learning Journey"
          ],
          "learning_journeys": "Harvard ManageMentor Learning Journey",
          "personas_arr": [
            "CEO",
            "Executive",
            "Manager",
            "Staff"
          ],
          "personas": "CEO|Executive|Manager|Staff",
          "content_classification": "CEO|Executive|Manager|Staff",
          "topics_arr": [
            "Growth",
            "Strategy"
          ],
          "topics": "Growth|Strategy",
          "genres_arr": [
            "Growth",
            "Strategy"
          ],
          "genres": "Growth|Strategy",
          "series": "HMM",
          "tiers_arr": [
            "tier_1"
          ],
          "tiers": "tier_1",
          "tiers_access_levels": [
            "Unlimited & Unlimited+ Members"
          ],
          "content_role_arr": [
            "tier_1"
          ],
          "content_role": "tier_1",
          "status": "active",
          "drupal_id": "27666",
          "content_type": "course",
          "author": null,
          "updated_at": "2024-03-21 14:42:55",
          "creation_timestamp": "2024-03-08 08:19:21",
          "recommendation_id": "RID-1f-40c9-a26c-dfa978d7e683-CID-ba8cb2",
          "isAdminEnrolled": false,
          "course_record": {
            "name": "Business Plan Development - Harvard ManageMentor",
            "type": "Course",
            "catalog_id": "hmm-bus-plan-dev",
            "release_date": "2023-11-01T04:01:00Z",
            "close_date": null,
            "class_summary": "\u003Cp\u003ETransforming abstract organizational or team goals into tangible and achievable outcomes is the essence of a business plan. Learn the process of crafting a business plan that serves as a dynamic blueprint, guiding your team's actions and driving the success of your ventures in an ever-evolving business landscape.\u003C/p\u003E",
            "course_url": "https://cues.novoed.com/#!/courses/hmm-bus-plan-dev/home"
          }
        },
        {
          "course_id": "30339",
          "item_id": "30339",
          "title": "Goal Setting - Harvard ManageMentor",
          "url": "/node/27696/edit",
          "course_catalog_id": "hmm-goal-setting",
          "learning_journeys_arr": [
            "Harvard ManageMentor Learning Journey"
          ],
          "learning_journeys": "Harvard ManageMentor Learning Journey",
          "personas_arr": [
            "CEO",
            "Executive",
            "Manager",
            "Staff",
            "Board"
          ],
          "personas": "CEO|Executive|Manager|Staff|Board",
          "content_classification": "CEO|Executive|Manager|Staff|Board",
          "topics_arr": [
            "Governance",
            "Growth",
            "Human Resources",
            "Marketing",
            "Operations",
            "Strategy"
          ],
          "topics": "Governance|Growth|Human Resources|Marketing|Operations|Strategy",
          "genres_arr": [
            "Governance",
            "Growth",
            "Human Resources",
            "Marketing",
            "Operations",
            "Strategy"
          ],
          "genres": "Governance|Growth|Human Resources|Marketing|Operations|Strategy",
          "series": "HMM",
          "tiers_arr": [
            "tier_1"
          ],
          "tiers": "tier_1",
          "tiers_access_levels": [
            "Unlimited & Unlimited+ Members"
          ],
          "content_role_arr": [
            "tier_1"
          ],
          "content_role": "tier_1",
          "status": "active",
          "drupal_id": "27696",
          "content_type": "course",
          "author": null,
          "updated_at": "2024-03-21 14:51:45",
          "creation_timestamp": "2024-03-08 08:25:04",
          "recommendation_id": "RID-1f-40c9-a26c-dfa978d7e683-CID-ba8cb2",
          "isAdminEnrolled": false,
          "course_record": {
            "name": "Goal Setting - Harvard ManageMentor",
            "type": "Course",
            "catalog_id": "hmm-goal-setting",
            "release_date": "2023-11-01T04:01:00Z",
            "close_date": null,
            "class_summary": null,
            "course_url": "https://cues.novoed.com/#!/courses/hmm-goal-setting/home"
          }
        },
        {
          "course_id": "30676",
          "item_id": "30676",
          "title": "Project Management Basics",
          "url": "/node/27366/edit",
          "course_catalog_id": "qve7r",
          "learning_journeys_arr": [
            "Leading Self",
            "Self-Development"
          ],
          "learning_journeys": "Leading Self|Self-Development",
          "personas_arr": [
            "Manager",
            "Staff"
          ],
          "personas": "Manager|Staff",
          "content_classification": "Manager|Staff",
          "topics_arr": [
            "Leadership"
          ],
          "topics": "Leadership",
          "genres_arr": [
            "Leadership"
          ],
          "genres": "Leadership",
          "series": "General",
          "tiers_arr": [
            "tier_1",
            "tier_2"
          ],
          "tiers": "tier_1|tier_2",
          "tiers_access_levels": [
            "Unlimited & Unlimited+ Members",
            "Individual Members"
          ],
          "content_role_arr": [
            "tier_1",
            "tier_2"
          ],
          "content_role": "tier_1|tier_2",
          "status": "active",
          "drupal_id": "27366",
          "content_type": "course",
          "author": null,
          "updated_at": "2024-03-07 10:42:28",
          "creation_timestamp": "2024-03-07 10:40:00",
          "recommendation_id": "RID-1f-40c9-a26c-dfa978d7e683-CID-ba8cb2",
          "isAdminEnrolled": false,
          "course_record": {
            "name": "Project Management Basics",
            "type": "Course",
            "catalog_id": "qve7r",
            "release_date": "2023-11-01T05:01:00Z",
            "close_date": null,
            "class_summary": "\u003Cp\u003EProject Management is in high demand and is important to every type of business. Many people think that project management is easy. Project Management requires highly skilled organization, attention to details, constant monitoring and problem solving to achieve a successful project. Increasing your knowledge of project management will maximize your skills and value for any role or position you seek in today's global market.\u003C/p\u003E",
            "course_url": "https://cues.novoed.com/#!/courses/qve7r/home"
          }
        },
        {
          "course_id": "31282",
          "item_id": "31282",
          "title": "Presentation Skills - Harvard ManageMentor",
          "url": "/node/27746/edit",
          "course_catalog_id": "hmm-presentation-skills",
          "learning_journeys_arr": [
            "Harvard ManageMentor Learning Journey"
          ],
          "learning_journeys": "Harvard ManageMentor Learning Journey",
          "personas_arr": [
            "CEO",
            "Executive",
            "Manager",
            "Staff",
            "Board"
          ],
          "personas": "CEO|Executive|Manager|Staff|Board",
          "content_classification": "CEO|Executive|Manager|Staff|Board",
          "topics_arr": [
            "Governance",
            "Human Resources",
            "Leadership"
          ],
          "topics": "Governance|Human Resources|Leadership",
          "genres_arr": [
            "Governance",
            "Human Resources",
            "Leadership"
          ],
          "genres": "Governance|Human Resources|Leadership",
          "series": "HMM",
          "tiers_arr": [
            "tier_1"
          ],
          "tiers": "tier_1",
          "tiers_access_levels": [
            "Unlimited & Unlimited+ Members"
          ],
          "content_role_arr": [
            "tier_1"
          ],
          "content_role": "tier_1",
          "status": "active",
          "drupal_id": "27746",
          "content_type": "course",
          "author": null,
          "updated_at": "2024-03-21 15:16:50",
          "creation_timestamp": "2024-03-08 08:34:44",
          "recommendation_id": "RID-1f-40c9-a26c-dfa978d7e683-CID-ba8cb2",
          "isAdminEnrolled": false,
          "course_record": {
            "name": "Presentation Skills - Harvard ManageMentor",
            "type": "Course",
            "catalog_id": "hmm-presentation-skills",
            "release_date": "2023-11-01T04:01:00Z",
            "close_date": null,
            "class_summary": "\u003Cp\u003EThe ability to deliver compelling presentations is a valuable asset that can open doors to new opportunities and further your success as a skilled communicator and influential leader. Expand your presentation skills and learn how to deliver clear and compelling messages that captivate your audience and leave a lasting impact.\u003C/p\u003E",
            "course_url": "https://cues.novoed.com/#!/courses/hmm-presentation-skills/home"
          }
        },
        {
          "course_id": "31271",
          "item_id": "31271",
          "title": "Innovation and Creativity - Harvard ManageMentor",
          "url": "/node/27796/edit",
          "course_catalog_id": "hmm-innov-creativity",
          "learning_journeys_arr": [
            "Harvard ManageMentor Learning Journey"
          ],
          "learning_journeys": "Harvard ManageMentor Learning Journey",
          "personas_arr": [
            "CEO",
            "Executive",
            "Manager",
            "Staff",
            "Board"
          ],
          "personas": "CEO|Executive|Manager|Staff|Board",
          "content_classification": "CEO|Executive|Manager|Staff|Board",
          "topics_arr": [
            "Innovation",
            "Strategy"
          ],
          "topics": "Innovation|Strategy",
          "genres_arr": [
            "Innovation",
            "Strategy"
          ],
          "genres": "Innovation|Strategy",
          "series": "HMM",
          "tiers_arr": [
            "tier_1"
          ],
          "tiers": "tier_1",
          "tiers_access_levels": [
            "Unlimited & Unlimited+ Members"
          ],
          "content_role_arr": [
            "tier_1"
          ],
          "content_role": "tier_1",
          "status": "active",
          "drupal_id": "27796",
          "content_type": "course",
          "author": null,
          "updated_at": "2024-03-21 15:08:14",
          "creation_timestamp": "2024-03-08 08:45:02",
          "recommendation_id": "RID-1f-40c9-a26c-dfa978d7e683-CID-ba8cb2",
          "isAdminEnrolled": false,
          "course_record": {
            "name": "Innovation and Creativity - Harvard ManageMentor",
            "type": "Course",
            "catalog_id": "hmm-innov-creativity",
            "release_date": "2023-11-01T04:01:00Z",
            "close_date": null,
            "class_summary": "\u003Cp\u003ESuccess in teams thrives on their ability to unleash creativity and innovate consistently. Discover how to harness your creativity and cultivate an environment that nurtures and encourages innovation, as it is the essential key to driving your team towards unparalleled achievements and sustainable growth.\u003C/p\u003E",
            "course_url": "https://cues.novoed.com/#!/courses/hmm-innov-creativity/home"
          }
        },
        {
          "course_id": "30312",
          "item_id": "30312",
          "title": "Business Writing Basics",
          "url": "/node/27636/edit",
          "course_catalog_id": "xedjr",
          "learning_journeys_arr": [
            "Communication",
            "Leading Self"
          ],
          "learning_journeys": "Communication|Leading Self",
          "personas_arr": [
            "Manager",
            "Staff"
          ],
          "personas": "Manager|Staff",
          "content_classification": "Manager|Staff",
          "topics_arr": [
            "Growth"
          ],
          "topics": "Growth",
          "genres_arr": [
            "Growth"
          ],
          "genres": "Growth",
          "series": "General",
          "tiers_arr": [
            "tier_1",
            "tier_2"
          ],
          "tiers": "tier_1|tier_2",
          "tiers_access_levels": [
            "Unlimited & Unlimited+ Members",
            "Individual Members"
          ],
          "content_role_arr": [
            "tier_1",
            "tier_2"
          ],
          "content_role": "tier_1|tier_2",
          "status": "active",
          "drupal_id": "27636",
          "content_type": "course",
          "author": null,
          "updated_at": "2024-05-20 14:34:25",
          "creation_timestamp": "2024-03-07 13:20:19",
          "recommendation_id": "RID-1f-40c9-a26c-dfa978d7e683-CID-ba8cb2",
          "isAdminEnrolled": false,
          "course_record": {
            "name": "Business Writing Basics",
            "type": "Course",
            "catalog_id": "xedjr",
            "release_date": "2023-11-01T04:01:00Z",
            "close_date": null,
            "class_summary": "\u003Cp\u003ECommunicating effectively in writing is a basic skill for everyone. Think about how often you write an email, present at a meeting, or speak to a large audience. All these activities require solid business writing skills. Writing etiquette, differences in writing styles and knowing the audience you are addressing (internal/external) are essential elements of basic writing skills.\u003C/p\u003E",
            "course_url": "https://cues.novoed.com/#!/courses/xedjr/home"
          }
        },
        {
          "course_id": "31287",
          "item_id": "31287",
          "title": "Strategy Planning and Execution - Harvard ManageMentor",
          "url": "/node/27721/edit",
          "course_catalog_id": "hmm-strat-planning-execution",
          "learning_journeys_arr": [
            "Harvard ManageMentor Learning Journey"
          ],
          "learning_journeys": "Harvard ManageMentor Learning Journey",
          "personas_arr": [
            "CEO",
            "Executive",
            "Board"
          ],
          "personas": "CEO|Executive|Board",
          "content_classification": "CEO|Executive|Board",
          "topics_arr": [
            "Governance",
            "Strategy"
          ],
          "topics": "Governance|Strategy",
          "genres_arr": [
            "Governance",
            "Strategy"
          ],
          "genres": "Governance|Strategy",
          "series": "HMM",
          "tiers_arr": [
            "tier_1"
          ],
          "tiers": "tier_1",
          "tiers_access_levels": [
            "Unlimited & Unlimited+ Members"
          ],
          "content_role_arr": [
            "tier_1"
          ],
          "content_role": "tier_1",
          "status": "active",
          "drupal_id": "27721",
          "content_type": "course",
          "author": null,
          "updated_at": "2024-03-21 15:20:30",
          "creation_timestamp": "2024-03-08 08:30:25",
          "recommendation_id": "RID-1f-40c9-a26c-dfa978d7e683-CID-ba8cb2",
          "isAdminEnrolled": false,
          "course_record": {
            "name": "Strategy Planning and Execution - Harvard ManageMentor",
            "type": "Course",
            "catalog_id": "hmm-strat-planning-execution",
            "release_date": "2023-11-01T04:01:00Z",
            "close_date": null,
            "class_summary": "\u003Cp\u003ESuccess for your organization hinges on devising and implementing a well-defined, robust strategic plan. Grasp your organization's unique differentiators that set it apart from competitors and recognize your pivotal role in executing the organization's strategy.\u003C/p\u003E",
            "course_url": "https://cues.novoed.com/#!/courses/hmm-strat-planning-execution/home"
          }
        },
        {
          "course_id": "30461",
          "item_id": "30461",
          "title": "Operations Management",
          "url": "/node/27376/edit",
          "course_catalog_id": "kq175",
          "learning_journeys_arr": [
            "Leading the Business",
            "Operations, Strategy, & Regulatory"
          ],
          "learning_journeys": "Leading the Business|Operations, Strategy, & Regulatory",
          "personas_arr": [
            "Executive",
            "Manager"
          ],
          "personas": "Executive|Manager",
          "content_classification": "Executive|Manager",
          "topics_arr": [
            "Operations"
          ],
          "topics": "Operations",
          "genres_arr": [
            "Operations"
          ],
          "genres": "Operations",
          "series": "General",
          "tiers_arr": [
            "tier_1",
            "tier_2"
          ],
          "tiers": "tier_1|tier_2",
          "tiers_access_levels": [
            "Unlimited & Unlimited+ Members",
            "Individual Members"
          ],
          "content_role_arr": [
            "tier_1",
            "tier_2"
          ],
          "content_role": "tier_1|tier_2",
          "status": "active",
          "drupal_id": "27376",
          "content_type": "course",
          "author": null,
          "updated_at": "2024-03-07 10:45:44",
          "creation_timestamp": "2024-03-07 10:44:19",
          "recommendation_id": "RID-1f-40c9-a26c-dfa978d7e683-CID-ba8cb2",
          "isAdminEnrolled": false,
          "course_record": {
            "name": "Operations Management",
            "type": "Course",
            "catalog_id": "kq175",
            "release_date": "2023-11-01T04:01:00Z",
            "close_date": null,
            "class_summary": "\u003Cp\u003EThis course is intended to increase knowledge of operations management needed to improve processes, solve problems, and support innovation. It will help you learn more about operations management through sections on important operations management principles.\u003C/p\u003E",
            "course_url": "https://cues.novoed.com/#!/courses/kq175/home"
          }
        },
        {
          "course_id": "31283",
          "item_id": "31283",
          "title": "Process Improvement - Harvard ManageMentor",
          "url": "/node/27736/edit",
          "course_catalog_id": "hmm-process-improvement",
          "learning_journeys_arr": [
            "Harvard ManageMentor Learning Journey"
          ],
          "learning_journeys": "Harvard ManageMentor Learning Journey",
          "personas_arr": [
            "Executive",
            "Manager",
            "Staff"
          ],
          "personas": "Executive|Manager|Staff",
          "content_classification": "Executive|Manager|Staff",
          "topics_arr": [
            "Human Resources",
            "Marketing",
            "Operations"
          ],
          "topics": "Human Resources|Marketing|Operations",
          "genres_arr": [
            "Human Resources",
            "Marketing",
            "Operations"
          ],
          "genres": "Human Resources|Marketing|Operations",
          "series": "HMM",
          "tiers_arr": [
            "tier_1"
          ],
          "tiers": "tier_1",
          "tiers_access_levels": [
            "Unlimited & Unlimited+ Members"
          ],
          "content_role_arr": [
            "tier_1"
          ],
          "content_role": "tier_1",
          "status": "active",
          "drupal_id": "27736",
          "content_type": "course",
          "author": null,
          "updated_at": "2024-03-21 15:18:11",
          "creation_timestamp": "2024-03-08 08:33:14",
          "recommendation_id": "RID-1f-40c9-a26c-dfa978d7e683-CID-ba8cb2",
          "isAdminEnrolled": false,
          "course_record": {
            "name": "Process Improvement - Harvard ManageMentor",
            "type": "Course",
            "catalog_id": "hmm-process-improvement",
            "release_date": "2023-11-01T04:01:00Z",
            "close_date": null,
            "class_summary": "\u003Cp\u003EInvesting time in process analysis and improvement is a strategic step that empowers your team to work smarter, not harder, and drive continuous growth and improvement within your organization. Maximize productivity for you and your team by comprehending the processes you are involved in and redesigning them to optimize your organization's performance.\u003C/p\u003E",
            "course_url": "https://cues.novoed.com/#!/courses/hmm-process-improvement/home"
          }
        },
        {
          "course_id": "29890",
          "item_id": "29890",
          "title": "Resiliency",
          "url": "/node/27351/edit",
          "course_catalog_id": "y34y4",
          "learning_journeys_arr": [
            "Change Management",
            "Leading Self",
            "Resiliency"
          ],
          "learning_journeys": "Change Management|Leading Self|Resiliency",
          "personas_arr": [
            "CEO",
            "Executive",
            "Manager",
            "Staff"
          ],
          "personas": "CEO|Executive|Manager|Staff",
          "content_classification": "CEO|Executive|Manager|Staff",
          "topics_arr": [
            "Leadership"
          ],
          "topics": "Leadership",
          "genres_arr": [
            "Leadership"
          ],
          "genres": "Leadership",
          "series": "General",
          "tiers_arr": [
            "tier_1",
            "tier_2"
          ],
          "tiers": "tier_1|tier_2",
          "tiers_access_levels": [
            "Unlimited & Unlimited+ Members",
            "Individual Members"
          ],
          "content_role_arr": [
            "tier_1",
            "tier_2"
          ],
          "content_role": "tier_1|tier_2",
          "status": "active",
          "drupal_id": "27351",
          "content_type": "course",
          "author": null,
          "updated_at": "2024-03-07 09:31:21",
          "creation_timestamp": "2024-03-07 09:29:31",
          "recommendation_id": "RID-1f-40c9-a26c-dfa978d7e683-CID-ba8cb2",
          "isAdminEnrolled": false,
          "course_record": {
            "name": "Resiliency",
            "type": "Course",
            "catalog_id": "y34y4",
            "release_date": "2023-11-01T04:01:00Z",
            "close_date": null,
            "class_summary": "\u003Cp\u003EHow well do you recover from difficult situations or challenges? By learning to be more resilient you can acknowledge the situation, learn from your mistakes, and move forward.\u003Cbr\u003E\u003C/p\u003E",
            "course_url": "https://cues.novoed.com/#!/courses/y34y4/home"
          }
        },
        {
          "course_id": "31268",
          "item_id": "31268",
          "title": "Finance Essentials - Harvard ManageMentor",
          "url": "/node/27806/edit",
          "course_catalog_id": "hmm-finance-essentials",
          "learning_journeys_arr": [
            "Harvard ManageMentor Learning Journey"
          ],
          "learning_journeys": "Harvard ManageMentor Learning Journey",
          "personas_arr": [
            "Manager",
            "Staff",
            "Board"
          ],
          "personas": "Manager|Staff|Board",
          "content_classification": "Manager|Staff|Board",
          "topics_arr": [
            "Marketing",
            "Operations",
            "Strategy"
          ],
          "topics": "Marketing|Operations|Strategy",
          "genres_arr": [
            "Marketing",
            "Operations",
            "Strategy"
          ],
          "genres": "Marketing|Operations|Strategy",
          "series": "HMM",
          "tiers_arr": [
            "tier_1"
          ],
          "tiers": "tier_1",
          "tiers_access_levels": [
            "Unlimited & Unlimited+ Members"
          ],
          "content_role_arr": [
            "tier_1"
          ],
          "content_role": "tier_1",
          "status": "active",
          "drupal_id": "27806",
          "content_type": "course",
          "author": null,
          "updated_at": "2024-03-21 14:56:05",
          "creation_timestamp": "2024-03-08 08:47:00",
          "recommendation_id": "RID-1f-40c9-a26c-dfa978d7e683-CID-ba8cb2",
          "isAdminEnrolled": false,
          "course_record": {
            "name": "Finance Essentials - Harvard ManageMentor",
            "type": "Course",
            "catalog_id": "hmm-finance-essentials",
            "release_date": "2023-11-01T04:01:00Z",
            "close_date": null,
            "class_summary": "Regardless of your position, grasping the fundamentals of finance empowers your team to optimize its resources effectively. Gain valuable insights into interpreting financial statements and making prudent investment decisions to achieve financial stability and success for your organization.",
            "course_url": "https://cues.novoed.com/#!/courses/hmm-finance-essentials/home"
          }
        },
        {
          "course_id": "31277",
          "item_id": "31277",
          "title": "Meeting Management - Harvard ManageMentor",
          "url": "/node/27766/edit",
          "course_catalog_id": "hmm-meeting-mgmt",
          "learning_journeys_arr": [
            "Harvard ManageMentor Learning Journey"
          ],
          "learning_journeys": "Harvard ManageMentor Learning Journey",
          "personas_arr": [
            "CEO",
            "Executive",
            "Manager",
            "Staff",
            "Board"
          ],
          "personas": "CEO|Executive|Manager|Staff|Board",
          "content_classification": "CEO|Executive|Manager|Staff|Board",
          "topics_arr": [
            "Governance",
            "Human Resources",
            "Leadership"
          ],
          "topics": "Governance|Human Resources|Leadership",
          "genres_arr": [
            "Governance",
            "Human Resources",
            "Leadership"
          ],
          "genres": "Governance|Human Resources|Leadership",
          "series": "HMM",
          "tiers_arr": [
            "tier_1"
          ],
          "tiers": "tier_1",
          "tiers_access_levels": [
            "Unlimited & Unlimited+ Members"
          ],
          "content_role_arr": [
            "tier_1"
          ],
          "content_role": "tier_1",
          "status": "active",
          "drupal_id": "27766",
          "content_type": "course",
          "author": null,
          "updated_at": "2024-03-21 15:12:29",
          "creation_timestamp": "2024-03-08 08:38:54",
          "recommendation_id": "RID-1f-40c9-a26c-dfa978d7e683-CID-ba8cb2",
          "isAdminEnrolled": false,
          "course_record": {
            "name": "Meeting Management - Harvard ManageMentor",
            "type": "Course",
            "catalog_id": "hmm-meeting-mgmt",
            "release_date": "2023-11-01T04:01:00Z",
            "close_date": null,
            "class_summary": "\u003Cp\u003ELong meetings that could have been handled via email are universally dreaded. A well-organized meeting not only saves time but also improves communication and streamlines decision-making, ultimately benefiting your organization in the long run. By learning various meeting managements skills, you can transform gatherings into productive and impactful sessions that drive collaboration.\u003C/p\u003E",
            "course_url": "https://cues.novoed.com/#!/courses/hmm-meeting-mgmt/home"
          }
        },
        {
          "course_id": "31280",
          "item_id": "31280",
          "title": "Performance Measurement - Harvard ManageMentor",
          "url": "/node/27756/edit",
          "course_catalog_id": "hmm-perf-measurement",
          "learning_journeys_arr": [
            "Harvard ManageMentor Learning Journey"
          ],
          "learning_journeys": "Harvard ManageMentor Learning Journey",
          "personas_arr": [
            "CEO",
            "Executive",
            "Manager",
            "Staff"
          ],
          "personas": "CEO|Executive|Manager|Staff",
          "content_classification": "CEO|Executive|Manager|Staff",
          "topics_arr": [
            "Human Resources",
            "Leadership"
          ],
          "topics": "Human Resources|Leadership",
          "genres_arr": [
            "Human Resources",
            "Leadership"
          ],
          "genres": "Human Resources|Leadership",
          "series": "HMM",
          "tiers_arr": [
            "tier_1"
          ],
          "tiers": "tier_1",
          "tiers_access_levels": [
            "Unlimited & Unlimited+ Members"
          ],
          "content_role_arr": [
            "tier_1"
          ],
          "content_role": "tier_1",
          "status": "active",
          "drupal_id": "27756",
          "content_type": "course",
          "author": null,
          "updated_at": "2024-03-21 15:15:57",
          "creation_timestamp": "2024-03-08 08:36:58",
          "recommendation_id": "RID-1f-40c9-a26c-dfa978d7e683-CID-ba8cb2",
          "isAdminEnrolled": false,
          "course_record": {
            "name": "Performance Measurement - Harvard ManageMentor",
            "type": "Course",
            "catalog_id": "hmm-perf-measurement",
            "release_date": "2023-11-01T04:01:00Z",
            "close_date": null,
            "class_summary": "\u003Cp\u003EPerformance measurement provides a valuable means to assess progress in achieving your goals.  These insights can help you make informed decisions, drive continuous improvement, and ensure your team is on the path to optimal performance and success.\u003C/p\u003E",
            "course_url": "https://cues.novoed.com/#!/courses/hmm-perf-measurement/home"
          }
        },
        {
          "course_id": "31281",
          "item_id": "31281",
          "title": "Persuading Others - Harvard ManageMentor",
          "url": "/node/27751/edit",
          "course_catalog_id": "hmm-persuading-others",
          "learning_journeys_arr": [
            "Harvard ManageMentor Learning Journey"
          ],
          "learning_journeys": "Harvard ManageMentor Learning Journey",
          "personas_arr": [
            "CEO",
            "Executive",
            "Manager",
            "Staff",
            "Board"
          ],
          "personas": "CEO|Executive|Manager|Staff|Board",
          "content_classification": "CEO|Executive|Manager|Staff|Board",
          "topics_arr": [
            "Governance",
            "Leadership"
          ],
          "topics": "Governance|Leadership",
          "genres_arr": [
            "Governance",
            "Leadership"
          ],
          "genres": "Governance|Leadership",
          "series": "HMM",
          "tiers_arr": [
            "tier_1"
          ],
          "tiers": "tier_1",
          "tiers_access_levels": [
            "Unlimited & Unlimited+ Members"
          ],
          "content_role_arr": [
            "tier_1"
          ],
          "content_role": "tier_1",
          "status": "active",
          "drupal_id": "27751",
          "content_type": "course",
          "author": null,
          "updated_at": "2024-03-21 15:17:31",
          "creation_timestamp": "2024-03-08 08:36:19",
          "recommendation_id": "RID-1f-40c9-a26c-dfa978d7e683-CID-ba8cb2",
          "isAdminEnrolled": false,
          "course_record": {
            "name": "Persuading Others - Harvard ManageMentor",
            "type": "Course",
            "catalog_id": "hmm-persuading-others",
            "release_date": "2023-11-01T04:01:00Z",
            "close_date": null,
            "class_summary": "\u003Cp\u003EEffective persuasion empowers you to establish credibility and expand your influence among others. Delve into distinctive strategies that enable you to craft compelling arguments and drive successful outcomes for your initiatives.\u003C/p\u003E",
            "course_url": "https://cues.novoed.com/#!/courses/hmm-persuading-others/home"
          }
        },
        {
          "course_id": "31274",
          "item_id": "31274",
          "title": "Leveraging Your Networks - Harvard ManageMentor",
          "url": "/node/27781/edit",
          "course_catalog_id": "hmm-leveraging-networks",
          "learning_journeys_arr": [
            "Harvard ManageMentor Learning Journey"
          ],
          "learning_journeys": "Harvard ManageMentor Learning Journey",
          "personas_arr": [
            "CEO",
            "Executive",
            "Manager",
            "Staff",
            "Board"
          ],
          "personas": "CEO|Executive|Manager|Staff|Board",
          "content_classification": "CEO|Executive|Manager|Staff|Board",
          "topics_arr": [
            "Governance",
            "Leadership"
          ],
          "topics": "Governance|Leadership",
          "genres_arr": [
            "Governance",
            "Leadership"
          ],
          "genres": "Governance|Leadership",
          "series": "HMM",
          "tiers_arr": [
            "tier_1"
          ],
          "tiers": "tier_1",
          "tiers_access_levels": [
            "Unlimited & Unlimited+ Members"
          ],
          "content_role_arr": [
            "tier_1"
          ],
          "content_role": "tier_1",
          "status": "active",
          "drupal_id": "27781",
          "content_type": "course",
          "author": null,
          "updated_at": "2024-03-21 15:09:51",
          "creation_timestamp": "2024-03-08 08:42:11",
          "recommendation_id": "RID-1f-40c9-a26c-dfa978d7e683-CID-ba8cb2",
          "isAdminEnrolled": false,
          "course_record": {
            "name": "Leveraging Your Networks - Harvard ManageMentor",
            "type": "Course",
            "catalog_id": "hmm-leveraging-networks",
            "release_date": "2023-11-01T04:01:00Z",
            "close_date": null,
            "class_summary": "\u003Cp\u003EWith effective networking, you can sharpen your interpersonal skills and forge valuable connections with important stakeholders within your organization. A well-developed network can serve as a powerful catalyst for personal and professional growth, paving the way for a thriving and impactful career.\u003C/p\u003E",
            "course_url": "https://cues.novoed.com/#!/courses/hmm-leveraging-networks/home"
          }
        },
        {
          "course_id": "31272",
          "item_id": "31272",
          "title": "Innovation Implementation - Harvard ManageMentor",
          "url": "/node/27791/edit",
          "course_catalog_id": "hmm-innov-implementation",
          "learning_journeys_arr": [
            "Harvard ManageMentor Learning Journey"
          ],
          "learning_journeys": "Harvard ManageMentor Learning Journey",
          "personas_arr": [
            "CEO",
            "Executive",
            "Manager",
            "Staff",
            "Board"
          ],
          "personas": "CEO|Executive|Manager|Staff|Board",
          "content_classification": "CEO|Executive|Manager|Staff|Board",
          "topics_arr": [
            "Innovation",
            "Strategy"
          ],
          "topics": "Innovation|Strategy",
          "genres_arr": [
            "Innovation",
            "Strategy"
          ],
          "genres": "Innovation|Strategy",
          "series": "HMM",
          "tiers_arr": [
            "tier_1"
          ],
          "tiers": "tier_1",
          "tiers_access_levels": [
            "Unlimited & Unlimited+ Members"
          ],
          "content_role_arr": [
            "tier_1"
          ],
          "content_role": "tier_1",
          "status": "active",
          "drupal_id": "27791",
          "content_type": "course",
          "author": null,
          "updated_at": "2024-04-18 11:28:59",
          "creation_timestamp": "2024-03-08 08:45:00",
          "recommendation_id": "RID-1f-40c9-a26c-dfa978d7e683-CID-ba8cb2",
          "isAdminEnrolled": false,
          "course_record": {
            "name": "Innovation Implementation - Harvard ManageMentor",
            "type": "Course",
            "catalog_id": "hmm-innov-implementation",
            "release_date": "2023-11-01T04:01:00Z",
            "close_date": null,
            "class_summary": "\u003Cp\u003EThe power of innovation lies in its ability to transform aspirations into concrete solutions that inspire and transform the way we work and live. Start turning your ideas into tangible products or services that will create a genuine impact for your team, organization, and members.\u003C/p\u003E",
            "course_url": "https://cues.novoed.com/#!/courses/hmm-innov-implementation/home"
          }
        },
        {
          "course_id": "30340",
          "item_id": "30340",
          "title": "Negotiating - Harvard ManageMentor",
          "url": "/node/27691/edit",
          "course_catalog_id": "hmm-negotiating",
          "learning_journeys_arr": [
            "Harvard ManageMentor Learning Journey"
          ],
          "learning_journeys": "Harvard ManageMentor Learning Journey",
          "personas_arr": [
            "CEO",
            "Executive",
            "Manager",
            "Staff",
            "Board"
          ],
          "personas": "CEO|Executive|Manager|Staff|Board",
          "content_classification": "CEO|Executive|Manager|Staff|Board",
          "topics_arr": [
            "Governance",
            "Human Resources",
            "Leadership"
          ],
          "topics": "Governance|Human Resources|Leadership",
          "genres_arr": [
            "Governance",
            "Human Resources",
            "Leadership"
          ],
          "genres": "Governance|Human Resources|Leadership",
          "series": "HMM",
          "tiers_arr": [
            "tier_1"
          ],
          "tiers": "tier_1",
          "tiers_access_levels": [
            "Unlimited & Unlimited+ Members"
          ],
          "content_role_arr": [
            "tier_1"
          ],
          "content_role": "tier_1",
          "status": "active",
          "drupal_id": "27691",
          "content_type": "course",
          "author": null,
          "updated_at": "2024-03-21 14:52:30",
          "creation_timestamp": "2024-03-08 08:24:16",
          "recommendation_id": "RID-1f-40c9-a26c-dfa978d7e683-CID-ba8cb2",
          "isAdminEnrolled": false,
          "course_record": {
            "name": "Negotiating - Harvard ManageMentor",
            "type": "Course",
            "catalog_id": "hmm-negotiating",
            "release_date": "2023-11-01T04:01:00Z",
            "close_date": null,
            "class_summary": null,
            "course_url": "https://cues.novoed.com/#!/courses/hmm-negotiating/home"
          }
        },
        {
          "course_id": "34969",
          "item_id": "34969",
          "title": "Understanding Credit Union Financial Statements | 2024 Director Education Center Course",
          "url": "/node/27511/edit",
          "course_catalog_id": "24dec-cu-fin-stmt",
          "learning_journeys_arr": [
            "CUES Canadian Director Education Center - Full Learning Journey 2024",
            "CUES Director Education Center - Full Learning Journey 2024",
            "New Board Members"
          ],
          "learning_journeys": "CUES Canadian Director Education Center - Full Learning Journey 2024|CUES Director Education Center - Full Learning Journey 2024|New Board Members",
          "personas_arr": [
            "Board"
          ],
          "personas": "Board",
          "content_classification": "Board",
          "topics_arr": [
            "Operations"
          ],
          "topics": "Operations",
          "genres_arr": [
            "Operations"
          ],
          "genres": "Operations",
          "series": "DEC",
          "tiers_arr": [
            "tier_1",
            "tier_2"
          ],
          "tiers": "tier_1|tier_2",
          "tiers_access_levels": [
            "Unlimited & Unlimited+ Members",
            "Individual Members"
          ],
          "content_role_arr": [
            "tier_1",
            "tier_2"
          ],
          "content_role": "tier_1|tier_2",
          "status": "active",
          "drupal_id": "27511",
          "content_type": "course",
          "author": null,
          "updated_at": "2024-03-07 12:00:04",
          "creation_timestamp": "2024-03-07 11:58:35",
          "recommendation_id": "RID-1f-40c9-a26c-dfa978d7e683-CID-ba8cb2",
          "isAdminEnrolled": false,
          "course_record": {
            "name": "Understanding Credit Union Financial Statements | 2024 Director Education Center Course",
            "type": "Course",
            "catalog_id": "24dec-cu-fin-stmt",
            "release_date": "2024-01-01T07:00:00Z",
            "close_date": null,
            "class_summary": null,
            "course_url": "https://cues.novoed.com/#!/courses/24dec-cu-fin-stmt/home"
          }
        },
        {
          "course_id": "34967",
          "item_id": "34967",
          "title": "A History of the Credit Union System | 2024 Director Education Center Course",
          "url": "/node/27551/edit",
          "course_catalog_id": "24dec-ahistory",
          "learning_journeys_arr": [
            "CUES Canadian Director Education Center - Full Learning Journey 2024",
            "CUES Director Education Center - Full Learning Journey 2024",
            "New Board Members"
          ],
          "learning_journeys": "CUES Canadian Director Education Center - Full Learning Journey 2024|CUES Director Education Center - Full Learning Journey 2024|New Board Members",
          "personas_arr": [
            "Board"
          ],
          "personas": "Board",
          "content_classification": "Board",
          "topics_arr": [],
          "topics": "",
          "genres_arr": [],
          "genres": "",
          "series": "DEC",
          "tiers_arr": [
            "tier_1",
            "tier_2"
          ],
          "tiers": "tier_1|tier_2",
          "tiers_access_levels": [
            "Unlimited & Unlimited+ Members",
            "Individual Members"
          ],
          "content_role_arr": [
            "tier_1",
            "tier_2"
          ],
          "content_role": "tier_1|tier_2",
          "status": "active",
          "drupal_id": "27551",
          "content_type": "course",
          "author": null,
          "updated_at": "2024-03-07 12:12:50",
          "creation_timestamp": "2024-03-07 12:11:53",
          "recommendation_id": "RID-1f-40c9-a26c-dfa978d7e683-CID-ba8cb2",
          "isAdminEnrolled": false,
          "course_record": {
            "name": "A History of the Credit Union System | 2024 Director Education Center Course",
            "type": "Course",
            "catalog_id": "24dec-ahistory",
            "release_date": "2024-01-01T07:00:00Z",
            "close_date": null,
            "class_summary": null,
            "course_url": "https://cues.novoed.com/#!/courses/24dec-ahistory/home"
          }
        },
        {
          "course_id": "35231",
          "item_id": "35231",
          "title": "Board Meeting Management",
          "url": "/node/27311/edit",
          "course_catalog_id": "gbwry",
          "learning_journeys_arr": [
            "Governance+"
          ],
          "learning_journeys": "Governance+",
          "personas_arr": [
            "CEO",
            "Board"
          ],
          "personas": "CEO|Board",
          "content_classification": "CEO|Board",
          "topics_arr": [
            "Leadership"
          ],
          "topics": "Leadership",
          "genres_arr": [
            "Leadership"
          ],
          "genres": "Leadership",
          "series": "General",
          "tiers_arr": [
            "tier_1",
            "tier_2"
          ],
          "tiers": "tier_1|tier_2",
          "tiers_access_levels": [
            "Unlimited & Unlimited+ Members",
            "Individual Members"
          ],
          "content_role_arr": [
            "tier_1",
            "tier_2"
          ],
          "content_role": "tier_1|tier_2",
          "status": "active",
          "drupal_id": "27311",
          "content_type": "course",
          "author": null,
          "updated_at": "2024-03-07 09:11:25",
          "creation_timestamp": "2024-03-07 09:10:04",
          "recommendation_id": "RID-1f-40c9-a26c-dfa978d7e683-CID-ba8cb2",
          "isAdminEnrolled": false,
          "course_record": {
            "name": "Board Meeting Management",
            "type": "Course",
            "catalog_id": "gbwry",
            "release_date": "2023-11-30T06:00:00Z",
            "close_date": null,
            "class_summary": null,
            "course_url": "https://cues.novoed.com/#!/courses/gbwry/home"
          }
        },
        {
          "course_id": "34979",
          "item_id": "34979",
          "title": "Asset Liability Management | 2024 Director Education Center Course",
          "url": "/node/27581/edit",
          "course_catalog_id": "24dec-alm",
          "learning_journeys_arr": [
            "Board Journey",
            "CUES Canadian Director Education Center - Full Learning Journey 2024",
            "CUES Director Education Center - Full Learning Journey 2024",
            "New Board Members"
          ],
          "learning_journeys": "Board Journey|CUES Canadian Director Education Center - Full Learning Journey 2024|CUES Director Education Center - Full Learning Journey 2024|New Board Members",
          "personas_arr": [
            "Board"
          ],
          "personas": "Board",
          "content_classification": "Board",
          "topics_arr": [],
          "topics": "",
          "genres_arr": [],
          "genres": "",
          "series": "DEC",
          "tiers_arr": [
            "tier_1",
            "tier_2"
          ],
          "tiers": "tier_1|tier_2",
          "tiers_access_levels": [
            "Unlimited & Unlimited+ Members",
            "Individual Members"
          ],
          "content_role_arr": [
            "tier_1",
            "tier_2"
          ],
          "content_role": "tier_1|tier_2",
          "status": "active",
          "drupal_id": "27581",
          "content_type": "course",
          "author": null,
          "updated_at": "2024-03-07 12:19:55",
          "creation_timestamp": "2024-03-07 12:18:51",
          "recommendation_id": "RID-1f-40c9-a26c-dfa978d7e683-CID-ba8cb2",
          "isAdminEnrolled": false,
          "course_record": {
            "name": "Asset Liability Management | 2024 Director Education Center Course",
            "type": "Course",
            "catalog_id": "24dec-alm",
            "release_date": "2024-01-01T07:00:00Z",
            "close_date": null,
            "class_summary": null,
            "course_url": "https://cues.novoed.com/#!/courses/24dec-alm/home"
          }
        },
        {
          "course_id": "31276",
          "item_id": "31276",
          "title": "Marketing Essentials - Harvard ManageMentor",
          "url": "/node/27771/edit",
          "course_catalog_id": "hmm-marketing-essentials",
          "learning_journeys_arr": [
            "Harvard ManageMentor Learning Journey"
          ],
          "learning_journeys": "Harvard ManageMentor Learning Journey",
          "personas_arr": [
            "Executive",
            "Manager",
            "Staff",
            "Board"
          ],
          "personas": "Executive|Manager|Staff|Board",
          "content_classification": "Executive|Manager|Staff|Board",
          "topics_arr": [
            "Growth",
            "Marketing"
          ],
          "topics": "Growth|Marketing",
          "genres_arr": [
            "Growth",
            "Marketing"
          ],
          "genres": "Growth|Marketing",
          "series": "HMM",
          "tiers_arr": [
            "tier_1"
          ],
          "tiers": "tier_1",
          "tiers_access_levels": [
            "Unlimited & Unlimited+ Members"
          ],
          "content_role_arr": [
            "tier_1"
          ],
          "content_role": "tier_1",
          "status": "active",
          "drupal_id": "27771",
          "content_type": "course",
          "author": null,
          "updated_at": "2024-03-21 15:11:21",
          "creation_timestamp": "2024-03-08 08:40:05",
          "recommendation_id": "RID-1f-40c9-a26c-dfa978d7e683-CID-ba8cb2",
          "isAdminEnrolled": false,
          "course_record": {
            "name": "Marketing Essentials - Harvard ManageMentor",
            "type": "Course",
            "catalog_id": "hmm-marketing-essentials",
            "release_date": "2023-11-01T04:01:00Z",
            "close_date": null,
            "class_summary": "\u003Cp\u003EThe success of your product or service heavily relies on how effectively you communicate with your customers. Uncover the key factors that influence their purchasing habits and master the art of winning them over through a compelling marketing strategy.\u003C/p\u003E",
            "course_url": "https://cues.novoed.com/#!/courses/hmm-marketing-essentials/home"
          }
        },
        {
          "course_id": "31269",
          "item_id": "31269",
          "title": "Global Collaboration - Harvard ManageMentor",
          "url": "/node/27801/edit",
          "course_catalog_id": "hmm-global-collaboration",
          "learning_journeys_arr": [
            "Harvard ManageMentor Learning Journey"
          ],
          "learning_journeys": "Harvard ManageMentor Learning Journey",
          "personas_arr": [
            "CEO",
            "Executive",
            "Board"
          ],
          "personas": "CEO|Executive|Board",
          "content_classification": "CEO|Executive|Board",
          "topics_arr": [
            "Governance",
            "Growth",
            "Leadership"
          ],
          "topics": "Governance|Growth|Leadership",
          "genres_arr": [
            "Governance",
            "Growth",
            "Leadership"
          ],
          "genres": "Governance|Growth|Leadership",
          "series": "HMM",
          "tiers_arr": [
            "tier_1"
          ],
          "tiers": "tier_1",
          "tiers_access_levels": [
            "Unlimited & Unlimited+ Members"
          ],
          "content_role_arr": [
            "tier_1"
          ],
          "content_role": "tier_1",
          "status": "active",
          "drupal_id": "27801",
          "content_type": "course",
          "author": null,
          "updated_at": "2024-03-21 14:56:52",
          "creation_timestamp": "2024-03-08 08:45:04",
          "recommendation_id": "RID-1f-40c9-a26c-dfa978d7e683-CID-ba8cb2",
          "isAdminEnrolled": false,
          "course_record": {
            "name": "Global Collaboration - Harvard ManageMentor",
            "type": "Course",
            "catalog_id": "hmm-global-collaboration",
            "release_date": "2023-11-01T04:01:00Z",
            "close_date": null,
            "class_summary": "\u003Cp\u003ECollaboration extends beyond your internal team; it involves connecting with others across diverse cultures and languages. Embracing cultural diversity and effective communication not only enhances collaboration but also opens doors to innovative perspectives and enriching experiences that significantly contribute to the success of your global initiatives.\u003C/p\u003E",
            "course_url": "https://cues.novoed.com/#!/courses/hmm-global-collaboration/home"
          }
        },
        {
          "course_id": "35130",
          "item_id": "35130",
          "title": "Introduction to Governance",
          "url": "/node/27416/edit",
          "course_catalog_id": "ymd3r",
          "learning_journeys_arr": [
            "Governance+"
          ],
          "learning_journeys": "Governance+",
          "personas_arr": [
            "Board"
          ],
          "personas": "Board",
          "content_classification": "Board",
          "topics_arr": [
            "Governance"
          ],
          "topics": "Governance",
          "genres_arr": [
            "Governance"
          ],
          "genres": "Governance",
          "series": "General",
          "tiers_arr": [
            "tier_1"
          ],
          "tiers": "tier_1",
          "tiers_access_levels": [
            "Unlimited & Unlimited+ Members"
          ],
          "content_role_arr": [
            "tier_1"
          ],
          "content_role": "tier_1",
          "status": "active",
          "drupal_id": "27416",
          "content_type": "course",
          "author": null,
          "updated_at": "2024-03-12 08:42:22",
          "creation_timestamp": "2024-03-07 11:04:17",
          "recommendation_id": "RID-1f-40c9-a26c-dfa978d7e683-CID-ba8cb2",
          "isAdminEnrolled": false,
          "course_record": {
            "name": "Introduction to Governance",
            "type": "Course",
            "catalog_id": "ymd3r",
            "release_date": "2023-11-30T06:00:00Z",
            "close_date": null,
            "class_summary": null,
            "course_url": "https://cues.novoed.com/#!/courses/ymd3r/home"
          }
        },
        {
          "course_id": "34974",
          "item_id": "34974",
          "title": "The Bank Secrecy Act and The Role of the Board | 2024 Director Education Center Course",
          "url": "/node/27576/edit",
          "course_catalog_id": "24dec-bsa",
          "learning_journeys_arr": [
            "CUES Director Education Center - Full Learning Journey 2024",
            "New Board Members"
          ],
          "learning_journeys": "CUES Director Education Center - Full Learning Journey 2024|New Board Members",
          "personas_arr": [
            "Board"
          ],
          "personas": "Board",
          "content_classification": "Board",
          "topics_arr": [],
          "topics": "",
          "genres_arr": [],
          "genres": "",
          "series": "DEC",
          "tiers_arr": [
            "tier_1",
            "tier_2"
          ],
          "tiers": "tier_1|tier_2",
          "tiers_access_levels": [
            "Unlimited & Unlimited+ Members",
            "Individual Members"
          ],
          "content_role_arr": [
            "tier_1",
            "tier_2"
          ],
          "content_role": "tier_1|tier_2",
          "status": "active",
          "drupal_id": "27576",
          "content_type": "course",
          "author": null,
          "updated_at": "2024-03-07 12:18:35",
          "creation_timestamp": "2024-03-07 12:17:55",
          "recommendation_id": "RID-1f-40c9-a26c-dfa978d7e683-CID-ba8cb2",
          "isAdminEnrolled": false,
          "course_record": {
            "name": "The Bank Secrecy Act and The Role of the Board | 2024 Director Education Center Course",
            "type": "Course",
            "catalog_id": "24dec-bsa",
            "release_date": "2024-01-01T07:00:00Z",
            "close_date": null,
            "class_summary": null,
            "course_url": "https://cues.novoed.com/#!/courses/24dec-bsa/home"
          }
        },
        {
          "course_id": "35358",
          "item_id": "35358",
          "title": "Introduction to Risk Management",
          "url": "/node/27406/edit",
          "course_catalog_id": "m6gwx",
          "learning_journeys_arr": [
            "Governance+"
          ],
          "learning_journeys": "Governance+",
          "personas_arr": [
            "Board"
          ],
          "personas": "Board",
          "content_classification": "Board",
          "topics_arr": [
            "Operations"
          ],
          "topics": "Operations",
          "genres_arr": [
            "Operations"
          ],
          "genres": "Operations",
          "series": "General",
          "tiers_arr": [
            "tier_1"
          ],
          "tiers": "tier_1",
          "tiers_access_levels": [
            "Unlimited & Unlimited+ Members"
          ],
          "content_role_arr": [
            "tier_1"
          ],
          "content_role": "tier_1",
          "status": "active",
          "drupal_id": "27406",
          "content_type": "course",
          "author": null,
          "updated_at": "2024-03-12 08:42:17",
          "creation_timestamp": "2024-03-07 11:00:39",
          "recommendation_id": "RID-1f-40c9-a26c-dfa978d7e683-CID-ba8cb2",
          "isAdminEnrolled": false,
          "course_record": {
            "name": "Introduction to Risk Management",
            "type": "Course",
            "catalog_id": "m6gwx",
            "release_date": "2023-11-30T06:00:00Z",
            "close_date": null,
            "class_summary": null,
            "course_url": "https://cues.novoed.com/#!/courses/m6gwx/home"
          }
        },
        {
          "course_id": "34972",
          "item_id": "34972",
          "title": "The Role of the Board in Establishing an Effective Risk Management Framework | 2024 Director Education ",
          "url": "/node/27556/edit",
          "course_catalog_id": "24dec-risk-mgt",
          "learning_journeys_arr": [
            "CUES Canadian Director Education Center - Full Learning Journey 2024",
            "CUES Director Education Center - Full Learning Journey 2024"
          ],
          "learning_journeys": "CUES Canadian Director Education Center - Full Learning Journey 2024|CUES Director Education Center - Full Learning Journey 2024",
          "personas_arr": [
            "Board"
          ],
          "personas": "Board",
          "content_classification": "Board",
          "topics_arr": [],
          "topics": "",
          "genres_arr": [],
          "genres": "",
          "series": "DEC",
          "tiers_arr": [
            "tier_1",
            "tier_2"
          ],
          "tiers": "tier_1|tier_2",
          "tiers_access_levels": [
            "Unlimited & Unlimited+ Members",
            "Individual Members"
          ],
          "content_role_arr": [
            "tier_1",
            "tier_2"
          ],
          "content_role": "tier_1|tier_2",
          "status": "active",
          "drupal_id": "27556",
          "content_type": "course",
          "author": null,
          "updated_at": "2024-03-07 12:14:03",
          "creation_timestamp": "2024-03-07 12:13:09",
          "recommendation_id": "RID-1f-40c9-a26c-dfa978d7e683-CID-ba8cb2",
          "isAdminEnrolled": false,
          "course_record": {
            "name": "The Role of the Board in Establishing an Effective Risk Management Framework | 2024 Director Education Center Course",
            "type": "Course",
            "catalog_id": "24dec-risk-mgt",
            "release_date": "2024-01-01T07:00:00Z",
            "close_date": null,
            "class_summary": null,
            "course_url": "https://cues.novoed.com/#!/courses/24dec-risk-mgt/home"
          }
        },
        {
          "course_id": "34966",
          "item_id": "34966",
          "title": "The Role and Responsibilities of Board Committees and Task Forces | 2024 Director Education Center ",
          "url": "/node/27571/edit",
          "course_catalog_id": "24dec-comm-task",
          "learning_journeys_arr": [
            "CUES Canadian Director Education Center - Full Learning Journey 2024",
            "CUES Director Education Center - Full Learning Journey 2024"
          ],
          "learning_journeys": "CUES Canadian Director Education Center - Full Learning Journey 2024|CUES Director Education Center - Full Learning Journey 2024",
          "personas_arr": [
            "Board"
          ],
          "personas": "Board",
          "content_classification": "Board",
          "topics_arr": [],
          "topics": "",
          "genres_arr": [],
          "genres": "",
          "series": "DEC",
          "tiers_arr": [
            "tier_1",
            "tier_2"
          ],
          "tiers": "tier_1|tier_2",
          "tiers_access_levels": [
            "Unlimited & Unlimited+ Members",
            "Individual Members"
          ],
          "content_role_arr": [
            "tier_1",
            "tier_2"
          ],
          "content_role": "tier_1|tier_2",
          "status": "active",
          "drupal_id": "27571",
          "content_type": "course",
          "author": null,
          "updated_at": "2024-03-07 12:17:31",
          "creation_timestamp": "2024-03-07 12:16:40",
          "recommendation_id": "RID-1f-40c9-a26c-dfa978d7e683-CID-ba8cb2",
          "isAdminEnrolled": false,
          "course_record": {
            "name": "The Role and Responsibilities of Board Committees and Task Forces | 2024 Director Education Center Course",
            "type": "Course",
            "catalog_id": "24dec-comm-task",
            "release_date": "2024-01-01T07:00:00Z",
            "close_date": null,
            "class_summary": null,
            "course_url": "https://cues.novoed.com/#!/courses/24dec-comm-task/home"
          }
        },
        {
          "course_id": "34978",
          "item_id": "34978",
          "title": "The Role of the Credit Union Board in Strategic Planning | 2024 Director Education Center Course",
          "url": "/node/27521/edit",
          "course_catalog_id": "24dec-stra-plan",
          "learning_journeys_arr": [
            "CUES Canadian Director Education Center - Full Learning Journey 2024",
            "CUES Director Education Center - Full Learning Journey 2024"
          ],
          "learning_journeys": "CUES Canadian Director Education Center - Full Learning Journey 2024|CUES Director Education Center - Full Learning Journey 2024",
          "personas_arr": [
            "Board"
          ],
          "personas": "Board",
          "content_classification": "Board",
          "topics_arr": [
            "Strategy"
          ],
          "topics": "Strategy",
          "genres_arr": [
            "Strategy"
          ],
          "genres": "Strategy",
          "series": "DEC",
          "tiers_arr": [
            "tier_1",
            "tier_2"
          ],
          "tiers": "tier_1|tier_2",
          "tiers_access_levels": [
            "Unlimited & Unlimited+ Members",
            "Individual Members"
          ],
          "content_role_arr": [
            "tier_1",
            "tier_2"
          ],
          "content_role": "tier_1|tier_2",
          "status": "active",
          "drupal_id": "27521",
          "content_type": "course",
          "author": null,
          "updated_at": "2024-03-07 12:04:24",
          "creation_timestamp": "2024-03-07 12:03:08",
          "recommendation_id": "RID-1f-40c9-a26c-dfa978d7e683-CID-ba8cb2",
          "isAdminEnrolled": false,
          "course_record": {
            "name": "The Role of the Credit Union Board in Strategic Planning | 2024 Director Education Center Course",
            "type": "Course",
            "catalog_id": "24dec-stra-plan",
            "release_date": "2024-01-01T07:00:00Z",
            "close_date": null,
            "class_summary": null,
            "course_url": "https://cues.novoed.com/#!/courses/24dec-stra-plan/home"
          }
        },
        {
          "course_id": "34968",
          "item_id": "34968",
          "title": "The Role of the Board in Recruiting, Compensating and Assessing the CEO | 2024 Director Education Center Course",
          "url": "/node/27541/edit",
          "course_catalog_id": "24dec-rec-comp-ceo",
          "learning_journeys_arr": [
            "CUES Canadian Director Education Center - Full Learning Journey 2024",
            "CUES Director Education Center - Full Learning Journey 2024"
          ],
          "learning_journeys": "CUES Canadian Director Education Center - Full Learning Journey 2024|CUES Director Education Center - Full Learning Journey 2024",
          "personas_arr": [
            "Board"
          ],
          "personas": "Board",
          "content_classification": "Board",
          "topics_arr": [
            "Governance"
          ],
          "topics": "Governance",
          "genres_arr": [
            "Governance"
          ],
          "genres": "Governance",
          "series": "DEC",
          "tiers_arr": [
            "tier_1",
            "tier_2"
          ],
          "tiers": "tier_1|tier_2",
          "tiers_access_levels": [
            "Unlimited & Unlimited+ Members",
            "Individual Members"
          ],
          "content_role_arr": [
            "tier_1",
            "tier_2"
          ],
          "content_role": "tier_1|tier_2",
          "status": "active",
          "drupal_id": "27541",
          "content_type": "course",
          "author": null,
          "updated_at": "2024-03-07 12:10:38",
          "creation_timestamp": "2024-03-07 12:09:30",
          "recommendation_id": "RID-1f-40c9-a26c-dfa978d7e683-CID-ba8cb2",
          "isAdminEnrolled": false,
          "course_record": {
            "name": "The Role of the Board in Recruiting, Compensating and Assessing the CEO | 2024 Director Education Center Course",
            "type": "Course",
            "catalog_id": "24dec-rec-comp-ceo",
            "release_date": "2024-01-01T07:00:00Z",
            "close_date": null,
            "class_summary": null,
            "course_url": "https://cues.novoed.com/#!/courses/24dec-rec-comp-ceo/home"
          }
        },
        {
          "course_id": "34975",
          "item_id": "34975",
          "title": "Delegation of Board Authority to Management | 2024 Director Education Center Course",
          "url": "/node/27561/edit",
          "course_catalog_id": "24dec-del-mgt",
          "learning_journeys_arr": [
            "CUES Canadian Director Education Center - Full Learning Journey 2024",
            "CUES Director Education Center - Full Learning Journey 2024"
          ],
          "learning_journeys": "CUES Canadian Director Education Center - Full Learning Journey 2024|CUES Director Education Center - Full Learning Journey 2024",
          "personas_arr": [
            "Board"
          ],
          "personas": "Board",
          "content_classification": "Board",
          "topics_arr": [],
          "topics": "",
          "genres_arr": [],
          "genres": "",
          "series": "DEC",
          "tiers_arr": [
            "tier_1",
            "tier_2"
          ],
          "tiers": "tier_1|tier_2",
          "tiers_access_levels": [
            "Unlimited & Unlimited+ Members",
            "Individual Members"
          ],
          "content_role_arr": [
            "tier_1",
            "tier_2"
          ],
          "content_role": "tier_1|tier_2",
          "status": "active",
          "drupal_id": "27561",
          "content_type": "course",
          "author": null,
          "updated_at": "2024-03-07 12:15:04",
          "creation_timestamp": "2024-03-07 12:14:11",
          "recommendation_id": "RID-1f-40c9-a26c-dfa978d7e683-CID-ba8cb2",
          "isAdminEnrolled": false,
          "course_record": {
            "name": "Delegation of Board Authority to Management | 2024 Director Education Center Course",
            "type": "Course",
            "catalog_id": "24dec-del-mgt",
            "release_date": "2024-01-04T07:00:00Z",
            "close_date": null,
            "class_summary": null,
            "course_url": "https://cues.novoed.com/#!/courses/24dec-del-mgt/home"
          }
        },
        {
          "course_id": "35360",
          "item_id": "35360",
          "title": "CEO Compensation",
          "url": "/node/27631/edit",
          "course_catalog_id": "91w4j",
          "learning_journeys_arr": [
            "Governance+"
          ],
          "learning_journeys": "Governance+",
          "personas_arr": [
            "Board"
          ],
          "personas": "Board",
          "content_classification": "Board",
          "topics_arr": [
            "Operations"
          ],
          "topics": "Operations",
          "genres_arr": [
            "Operations"
          ],
          "genres": "Operations",
          "series": "General",
          "tiers_arr": [
            "tier_1"
          ],
          "tiers": "tier_1",
          "tiers_access_levels": [
            "Unlimited & Unlimited+ Members"
          ],
          "content_role_arr": [
            "tier_1"
          ],
          "content_role": "tier_1",
          "status": "active",
          "drupal_id": "27631",
          "content_type": "course",
          "author": null,
          "updated_at": "2024-05-20 14:35:03",
          "creation_timestamp": "2024-03-07 13:16:43",
          "recommendation_id": "RID-1f-40c9-a26c-dfa978d7e683-CID-ba8cb2",
          "isAdminEnrolled": false,
          "course_record": {
            "name": "CEO Compensation",
            "type": "Course",
            "catalog_id": "91w4j",
            "release_date": "2023-11-30T06:00:00Z",
            "close_date": null,
            "class_summary": null,
            "course_url": "https://cues.novoed.com/#!/courses/91w4j/home"
          }
        },
        {
          "course_id": "35359",
          "item_id": "35359",
          "title": "Introduction To CEO Relations",
          "url": "/node/27431/edit",
          "course_catalog_id": "68kla",
          "learning_journeys_arr": [
            "Governance+"
          ],
          "learning_journeys": "Governance+",
          "personas_arr": [
            "Board"
          ],
          "personas": "Board",
          "content_classification": "Board",
          "topics_arr": [
            "Leadership"
          ],
          "topics": "Leadership",
          "genres_arr": [
            "Leadership"
          ],
          "genres": "Leadership",
          "series": "General",
          "tiers_arr": [
            "tier_1"
          ],
          "tiers": "tier_1",
          "tiers_access_levels": [
            "Unlimited & Unlimited+ Members"
          ],
          "content_role_arr": [
            "tier_1"
          ],
          "content_role": "tier_1",
          "status": "active",
          "drupal_id": "27431",
          "content_type": "course",
          "author": null,
          "updated_at": "2024-03-12 08:42:37",
          "creation_timestamp": "2024-03-07 11:14:36",
          "recommendation_id": "RID-1f-40c9-a26c-dfa978d7e683-CID-ba8cb2",
          "isAdminEnrolled": false,
          "course_record": {
            "name": "Introduction To CEO Relations",
            "type": "Course",
            "catalog_id": "68kla",
            "release_date": "2023-11-30T06:00:00Z",
            "close_date": null,
            "class_summary": null,
            "course_url": "https://cues.novoed.com/#!/courses/68kla/home"
          }
        },
        {
          "course_id": "35317",
          "item_id": "35317",
          "title": "Introduction To Strategy",
          "url": "/node/27401/edit",
          "course_catalog_id": "wrean",
          "learning_journeys_arr": [
            "Governance+"
          ],
          "learning_journeys": "Governance+",
          "personas_arr": [
            "Board"
          ],
          "personas": "Board",
          "content_classification": "Board",
          "topics_arr": [
            "Strategy"
          ],
          "topics": "Strategy",
          "genres_arr": [
            "Strategy"
          ],
          "genres": "Strategy",
          "series": "General",
          "tiers_arr": [
            "tier_1"
          ],
          "tiers": "tier_1",
          "tiers_access_levels": [
            "Unlimited & Unlimited+ Members"
          ],
          "content_role_arr": [
            "tier_1"
          ],
          "content_role": "tier_1",
          "status": "active",
          "drupal_id": "27401",
          "content_type": "course",
          "author": null,
          "updated_at": "2024-03-12 08:42:13",
          "creation_timestamp": "2024-03-07 10:58:47",
          "recommendation_id": "RID-1f-40c9-a26c-dfa978d7e683-CID-ba8cb2",
          "isAdminEnrolled": false,
          "course_record": {
            "name": "Introduction To Strategy",
            "type": "Course",
            "catalog_id": "wrean",
            "release_date": "2023-11-30T06:00:00Z",
            "close_date": null,
            "class_summary": null,
            "course_url": "https://cues.novoed.com/#!/courses/wrean/home"
          }
        },
        {
          "course_id": "35384",
          "item_id": "35384",
          "title": "Introduction to Committees",
          "url": "/node/27426/edit",
          "course_catalog_id": "rxdb6",
          "learning_journeys_arr": [
            "Governance+"
          ],
          "learning_journeys": "Governance+",
          "personas_arr": [
            "Board"
          ],
          "personas": "Board",
          "content_classification": "Board",
          "topics_arr": [
            "Leadership"
          ],
          "topics": "Leadership",
          "genres_arr": [
            "Leadership"
          ],
          "genres": "Leadership",
          "series": "General",
          "tiers_arr": [
            "tier_1"
          ],
          "tiers": "tier_1",
          "tiers_access_levels": [
            "Unlimited & Unlimited+ Members"
          ],
          "content_role_arr": [
            "tier_1"
          ],
          "content_role": "tier_1",
          "status": "active",
          "drupal_id": "27426",
          "content_type": "course",
          "author": null,
          "updated_at": "2024-03-12 08:42:32",
          "creation_timestamp": "2024-03-07 11:12:59",
          "recommendation_id": "RID-1f-40c9-a26c-dfa978d7e683-CID-ba8cb2",
          "isAdminEnrolled": false,
          "course_record": {
            "name": "Introduction to Committees",
            "type": "Course",
            "catalog_id": "rxdb6",
            "release_date": "2023-11-30T06:00:00Z",
            "close_date": null,
            "class_summary": null,
            "course_url": "https://cues.novoed.com/#!/courses/rxdb6/home"
          }
        },
        {
          "course_id": "34976",
          "item_id": "34976",
          "title": "The Role of the Board in Strategic Relationships and Mergers | 2024 Director Education Center Course",
          "url": "/node/27516/edit",
          "course_catalog_id": "24dec-stra-merger",
          "learning_journeys_arr": [
            "Board Journey",
            "CUES Canadian Director Education Center - Full Learning Journey 2024",
            "CUES Director Education Center - Full Learning Journey 2024"
          ],
          "learning_journeys": "Board Journey|CUES Canadian Director Education Center - Full Learning Journey 2024|CUES Director Education Center - Full Learning Journey 2024",
          "personas_arr": [
            "Board"
          ],
          "personas": "Board",
          "content_classification": "Board",
          "topics_arr": [
            "Strategy"
          ],
          "topics": "Strategy",
          "genres_arr": [
            "Strategy"
          ],
          "genres": "Strategy",
          "series": "DEC",
          "tiers_arr": [
            "tier_1",
            "tier_2"
          ],
          "tiers": "tier_1|tier_2",
          "tiers_access_levels": [
            "Unlimited & Unlimited+ Members",
            "Individual Members"
          ],
          "content_role_arr": [
            "tier_1",
            "tier_2"
          ],
          "content_role": "tier_1|tier_2",
          "status": "active",
          "drupal_id": "27516",
          "content_type": "course",
          "author": null,
          "updated_at": "2024-03-07 12:02:30",
          "creation_timestamp": "2024-03-07 12:01:35",
          "recommendation_id": "RID-1f-40c9-a26c-dfa978d7e683-CID-ba8cb2",
          "isAdminEnrolled": false,
          "course_record": {
            "name": "The Role of the Board in Strategic Relationships and Mergers | 2024 Director Education Center Course",
            "type": "Course",
            "catalog_id": "24dec-stra-merger",
            "release_date": "2024-01-04T07:00:00Z",
            "close_date": null,
            "class_summary": null,
            "course_url": "https://cues.novoed.com/#!/courses/24dec-stra-merger/home"
          }
        },
        {
          "course_id": "35315",
          "item_id": "35315",
          "title": "Essential Resources for Today's Board Chair",
          "url": "/node/27466/edit",
          "course_catalog_id": "m6gwv",
          "learning_journeys_arr": [
            "Governance+"
          ],
          "learning_journeys": "Governance+",
          "personas_arr": [
            "Board"
          ],
          "personas": "Board",
          "content_classification": "Board",
          "topics_arr": [],
          "topics": "",
          "genres_arr": [],
          "genres": "",
          "series": "General",
          "tiers_arr": [
            "tier_1"
          ],
          "tiers": "tier_1",
          "tiers_access_levels": [
            "Unlimited & Unlimited+ Members"
          ],
          "content_role_arr": [
            "tier_1"
          ],
          "content_role": "tier_1",
          "status": "active",
          "drupal_id": "27466",
          "content_type": "course",
          "author": null,
          "updated_at": "2024-03-12 08:43:19",
          "creation_timestamp": "2024-03-07 11:38:33",
          "recommendation_id": "RID-1f-40c9-a26c-dfa978d7e683-CID-ba8cb2",
          "isAdminEnrolled": false,
          "course_record": {
            "name": "Essential Resources for Today's Board Chair",
            "type": "Course",
            "catalog_id": "m6gwv",
            "release_date": "2023-11-30T06:00:00Z",
            "close_date": null,
            "class_summary": null,
            "course_url": "https://cues.novoed.com/#!/courses/m6gwv/home"
          }
        },
        {
          "course_id": "34970",
          "item_id": "34970",
          "title": "The Role and Responsibilities of a Credit Union Board | 2024 Director Education Center Course",
          "url": "/node/27526/edit",
          "course_catalog_id": "24dec-rr-cu-b",
          "learning_journeys_arr": [
            "CUES Canadian Director Education Center - Full Learning Journey 2024",
            "CUES Director Education Center - Full Learning Journey 2024",
            "New Board Members"
          ],
          "learning_journeys": "CUES Canadian Director Education Center - Full Learning Journey 2024|CUES Director Education Center - Full Learning Journey 2024|New Board Members",
          "personas_arr": [
            "Board"
          ],
          "personas": "Board",
          "content_classification": "Board",
          "topics_arr": [
            "Governance"
          ],
          "topics": "Governance",
          "genres_arr": [
            "Governance"
          ],
          "genres": "Governance",
          "series": "DEC",
          "tiers_arr": [
            "tier_1",
            "tier_2"
          ],
          "tiers": "tier_1|tier_2",
          "tiers_access_levels": [
            "Unlimited & Unlimited+ Members",
            "Individual Members"
          ],
          "content_role_arr": [
            "tier_1",
            "tier_2"
          ],
          "content_role": "tier_1|tier_2",
          "status": "active",
          "drupal_id": "27526",
          "content_type": "course",
          "author": null,
          "updated_at": "2024-03-07 12:06:26",
          "creation_timestamp": "2024-03-07 12:04:56",
          "recommendation_id": "RID-1f-40c9-a26c-dfa978d7e683-CID-ba8cb2",
          "isAdminEnrolled": false,
          "course_record": {
            "name": "The Role and Responsibilities of a Credit Union Board | 2024 Director Education Center Course",
            "type": "Course",
            "catalog_id": "24dec-rr-cu-b",
            "release_date": "2024-01-01T07:00:00Z",
            "close_date": null,
            "class_summary": null,
            "course_url": "https://cues.novoed.com/#!/courses/24dec-rr-cu-b/home"
          }
        },
        {
          "course_id": "35320",
          "item_id": "35320",
          "title": "Introduction To Fiduciary Duties",
          "url": "/node/27421/edit",
          "course_catalog_id": "5anwo",
          "learning_journeys_arr": [
            "Governance+"
          ],
          "learning_journeys": "Governance+",
          "personas_arr": [
            "Board"
          ],
          "personas": "Board",
          "content_classification": "Board",
          "topics_arr": [
            "Operations"
          ],
          "topics": "Operations",
          "genres_arr": [
            "Operations"
          ],
          "genres": "Operations",
          "series": "General",
          "tiers_arr": [
            "tier_1"
          ],
          "tiers": "tier_1",
          "tiers_access_levels": [
            "Unlimited & Unlimited+ Members"
          ],
          "content_role_arr": [
            "tier_1"
          ],
          "content_role": "tier_1",
          "status": "active",
          "drupal_id": "27421",
          "content_type": "course",
          "author": null,
          "updated_at": "2024-03-12 08:42:27",
          "creation_timestamp": "2024-03-07 11:11:24",
          "recommendation_id": "RID-1f-40c9-a26c-dfa978d7e683-CID-ba8cb2",
          "isAdminEnrolled": false,
          "course_record": {
            "name": "Introduction To Fiduciary Duties",
            "type": "Course",
            "catalog_id": "5anwo",
            "release_date": "2023-11-30T06:00:00Z",
            "close_date": null,
            "class_summary": null,
            "course_url": "https://cues.novoed.com/#!/courses/5anwo/home"
          }
        },
        {
          "course_id": "34977",
          "item_id": "34977",
          "title": "Recruitment, Election and Orientation of New Board Members | 2024 Director Education Center Course",
          "url": "/node/27536/edit",
          "course_catalog_id": "24dec-rec-ele-ori-bod",
          "learning_journeys_arr": [
            "CUES Canadian Director Education Center - Full Learning Journey 2024",
            "CUES Director Education Center - Full Learning Journey 2024"
          ],
          "learning_journeys": "CUES Canadian Director Education Center - Full Learning Journey 2024|CUES Director Education Center - Full Learning Journey 2024",
          "personas_arr": [
            "Board"
          ],
          "personas": "Board",
          "content_classification": "Board",
          "topics_arr": [],
          "topics": "",
          "genres_arr": [],
          "genres": "",
          "series": "DEC",
          "tiers_arr": [
            "tier_1",
            "tier_2"
          ],
          "tiers": "tier_1|tier_2",
          "tiers_access_levels": [
            "Unlimited & Unlimited+ Members",
            "Individual Members"
          ],
          "content_role_arr": [
            "tier_1",
            "tier_2"
          ],
          "content_role": "tier_1|tier_2",
          "status": "active",
          "drupal_id": "27536",
          "content_type": "course",
          "author": null,
          "updated_at": "2024-03-07 12:09:20",
          "creation_timestamp": "2024-03-07 12:08:32",
          "recommendation_id": "RID-1f-40c9-a26c-dfa978d7e683-CID-ba8cb2",
          "isAdminEnrolled": false,
          "course_record": {
            "name": "Recruitment, Election and Orientation of New Board Members | 2024 Director Education Center Course",
            "type": "Course",
            "catalog_id": "24dec-rec-ele-ori-bod",
            "release_date": "2024-01-01T07:00:00Z",
            "close_date": null,
            "class_summary": null,
            "course_url": "https://cues.novoed.com/#!/courses/24dec-rec-ele-ori-bod/home"
          }
        },
        {
          "course_id": "35394",
          "item_id": "35394",
          "title": "Introduction To Advocacy",
          "url": "/node/27436/edit",
          "course_catalog_id": "nnxqj",
          "learning_journeys_arr": [
            "Governance+"
          ],
          "learning_journeys": "Governance+",
          "personas_arr": [
            "Board"
          ],
          "personas": "Board",
          "content_classification": "Board",
          "topics_arr": [
            "Governance"
          ],
          "topics": "Governance",
          "genres_arr": [
            "Governance"
          ],
          "genres": "Governance",
          "series": "General",
          "tiers_arr": [
            "tier_1"
          ],
          "tiers": "tier_1",
          "tiers_access_levels": [
            "Unlimited & Unlimited+ Members"
          ],
          "content_role_arr": [
            "tier_1"
          ],
          "content_role": "tier_1",
          "status": "active",
          "drupal_id": "27436",
          "content_type": "course",
          "author": null,
          "updated_at": "2024-03-12 08:43:25",
          "creation_timestamp": "2024-03-07 11:15:31",
          "recommendation_id": "RID-1f-40c9-a26c-dfa978d7e683-CID-ba8cb2",
          "isAdminEnrolled": false,
          "course_record": {
            "name": "Introduction To Advocacy",
            "type": "Course",
            "catalog_id": "nnxqj",
            "release_date": "2023-11-30T06:00:00Z",
            "close_date": null,
            "class_summary": null,
            "course_url": "https://cues.novoed.com/#!/courses/nnxqj/home"
          }
        },
        {
          "course_id": "35314",
          "item_id": "35314",
          "title": "Revitalizing Your Board",
          "url": "/node/27346/edit",
          "course_catalog_id": "72rz3",
          "learning_journeys_arr": [
            "Governance+"
          ],
          "learning_journeys": "Governance+",
          "personas_arr": [
            "Board"
          ],
          "personas": "Board",
          "content_classification": "Board",
          "topics_arr": [],
          "topics": "",
          "genres_arr": [],
          "genres": "",
          "series": "General",
          "tiers_arr": [
            "tier_1"
          ],
          "tiers": "tier_1",
          "tiers_access_levels": [
            "Unlimited & Unlimited+ Members"
          ],
          "content_role_arr": [
            "tier_1"
          ],
          "content_role": "tier_1",
          "status": "active",
          "drupal_id": "27346",
          "content_type": "course",
          "author": null,
          "updated_at": "2024-03-12 08:42:08",
          "creation_timestamp": "2024-03-07 09:27:29",
          "recommendation_id": "RID-1f-40c9-a26c-dfa978d7e683-CID-ba8cb2",
          "isAdminEnrolled": false,
          "course_record": {
            "name": "Revitalizing Your Board",
            "type": "Course",
            "catalog_id": "72rz3",
            "release_date": "2023-11-30T06:00:00Z",
            "close_date": null,
            "class_summary": null,
            "course_url": "https://cues.novoed.com/#!/courses/72rz3/home"
          }
        },
        {
          "course_id": "35376",
          "item_id": "35376",
          "title": "CEO Performance Evaluation",
          "url": "/node/27626/edit",
          "course_catalog_id": "o6pbk",
          "learning_journeys_arr": [
            "Governance+"
          ],
          "learning_journeys": "Governance+",
          "personas_arr": [
            "Board"
          ],
          "personas": "Board",
          "content_classification": "Board",
          "topics_arr": [
            "Governance"
          ],
          "topics": "Governance",
          "genres_arr": [
            "Governance"
          ],
          "genres": "Governance",
          "series": "General",
          "tiers_arr": [
            "tier_1"
          ],
          "tiers": "tier_1",
          "tiers_access_levels": [
            "Unlimited & Unlimited+ Members"
          ],
          "content_role_arr": [
            "tier_1"
          ],
          "content_role": "tier_1",
          "status": "active",
          "drupal_id": "27626",
          "content_type": "course",
          "author": null,
          "updated_at": "2024-05-20 14:35:34",
          "creation_timestamp": "2024-03-07 13:15:51",
          "recommendation_id": "RID-1f-40c9-a26c-dfa978d7e683-CID-ba8cb2",
          "isAdminEnrolled": false,
          "course_record": {
            "name": "CEO Performance Evaluation",
            "type": "Course",
            "catalog_id": "o6pbk",
            "release_date": "2023-11-30T06:00:00Z",
            "close_date": null,
            "class_summary": null,
            "course_url": "https://cues.novoed.com/#!/courses/o6pbk/home"
          }
        },
        {
          "course_id": "34973",
          "item_id": "34973",
          "title": "The Role of the Board in Monitoring Performance | 2024 Director Education Center Course",
          "url": "/node/27546/edit",
          "course_catalog_id": "24dec-mon-perf",
          "learning_journeys_arr": [
            "CUES Canadian Director Education Center - Full Learning Journey 2024",
            "CUES Director Education Center - Full Learning Journey 2024"
          ],
          "learning_journeys": "CUES Canadian Director Education Center - Full Learning Journey 2024|CUES Director Education Center - Full Learning Journey 2024",
          "personas_arr": [
            "Board"
          ],
          "personas": "Board",
          "content_classification": "Board",
          "topics_arr": [
            "Governance"
          ],
          "topics": "Governance",
          "genres_arr": [
            "Governance"
          ],
          "genres": "Governance",
          "series": "DEC",
          "tiers_arr": [
            "tier_1",
            "tier_2"
          ],
          "tiers": "tier_1|tier_2",
          "tiers_access_levels": [
            "Unlimited & Unlimited+ Members",
            "Individual Members"
          ],
          "content_role_arr": [
            "tier_1",
            "tier_2"
          ],
          "content_role": "tier_1|tier_2",
          "status": "active",
          "drupal_id": "27546",
          "content_type": "course",
          "author": null,
          "updated_at": "2024-03-07 12:11:43",
          "creation_timestamp": "2024-03-07 12:10:59",
          "recommendation_id": "RID-1f-40c9-a26c-dfa978d7e683-CID-ba8cb2",
          "isAdminEnrolled": false,
          "course_record": {
            "name": "The Role of the Board in Monitoring Performance | 2024 Director Education Center Course",
            "type": "Course",
            "catalog_id": "24dec-mon-perf",
            "release_date": "2024-01-04T07:00:00Z",
            "close_date": null,
            "class_summary": null,
            "course_url": "https://cues.novoed.com/#!/courses/24dec-mon-perf/home"
          }
        },
        {
          "course_id": "34971",
          "item_id": "34971",
          "title": "The Role of the Board in Reporting to the Membership | 2024 Director Education Center Course",
          "url": "/node/27531/edit",
          "course_catalog_id": "24dec-rep-mem",
          "learning_journeys_arr": [
            "CUES Canadian Director Education Center - Full Learning Journey 2024",
            "CUES Director Education Center - Full Learning Journey 2024"
          ],
          "learning_journeys": "CUES Canadian Director Education Center - Full Learning Journey 2024|CUES Director Education Center - Full Learning Journey 2024",
          "personas_arr": [
            "Board"
          ],
          "personas": "Board",
          "content_classification": "Board",
          "topics_arr": [
            "Governance"
          ],
          "topics": "Governance",
          "genres_arr": [
            "Governance"
          ],
          "genres": "Governance",
          "series": "DEC",
          "tiers_arr": [
            "tier_1",
            "tier_2"
          ],
          "tiers": "tier_1|tier_2",
          "tiers_access_levels": [
            "Unlimited & Unlimited+ Members",
            "Individual Members"
          ],
          "content_role_arr": [
            "tier_1",
            "tier_2"
          ],
          "content_role": "tier_1|tier_2",
          "status": "active",
          "drupal_id": "27531",
          "content_type": "course",
          "author": null,
          "updated_at": "2024-03-07 12:07:42",
          "creation_timestamp": "2024-03-07 12:06:37",
          "recommendation_id": "RID-1f-40c9-a26c-dfa978d7e683-CID-ba8cb2",
          "isAdminEnrolled": false,
          "course_record": {
            "name": "The Role of the Board in Reporting to the Membership | 2024 Director Education Center Course",
            "type": "Course",
            "catalog_id": "24dec-rep-mem",
            "release_date": "2024-01-01T07:00:00Z",
            "close_date": null,
            "class_summary": null,
            "course_url": "https://cues.novoed.com/#!/courses/24dec-rep-mem/home"
          }
        },
        {
          "course_id": "35377",
          "item_id": "35377",
          "title": "CEO Transition",
          "url": "/node/27621/edit",
          "course_catalog_id": "y3r54",
          "learning_journeys_arr": [
            "Governance+"
          ],
          "learning_journeys": "Governance+",
          "personas_arr": [
            "Board"
          ],
          "personas": "Board",
          "content_classification": "Board",
          "topics_arr": [
            "Governance"
          ],
          "topics": "Governance",
          "genres_arr": [
            "Governance"
          ],
          "genres": "Governance",
          "series": "General",
          "tiers_arr": [
            "tier_1"
          ],
          "tiers": "tier_1",
          "tiers_access_levels": [
            "Unlimited & Unlimited+ Members"
          ],
          "content_role_arr": [
            "tier_1"
          ],
          "content_role": "tier_1",
          "status": "active",
          "drupal_id": "27621",
          "content_type": "course",
          "author": null,
          "updated_at": "2024-05-20 14:35:51",
          "creation_timestamp": "2024-03-07 13:14:43",
          "recommendation_id": "RID-1f-40c9-a26c-dfa978d7e683-CID-ba8cb2",
          "isAdminEnrolled": false,
          "course_record": {
            "name": "CEO Transition",
            "type": "Course",
            "catalog_id": "y3r54",
            "release_date": "2023-11-30T06:00:00Z",
            "close_date": null,
            "class_summary": null,
            "course_url": "https://cues.novoed.com/#!/courses/y3r54/home"
          }
        }
      ]
    }
}