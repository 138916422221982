import React, { useState } from 'react';
import { Modal, Box, Typography, Button, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/system';
const icnCourse = require("../assets/images/icn_courses@2x.png");
const truncateStringWithPTag = (inputString, maxLength) => {
    if (inputString === undefined || inputString === null) return inputString;
  
    const regex = /(<([^>]+)>)/gi;
    const stringWithoutPTags = inputString.replace(regex, '');
  
    if (!stringWithoutPTags.trim()) {
      return inputString;
    }
  
    if (stringWithoutPTags.length > maxLength) {
      const lastSpaceIndex = stringWithoutPTags.lastIndexOf(' ', maxLength);
      if (lastSpaceIndex !== -1) {
        return stringWithoutPTags.substring(0, lastSpaceIndex) + '...';
      }
      return stringWithoutPTags.substring(0, maxLength) + '...';
    }
  
    return stringWithoutPTags;
  };
const RecommendationSummary = ({ title, summary, maxLength, url }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const truncatedText = truncateStringWithPTag(summary, maxLength);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const textWithViewMore = truncatedText !== summary ? (
    <>
      {truncatedText}{' '}
      <Button
        variant="text"
        color="primary"
        onClick={openModal}
        style={{ textTransform: 'none', padding: 0, minWidth: 'auto' }}
      >
        View More
      </Button>
    </>
  ) : (
    truncatedText
  );
  const ModalContent = styled('div')`
  p {
    margin-top: 1rem; /* or any desired margin value */
  }
`;
const closeModalAndOpenUrl = () => {
    setIsModalOpen(false);
    window.open(url, '_blank');
  };
const styles = {
    icon: {
      width: '18px', // Adjust the width of the icon as per your requirement
      height: '18px', // Adjust the height of the icon as per your requirement
      marginRight: '5px', // Add some space between the icon and text
    }
  };
  return (
    <div className="cmp-learning-card-course-length">
      <p>{textWithViewMore}</p>
      <Modal
        open={isModalOpen}
        onClose={closeModal}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: {
              xs: '80%', // 80% width for extra-small screens and up (mobile devices)
              sm: '50%', // 50% width for small screens and up
            },
            height: 'auto',
            maxHeight: '80%',
            bgcolor: 'background.paper',
            border: '0px solid #000',
            boxShadow: 24,
            p: 4,
            borderRadius: 4, // Add rounded corners
            overflow: 'auto', // Enable scrolling
            '& ul': {
          marginTop:'10px',
          textAlign:'left',
          listStyleType: 'disc', // Ensure bullets are shown for list items
          paddingLeft: '20px',   // Add left padding to show bullets clearly
        },
        '& li': {
          marginBottom: '0px', // Optional: space between list items
        },
          }}
        >
          <IconButton
            aria-label="close"
            onClick={closeModal}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <Typography id="modal-title" variant="h6" component="h2">
           <span><img style={styles.icon} src={icnCourse} />{title}</span>
            
          </Typography>
          <Typography id="modal-description" sx={{ mt: 2 }}>
          <ModalContent dangerouslySetInnerHTML={{ __html: summary }} />
          </Typography>
          <Button  onClick={() => closeModalAndOpenUrl()} variant="contained" color="primary" sx={{
          textTransform: 'none',
          padding: '6px 26px',
          borderRadius: '30px',
          backgroundColor: '#E2680E',
          border: '2px solid #E2680E',
          textDecoration: 'none',
          color: '#fff',
          fontSize: '14px',
          lineHeight: '22px',
          letterSpacing: '0',
          fontWeight: 700,
          marginTop: '15px',
          '&:hover': {
            backgroundColor: '#fff',
            color: '#000',
            cursor: 'pointer',
          },
        }}>
            Enroll
          </Button>
        </Box>
      </Modal>
    </div>
  );
};
export default RecommendationSummary;