import styled from "styled-components";
export const LearningWrapper = styled.section`
background:linear-gradient(
    45deg,       /* Angle of the gradient */
    #2D66AC 0%,  /* First color stop at 0% */
    #009CA3 50%, /* Second color stop at 50% */
    #AED6B8 100% /* Third color stop at 100% */
  );
`;
export const LearningJourneyElement = styled.section`
  display: flex;
  justify-content: center;
  padding: 57px 20px 52px 20px;
  
  overflow-x: hidden;
  
  .pagination {
    list-style: none;
    height: 31.5px;
    width: 31.5px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto; /* Center the pagination horizontally */
    margin-top: 2px;
    cursor: pointer;
  }
  .pagination li a {
    /**width: 25px !important;**/
    height: 25px !important;
    background-color: transparent;
    color: #fff;
    align-items: center;
    justify-content: center;
   
    cursor: pointer;
    text-align: center !important;
    font-size: 12px; /* Adjust font size as needed */
    line-height: .75; /* Ensure the number is properly contained within the box */
    padding:5px!important;
    padding-top:7px!important;
   
    margin: 5px;
    border: 1px solid #fff; /* Add a white border */
  }
  .pagination .break  a {
    border: 1px solid transparent!important; /* Add a white border */
  }
  .hidden {
    display: none;
  }
  .active a {
    background-color: #fff!important;
    color: black!important;
    
  }
  .cmp-learningjourney-container {
    max-width: 1154px;
    width: 100%;
  }

  .cmp-learningjourney-header {
    display: flex;
    align-items: center;
    margin-bottom: 25px;
    flex-wrap: wrap;

    @media (max-width: 815px) {
      flex-direction: column;
      align-items: flex-start;
    }

    h2 {
      font-size: 24px;
      font-weight: 700;
      line-height: 28px;
      letter-spacing: -0.48px;
      color: #fff!important;
      margin-right: 40px;

      @media (max-width: 815px) {
        margin-right: unset;
        margin-bottom: 20px;
      }
    }

    

    a {
      margin-left: auto;
      border-radius: 30px;
      background: #E2680E;
      border: 2px solid #E2680E;
      text-decoration: none;
      color: #fff;
      font-size: 14px;
      font-weight: 500;
      line-height: 22px;
      letter-spacing: 0;
      padding: 4px 24px;

      @media (max-width: 815px) {
        order: 2;
        margin-left: unset;
        margin-bottom: 30px;
      }
    }
  }

  .cmp-learningjourney-feed {
    @media (max-width: 520px) {
      display: none;
    }

    .swiper {
      overflow: visible;
      padding-bottom: 50px;

      .swiper-pagination {
        bottom: 0px;

        .swiper-pagination-bullet {
          height: 15px;
          width: 15px;
          background: none;
          border: 2px solid #878990;
          opacity: 1;

          &:not(:last-of-type) {
            margin-right: 10px;
          }

          &.swiper-pagination-bullet-active {
            background: #E2680E;
            border: 2px solid #E2680E;
          }
        }
      }
    }

    .swiper-slide {
      width: 375px;
    }
  }

  .cmp-learningjourney-feed-mobile {
    @media (min-width: 521px) {
      display: none;
    }

    .cmp-learningjourney-card {
      margin-left: auto;
      margin-right: auto;
    }

    .swiper {
      padding-bottom: 50px;

      .swiper-pagination {
        bottom: 0px;

        .swiper-pagination-bullet {
          height: 15px;
          width: 15px;
          background: none;
          border: 2px solid #878990;
          opacity: 1;

          &:not(:last-of-type) {
            margin-right: 10px;
          }

          &.swiper-pagination-bullet-active {
            background: #E2680E;
            border: 2px solid #E2680E;
          }
        }
      }
    }
  }

  .cmp-learningjourney-card {
    background-size: cover;
    background-color: #fff;
    width: 375px;
    border-radius: 12px;
    height: 195px;
    position: relative;
    padding: 16px 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-sizing: border-box;
   
    
    &::before {
      border-radius: 12px;
      content: " ";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
     
      opacity: 0.9;
      z-index: 1;
    }

    .cmp-learningjourney-card-header,
    .cmp-learningjourney-card-footer {
      z-index: 2;
      position: relative;
    }

    .cmp-learningjourney-card-header {
      .cmp-learningjourney-type{
        color:#E2680E;
        letter-spacing: -0.14px;
        font: normal normal bold 14px/19px Roboto;
        display: flex; /* Use flexbox for vertical centering */
        align-items: center; /* Vertically center the content */
        margin-bottom:5px;

        img{
          width: 18px; /* Adjust the width of the icon as per your requirement */
          height: 18px; /* Adjust the height of the icon as per your requirement */
          margin-right: 5px; /* Add some space between the icon and text */
        }
  
      }
      .cmp-learningjourney-card-title {
        font-size: 18px;
        line-height: 22px;
        font-weight: 500;
        color: #000;
        max-width: 264px;
        margin-bottom:5px;
      }
      .cmp-learningjourney-card-date,
      .cmp-learningjourney-card-location {
        font-size: 14px;
        line-height: 16px;
        letter-spacing: 0;
        color: #fff;
      }

      .cmp-learningjourney-card-title {
        font-size: 20px;
        line-height: 22px;
        letter-spacing: 0;
        font-weight: 500;
        color: #000;
        margin: 3px 0;
      }
      a.cmp-learningjourney-card-button {
        font-weight: 700!important;
        font-size: 12px;
        line-height: 12px;
        padding: 8px;
        border-radius: 6px;
        background-color: #E2680E;
        margin-bottom: 14px;
        width:90px;
        text-transform: uppercase;
        text-align:center!important;
        display:block!important;
        &:hover {
          background: transparent;
          color: #fff;
        }

        &.register {
          background: #E2680E;
          color: #fff;
          display: flex;
          align-items: center;
          border: 2px solid #E2680E;

          img {
            width: 16px;
            height: 18px;
            margin-right: 6px;
          }
        }
      }
    }

    .cmp-learningjourney-card-footer {
      display: flex;
      align-items: center;

      .cmp-learningjourney-card-type-icon {
        margin-right: 20px;

        img {
          height: 22px;
          width: auto;
        }
      }
      .cmp-learningjourney-card-link {
        border-radius: 30px;
        background: #fff;
        border: 2px solid #2D66AC;
        text-decoration: none;
        color: #2D66AC;
        font-size: 14px;
        line-height: 22px;
        letter-spacing: 0;
        padding: 6px 26px;
        font-weight: 700;
        margin-top: 15px;
        
      }
      .cmp-learningjourney-card-link:hover {
        background-color: #2D66AC; /* Change the background color on hover */
        color: #fff; /* Change the text color on hover */
        cursor: pointer;
        
      }
     
      a.cmp-learningjourney-card-button {
        background: #ffffff;
        border: 2px solid #fff;
        font-size: 16px;
        line-height: 22px;
        font-weight: 700!important;
        text-decoration: none;
        color: #303440;
        border-radius: 30px;
        padding: 3px 21px;

        &:hover {
          background: transparent;
          color: #fff;
        }

        &.register {
          background: #E2680E;
          color: #fff;
          display: flex;
          align-items: center;
          border: 2px solid #E2680E;

          img {
            width: 16px;
            height: 18px;
            margin-right: 6px;
          }
        }
      }

      a.cmp-learningjourney-card-more-info {
        margin-left: auto;
        display: flex;
        align-items: center;
        text-transform: uppercase;
        text-decoration: none;
        color: #fff;
        font-size: 14px;
        line-height: 16px;
        font-weight: 900;

        img {
          height: 16px;
          width: 16px;
          margin-left: 10px;
        }
      }
    }
  }

  .cmp-learningjourney-feed-empty {
    padding: 40px 0 20px 0;
  }

  .cmp-debug-btn {
    border-radius: 30px;
    background: #E2680E;
    border: 2px solid #E2680E;
    -webkit-text-decoration: none;
    text-decoration: none;
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    -webkit-letter-spacing: 0;
    -moz-letter-spacing: 0;
    -ms-letter-spacing: 0;
    letter-spacing: 0;
    padding: 4px 24px;
    margin-top: 10px;
  }

  .loading-container {
    display: flex;

    .loading-card {
      height: 240px;
      width: 100%;
      background: #fff;
      border-radius: 7px;
      padding: 26px 24px 21px;
      display: flex;
      flex-direction: column;

      &:not(:last-child) {
        margin-right: 20px;
      }

      .loading-card-footer {
        margin-top: auto;
        display: flex;

        div {
          &:nth-child(1) {
            width: 12%;
            margin-right: 26px;
          }

          &:nth-child(2) {
            width: 35%;
          }
        }
      }
    }
  }
`;
export const LearningElement = styled.section`
  display: flex;
  justify-content: center;
  padding: 57px 20px 52px 20px;
  
 
  .custom-tooltip .cmp-popover-link {
    border-radius: 30px;
    background: #fff;
    border: 2px solid #E2680E;
    text-decoration: none;
    color: #242731;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0;
    padding: 6px 26px;
    font-weight: 700;
    margin-top: 15px;
  }
  .custom-tooltip .cmp-popover-link:hover {
    background-color: #E2680E; /* Change the background color on hover */
    color: #fff; /* Change the text color on hover */
    cursor: pointer;
    
  }
 
  .overlay {
    position: relative;
  }
  
  .overlay-content {
    position: absolute;
    background-color: rgba(0, 0, 0, 0.5); /* semi-transparent black background */
    color: white;
    padding: 10px;
    border-radius: 5px;
    top: calc(100% + 10px); /* position below the button */
    left: 50%; /* center horizontally */
    transform: translateX(-50%);
    z-index: 999; /* ensure it appears above other content */
  }
  
  .pagination {
    list-style: none;
    height: 31.5px;
    width: 31.5px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto; /* Center the pagination horizontally */
    margin-top: 2px;
    cursor: pointer;
  }
  .pagination li a {
    /**width: 25px !important;**/
    height: 25px !important;
    background-color: transparent;
    color: #fff;
    align-items: center;
    justify-content: center;
   
    cursor: pointer;
    text-align: center !important;
    font-size: 12px; /* Adjust font size as needed */
    line-height: .75; /* Ensure the number is properly contained within the box */
    padding:5px!important;
    padding-top:7px!important;
   
    margin: 5px;
    border: 1px solid #fff; /* Add a white border */
  }
  .pagination .break  a {
    border: 1px solid transparent!important; /* Add a white border */
  }
  .active a {
    background-color: #fff!important;
    color: black!important;
    
  }
  .hidden {
    display: none;
  }
  .page-item {
    list-style: none;
    padding: 2px 12px;
    height: 31.5px;
    width: 31.5px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2px;
  }
  .swiper-pagination-custom {
    width: 20px!important;
    height: 20px!important;
    background-color: #000;
    color: #fff;
    display: inline-flex!important;
    align-items: center;
    justify-content: center;
    border-radius: 5px; /* Adjust border-radius for square corners */
    cursor: pointer;
    text-align:center!important;
    font-size: 12px; /* Adjust font size as needed */
    line-height: .75; /* Ensure the number is properly contained within the box */
    padding:3px!important;
  }
  .swiper-pagination-bullet-active  {
    color: #000!important; /* Change text color for the selected bullet */
  }
  .cmp-learning-container {
    max-width: 1154px;
    width: 100%;
  }

  .cmp-learning-header {
    display: flex;
    align-items: center;
    margin-bottom: 35px;
    
    @media (max-width: 730px) {
      flex-direction: column;
    }

    .cmp-learning-header-content {
      h2 {
        font-size: 24px;
        font-weight: 700;
        line-height: 28px;
        letter-spacing: -0.48px;
        color: #fff;
        margin-right: 40px;

        @media (max-width: 730px) {
          margin-right: unset;
          margin-bottom: 20px;
        }
      }
    }

    ul.cmp-learning-tabs {
      display: flex;
      .cmp-popover-link {
        border-radius: 30px;
        background: #fff;
        border: 2px solid #E2680E;
        text-decoration: none;
        color: #242731;
        font-size: 14px;
        line-height: 22px;
        letter-spacing: 0;
        padding: 6px 26px;
        font-weight: 700;
        margin-top: 15px;
      }
      .cmp-popover-link:hover {
        background-color: #E2680E; /* Change the background color on hover */
        color: #fff; /* Change the text color on hover */
        cursor: pointer;
        
      }
      @media (max-width: 730px) {
        order: 3;
      }

      li {
        &:not(:last-child) {
          margin-right: 53px;
        }

        button {
          background: none;
          border: none;
          font-size: 18px;
          font-weight: 700;
          line-height: 21px;
          letter-spacing: -0.36px;
          color: #c4c5c8;
          padding: 0;

          span {
            color: #E2680E;
          }

          &:hover {
            color: #fff;
            cursor: pointer;
          }

          &.active {
            color: #fff;
            position: relative;

            &::after {
              content: " ";
              position: absolute;
              height: 3px;
              left: 0;
              right: 0;
              bottom: -10px;
              background: #fff;
            }
          }
        }
      }
    }

    a {
      @media (max-width: 730px) {
        order: 2;
        margin-left: unset;
        margin-bottom: 40px;
      }

      margin-left: auto;
      border-radius: 30px;
      background: #2D66AC;
      border: 2px solid #2D66AC;
      text-decoration: none;
      color: #fff;
      font-size: 14px;
      font-weight: 800;
      line-height: 22px;
      letter-spacing: 0;
      padding: 4px 24px;
    }
  }

  .cmp-learning-card {
    background-size: cover;
    background-repeat: no-repeat;
    max-width: 365px;
    width: 100%;
    height: 240px;
    padding: 16px 24px 21px 24px;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 7px;
    background: #fff;
    box-sizing: border-box;
    &.recommendations.empty{
      height:240px!important;
      background-color:#fff!important;
     
      .cmp-learning-card-title{
        color:black!important;
        font-weight: 700!important;
      }
      .cmp-learning-card-link{
        color:#2D66AC!important;
        font-weight:700!important;
      }
      .cmp-learning-card-link:hover {
       border-color:white!important;
       color:white!important; 
      }
      .cmp-learning-card-title {
        max-width:100%!important;
      }
    }
    &.empty{
      height:172px!important;
      background-color:#fff!important;
     
      .cmp-learning-card-title{
        color:#2f3234!important;
        font-weight:700!important;
      }
      .cmp-learning-card-link{
        color:#2D66AC!important;
      }
      .cmp-learning-card-link:hover {
       border-color:white!important;
       color:white!important; 
      }
      .cmp-learning-card-title {
        max-width:100%!important;
      }
    }

    .cmp-learning-card-header {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      .cmp-learning-type{
        color:#E2680E;
        letter-spacing: -0.14px;
        font: normal normal bold 14px/19px Roboto;
        display: flex; /* Use flexbox for vertical centering */
        align-items: center; /* Vertically center the content */
        margin-bottom:5px;

        img{
          width: 18px; /* Adjust the width of the icon as per your requirement */
          height: 18px; /* Adjust the height of the icon as per your requirement */
          margin-right: 5px; /* Add some space between the icon and text */
        }
  
      }
      .cmp-learning-card-title {
        font-size: 18px;
        line-height: 22px;
        font-weight: 700!important;
        color: #000;
        max-width: 264px;
        margin-bottom:5px;
      }

      
    }

    .cmp-learning-card-footer {
      margin-top: auto;
      display: flex;
      justify-content: space-between;
      
      .cmp-learning-card-link {
        border-radius: 30px;
        background: #fff;
        border: 2px solid #2D66AC;
        text-decoration: none;
        color: #2D66AC;
        font-size: 14px;
        line-height: 22px;
        letter-spacing: 0;
        padding: 6px 26px;
        font-weight: 700!important;
        margin-top: 15px;
        
      }
      .cmp-learning-card-link:hover {
        background-color: #2D66AC; /* Change the background color on hover */
        color: #fff; /* Change the text color on hover */
        cursor: pointer;
        
      }
     

      .cmp-learning-card-due-date {
        font-size: 14px;
        line-height: 16px;
      }

      .cmp-learning-card-course-length {
        font-size: 16px;
        line-height: 19px;
        letter-spacing: -0.48px;
        color: #000000;
        font-weight: 700;
        margin-left: auto;
      }
    }
  }

  .cmp-learning-assignments {
    .swiper-wrapper {
      height: 830px;

      @media (min-width: 769px) {
        height: 562px;
      }

      @media (max-width: 768px) {
        height: 560px;
      }
    }

    .swiper-slide {
      height: unset;
    }

    .swiper-pagination {
      bottom: 0px;

      .swiper-pagination-bullet {
        height: 15px;
        width: 15px;
        background: none;
        border: 2px solid #989aa0;
        opacity: 1;

        &:not(:last-of-type) {
          margin-right: 10px;
        }

        &.swiper-pagination-bullet-active {
          background: #fff;
          border: 2px solid #fff;
        }
      }
    }
  }

  /* .cmp-learning-assignments.cmp-learning-assignments-with-slider {
    @media (max-width: 639px) {
      display: none;
    }
  } */

  .cmp-learning-assignments.cmp-learning-assignments-mobile {
    @media (min-width: 641px) {
      display: none;
    }
    .cmp-learning-card {
      max-width: 100%;
      height: 180px;

      &:not(:last-child) {
        margin-bottom: 15px;
      }
    }
  }

  .empty-state {
    padding: 24px 0;

    p {
      color: #fff;
      line-height: 21px;
    }

    @media (max-width: 768px) {
      padding: 20px;
     
      
      p {
        text-align: center;
        padding: 0 20px;
      }
    }
  }

  .loading-container {
    display: flex;

    .loading-card {
      height: 240px;
      width: 100%;
      background: #fff;
      border-radius: 7px;
      padding: 26px 24px 21px;
      display: flex;
      flex-direction: column;
      box-shadow: rgb(0 0 0 / 16%) 0px 3px 6px;

      &:not(:last-child) {
        margin-right: 20px;
      }

      .loading-card-footer {
        margin-top: auto;
        display: flex;
        justify-content: space-between;

        div {
          width: 35%;
        }
      }
    }
  }
  .swiper-pagination .swiper-pagination-custom {
    width: 10px;
    height: 10px;
    display: inline-block;
    border: 1px solid #000; /* You can adjust the border style as needed */
    margin-right: 5px; /* Adjust spacing between pagination squares */
  }
  
`;