import react, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { useAuth } from "./Auth";

const InvalidMembershipEl = styled.section`
  display: flex;
  justify-content: center;
  padding: 52px 0 60px 0;
  background: #f1f1f1;

  div.invalid-membership-container {
    background: #fff;
    box-shadow: 0px 3px 6px #0000001f;
    border-radius: 12px;
    max-width: 1280px;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 42px 34px;
    flex-direction: column;
    text-align: center;

    h1 {
      color: rgb(12, 102, 165);
      font-weight: 700;
      letter-spacing: -0.84px;
      font-size: 28px;
      line-height: 33px;
      margin-bottom: 20px;
    }

    p {
      font-size: 18px;
      line-height: 26px;
      margin-bottom: 26px;
    }

    a {
      text-decoration: none;
      background: #E2680E;
      border: solid 2px #E2680E;
      font-size: 19px;
      line-height: 30px;
      color: #fff;
      font-weight: 500;
      padding: 6px 30px;
      border-radius: 100px;
      transition: all 0.2s ease 0s;

      &:hover {
        cursor: pointer;
        background: transparent;
        color: #E2680E;
      }
    }
  }
`;

const InvalidMembership = () => {
  const { loading, cmpData } = useAuth();
  const [html, setHtml] = useState(null);

  useEffect(() => {
    if (!loading) {
      setHtml(`
        <h1>${cmpData.pages.invalid_membership.title}</h1>
        ${cmpData.pages.invalid_membership.body}
      `);
    }
  }, [loading]);

  return (
    <>
      {!loading && cmpData && cmpData.pages.invalid_membership && (
        <InvalidMembershipEl>
          <div
            className="invalid-membership-container"
            dangerouslySetInnerHTML={{ __html: html }}
          >
            {/* Dynamic Page Content */}
          </div>
        </InvalidMembershipEl>
      )}
    </>
  );
};

export default InvalidMembership;
