import React, { useState } from "react";
import styled from "styled-components";
import { useAuth } from "../../Auth";
import ReactGA from "react-ga4";

const CuesNetElement = styled.section`
  display: flex;
  justify-content: center;
  padding: 20px;
background:linear-gradient(
    45deg,       /* Angle of the gradient */
   
    #009CA3 0%, /* Second color stop at 50% */
    #AED6B8 100% /* Third color stop at 100% */
  );
  .cmp-cuesnet-container {
    max-width: 1280px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    
    @media (max-width: 1220px) {
      flex-direction: column;
      align-items: center;
    }
  }

  .cmp-cuesnet-info {
    display: flex;
    flex-grow: 1; /* Grow to fill available space */
    align-items: center; /* Center items vertically */
    justify-content: space-between; /* Distribute items evenly */
    
    .cmp-cuesnet-visit-link{
      border-radius: 30px;
      background: #2D66AC;
      border: 2px solid #2D66AC;
      text-decoration: none;
      color: #fff;
      font-size: 14px;
      line-height: 22px;
      letter-spacing: 0;
      padding: 6px 26px;
      font-weight: 800;
      margin-top: 15px;
    }
    .cmp-cuesnet-visit-link:hover {
      background-color: #fff; /* Change the background color on hover */
      color: #242731; /* Change the text color on hover */
      cursor: pointer;   
    }

    @media (max-width: 1220px) {
      flex-direction: row;
      align-items: flex-start;
      .column-1,
      .column-2,
      .column-3 {
        display: flex;
        align-items: center; /* Center their content vertically */
      }
      .column-1{
        flex: 0 0 auto; /* Don't grow or shrink, maintain auto width */
        justify-self: start; /* Align to the start (left) */
      }
      .column-2{
        flex: 1; /* Allow to grow and fill remaining space */
      }
      .column-3{
        flex: 0 0 auto; /* Don't grow or shrink, maintain auto width */
      }
    }

    @media (max-width: 630px) {
      flex-direction: column;
      align-items: center;
    }

    img {
      max-height: 100px;
      width: 100%;
      margin-bottom: 0px;
      
    }

    .cmp-cuesnet-info-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      
      @media (max-width: 1220px) {
        align-items: flex-start;
        margin-left: 60px;
      }

      @media (max-width: 630px) {
        margin-left: unset;
        align-items: center;
      }

      .cmp-cuesnet-tagline-1 {
        
        text-align: center;
        font-size: 18px;
        line-height: 22px;
        font-weight: 700;
        letter-spacing: -0.36px;
        color: #fff;
        margin-bottom: 5px;

        @media (max-width: 1220px) {
          text-align: left;
        }
      }

      .cmp-cuesnet-tagline-2 {
        font-size: 16px;
        line-height: 19px;
        letter-spacing: -0.32px;
        color: #fff;
        margin-bottom: 00px;
      }

      a.cmp-cuesnet-visit-link {
        border-radius: 30px;
        background: #E2680E;
        border: 2px solid #E2680E;
        text-decoration: none;
        color: #fff;
        font-size: 14px;
        font-weight: 500;
        line-height: 22px;
        letter-spacing: 0;
        padding: 6px 24px;
      }
    }
  }

  .cmp-cuesnet-content-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 1px;
    grid-row-gap: 1px;
    max-width: 712px;
    width: 100%;
    background: rgba(183, 183, 183, 1);
    margin-left: 100px;

    @media (max-width: 1220px) {
      margin-left: unset;
    }

    @media (max-width: 630px) {
      grid-template-columns: 1fr;
    }

    .cmp-cuesnet-content-block {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 256px;
      background: rgba(255, 255, 255, 1);
      padding: 0 30px;

      img.cmp-cuesnet-content-icon {
        height: 50px;
        width: auto;
        margin-bottom: 16px;
      }

      p.cmp-cuesnet-content-title {
        font-size: 18px;
        line-height: 22px;
        color: #303440;
        font-weight: 500;
        text-align: center;
        margin-bottom: 5px;
      }

      p.cmp-cuesnet-content-description {
        font-size: 14px;
        line-height: 22px;
        color: #303440;
        text-align: center;
        margin-bottom: 24px;
      }

      a.cmp-cuesnet-content-link {
        font-size: 14px;
        line-height: 16px;
        color: #E2680E;
        text-transform: uppercase;
        text-decoration: none;
        font-weight: 700;
        display: flex;
        align-items: center;

        img {
          height: 16px;
          width: 16px;
          margin-left: 10px;
        }
      }
    }
  }
`;

const CuesNet = () => {
  const Visit = require("../../assets/images/icn-cuesnet-visit.png");
  const Network = require("../../assets/images/icn-cuesnet-network.png");
  const Profile = require("../../assets/images/icn-cuesnet-profile.png");
  const Explore = require("../../assets/images/icn-cuesnet-explore.png");
  const arrow = require("../../assets/images/icn-arrow-r.png");
  const logo = require("../../assets/images/logo-cuesnet.png");
  const orangeArrow = require("../../assets/images/icn-next-o.png");

  const { cmpData } = useAuth();

  function handleGAEvent(eventTitle, value) {
    if (cmpData) {
      if (window.location.host == "mycues.cues.org") {
        ReactGA.event(eventTitle, {
          value,
          user_role: cmpData.account_info.acgiRole,
          user_memberlevel: cmpData.account_info.membershipLevel,
          user_company: cmpData.account_info.employer,
        });
      }
    }
  }

  return (
    <CuesNetElement>
      <div className="cmp-cuesnet-container">
        <div className="cmp-cuesnet-info">
          <div className="column-1">
            <img src={logo} alt="CUESNet Logo" />
          </div>
          <div className="column-2">
          <div className="cmp-cuesnet-info-container column-2">
            <p className="cmp-cuesnet-tagline-1">
              Welcome To Your CUES Community
            </p>
            <p className="cmp-cuesnet-tagline-2">
              Discover tips, network with peers, and contribute to enhancing the collective knowledge library.
            </p>

          </div>
          </div>
          <div className="column-3">
          <a
            href="https://cuesnet.cues.org/HigherLogic/Security/SAML/localSAMLLoginService.aspx?ReturnUrl=https%3A//cuesnet.cues.org/home"
            target="_blank"
            className="cmp-cuesnet-visit-link"
            onClick={() => {
              handleGAEvent("cuesnet_visitcues");
            }}
          >
            EXPLORE CUESNet
          </a>
          </div>
        </div>

        {/* <div className="cmp-cuesnet-content-grid">
          <div className="cmp-cuesnet-content-block cmp-cuesnet-content-block-1">
            <img
              src={Visit}
              className="cmp-cuesnet-content-icon"
              alt="Visit CUES Member Community"
            />
            <p className="cmp-cuesnet-content-title">
              Visit CUES Member Community
            </p>
            <p className="cmp-cuesnet-content-description">
              Discover what your peers are talking about.
            </p>
            <a
              href="https://cuesnet.cues.org/HigherLogic/Security/SAML/localSAMLLoginService.aspx?ReturnUrl=https%3A//cuesnet.cues.org/communities/groupdetails?CommunityKey=be4fe2e1-e63e-4e47-b1ab-520472067869"
              target="_blank"
              className="cmp-cuesnet-content-link"
            >
              Visit <img src={orangeArrow} alt="Visit CUESNet" />
            </a>
          </div>

          <div className="cmp-cuesnet-content-block cmp-cuesnet-content-block-2">
            <img
              src={Network}
              className="cmp-cuesnet-content-icon"
              alt="Visit CUES Member Community"
            />
            <p className="cmp-cuesnet-content-title">Build My Networks</p>
            <p className="cmp-cuesnet-content-description">
              Find your peers in the CUES Membership Directory.
            </p>
            <a
              href="https://cuesnet.cues.org/HigherLogic/Security/SAML/localSAMLLoginService.aspx?ReturnUrl=https%3A//cuesnet.cues.org/networking/members"
              target="_blank"
              className="cmp-cuesnet-content-link"
            >
              Build <img src={orangeArrow} alt="Visit CUESNet" />
            </a>
          </div>

          <div className="cmp-cuesnet-content-block cmp-cuesnet-content-block-3">
            <img
              src={Explore}
              className="cmp-cuesnet-content-icon"
              alt="Visit CUES Member Community"
            />
            <p className="cmp-cuesnet-content-title">Explore All Communities</p>
            <p className="cmp-cuesnet-content-description">
              Discover and join new communities.
            </p>
            <a
              href="https://cuesnet.cues.org/HigherLogic/Security/SAML/localSAMLLoginService.aspx?ReturnUrl=https%3A//cuesnet.cues.org/communities/allcommunities"
              target="_blank"
              className="cmp-cuesnet-content-link"
            >
              Explore <img src={orangeArrow} alt="Visit CUESNet" />
            </a>
          </div>

          <div className="cmp-cuesnet-content-block cmp-cuesnet-content-block-4">
            <img
              src={Profile}
              className="cmp-cuesnet-content-icon"
              alt="Visit CUES Member Community"
            />
            <p className="cmp-cuesnet-content-title">myCUESNet Profile</p>
            <p className="cmp-cuesnet-content-description">
              Easily access your information and latest posts.
            </p>
            <a
              href="https://cuesnet.cues.org/HigherLogic/Security/SAML/localSAMLLoginService.aspx?ReturnUrl=https%3A//cuesnet.cues.org/networking/members/profile"
              target="_blank"
              className="cmp-cuesnet-content-link"
            >
              Access <img src={orangeArrow} alt="Visit CUESNet" />
            </a>
          </div>
        </div> */}
      </div>
    </CuesNetElement>
  );
};

export default CuesNet;
