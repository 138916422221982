import React, { useState, useEffect, useRef } from "react";
import ReactPaginate from 'react-paginate';
const SwiperPager = ({ swiper, itemCount, pageRangeCount, itemsPerPage,
  pageCount, onPageChange }) => {
    const [currentPage, setCurrentPage] = useState(0);
    useEffect(() => {
      const handleSlideChange = () => {
        if(swiper.params.slidesPerView === 1){
          const newPage = Math.floor(swiper.activeIndex / swiper.params.slidesPerView);
          setCurrentPage(swiper.activeIndex);
          console.log(`swipe:${newPage}:${swiper.params.slidesPerGroup}:${swiper.params.slidesPerView}`)
        }
       
      };
      if(!swiper)
        return;
      swiper.on('slideChange', handleSlideChange);
  
      return () => {
        swiper.off('slideChange', handleSlideChange);
      };
    }, [swiper, itemsPerPage]);
    // Callback for ReactPaginate
  const handlePageClick = (event) => {
    const newPage = event.selected;
    setCurrentPage(newPage);
    console.log(`page:${event.selected}:${swiper.params.slidesPerGroup}:${swiper.params.slidesPerView}`)
    swiper.slideTo(event.selected * swiper.params.slidesPerView );
  };
  return (
    <ReactPaginate
      containerClassName={"pagination"}
      activeClassName={"active"}
      pageClassName={"page-item"}
      onPageChange={handlePageClick}
      //onPageChange={(event) => {console.log(`Page ${event.selected} : ${(swiper.params.slidesPerGroup * swiper.params.slidesPerView)}`);swiper.slideTo(event.selected * (swiper.params.slidesPerGroup * swiper.params.slidesPerView))}}
      pageRangeDisplayed={1}
      marginPagesDisplayed={4}
      breakLabel="..."
      forcePage={currentPage} 
      pageCount={Math.ceil(itemCount / ( swiper?.params?.slidesPerView===1?2:6))}
      previousLabel={currentPage> 0 ? <span>{"<"}</span> : ''} // Show previous button if current page is greater than 0
      nextLabel={
        Math.ceil(itemCount / (swiper?.params?.slidesPerView===1?2:6)) >currentPage ?  <span>{">"}</span> : null
         // If total pages is less than or equal to 8, hide next button
      }
      previousClassName={currentPage > 0 ? 'page-item' : 'hidden'} // Add hidden class if previous button is not shown
      nextClassName={Math.ceil(itemCount / (swiper?.params?.slidesPerView===1?2:6)) > currentPage + 1 ? 'page-item' : 'hidden'} // Add hidden class if next button is not shown
      renderOnZeroPageCount={null}
    />
  );
};

export default SwiperPager;